<template>
    <div class="h-full">
        <div
            v-if="loading"
            class="h-full flex flex-column justify-content-center align-items-center"
        >
            <Skeleton shape="circle" size="5rem"></Skeleton>
        </div>
        <div v-else-if="locationObj.location_id">
            <div class="action-buttons">
                <template v-if="!readOnlyMode">
                    <h1>{{ $t('location.addLocationHeader') }}</h1>
                </template>
                <template v-else>
                    <h1>{{ $t('location.title') }}</h1>
                    <router-link
                        :to="{ name: 'endcustomer_location_managers', params:{location_id: route.params.location_id} }"
                        v-if="permStore.hasPermission(PERMISSION.location_manage)"
                    >
                        <Button icon="pi pi-users" severity="secondary" :label="$t('location.managers')" rounded></Button>
                    </router-link>
                </template>
            </div>
            <div class="add_location">
                <form class="form-section">
                    <div class="section shadow-2">
                        <div class="flex justify-content-between">
                            <h3>{{ t('location.address') }}</h3>
                            <i
                                v-if="readOnlyMode"
                                class="pi pi-info-circle"
                                style="font-size: 1rem; color: var(--takeables-secondary)"
                                v-tooltip.left="
                                    $t('general.readOnlyFormToolTip', {
                                        CUSTOMER: customerName,
                                    })
                                "
                            ></i>
                        </div>
                        <AddressComponent
                            :address="locationObj.address"
                            :readOnly="readOnlyMode"
                            :validation="locationValidation.address"
                            @get-address="locationObj.address = $event"
                            @is-street-town-required="
                                (value) => {
                                    if (value) {
                                        isStreetAndTownRequired = value
                                    }
                                }
                            "
                        />
                    </div>

                    <div class="section shadow-2">
                        <div class="form-header">
                            <h3>{{ t('location.contactPerson') }}</h3>
                            <div>
                                <Button
                                    outlined
                                    icon="pi pi-pencil"
                                    label="Edit"
                                    size="small"
                                    @click="editContactPerson()"
                                    v-if="!editContactPersonDetails && readOnlyMode"
                                />
                                <Button
                                    icon="pi pi-check"
                                    label="Save"
                                    size="small"
                                    @click="saveContactPerson()"
                                    v-if="editContactPersonDetails"
                                />
                            </div>
                        </div>
                        <ContactPerson
                            :contactPerson="locationObj.contact_person"
                            :readOnly="readOnlyMode && !editContactPersonDetails"
                            :validation="locationValidation.contact_person"
                            @get-contact-person="locationObj.contact_person = $event"
                        />
                    </div>

                    <div class="section shadow-2">
                        <div class="form-header">
                            <h3>{{ t('location.detailsAboutTheLocation') }}</h3>

                            <div>
                                <Button
                                    outlined
                                    icon="pi pi-pencil"
                                    label="Edit"
                                    size="small"
                                    @click="editLocationBtn()"
                                    v-if="!editLocationDetails && readOnlyMode"
                                />
                                <Button
                                    icon="pi pi-check"
                                    label="Save"
                                    size="small"
                                    @click="saveLocation()"
                                    v-if="editLocationDetails"
                                />
                            </div>
                        </div>
                        <div class="formgrid grid gap-3 justify-content-between px-4">
                            <div class="field w-3 flex-grow-1">
                                <label class="font-semibold" for="description">
                                    {{ $t('location.description') }}:
                                </label>
                                <div>
                                    <InputText
                                        v-model="locationObj.description"
                                        type="text"
                                        @blur="locationValidation.description.$touch()"
                                        id="description"
                                        :disabled="readOnlyMode && !editLocationDetails"
                                        :invalid="locationValidation?.description.$error"
                                    />
                                    <div
                                        class="input-errors"
                                        v-for="error of locationValidation.description.$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="field w-3 flex-grow-1">
                                <label class="font-semibold" for="building_number">
                                    {{ $t('location.building_number') }}:
                                </label>
                                <div>
                                    <InputText
                                        v-model="locationObj.building_number"
                                        type="text"
                                        id="building_number"
                                        @blur="locationValidation.building_number.$touch()"
                                        :disabled="readOnlyMode && !editLocationDetails"
                                        :invalid="locationValidation?.building_number.$error"
                                    />
                                    <div
                                        class="input-errors"
                                        v-for="error of locationValidation.building_number.$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="field w-3 flex-grow-1">
                                <label class="font-semibold" for="external_reference"
                                    >{{ $t('location.external_reference') }}:</label
                                >
                                <div>
                                    <InputText
                                        v-model="locationObj.external_reference"
                                        type="text"
                                        id="external_reference"
                                        @blur="locationValidation.external_reference.$touch()"
                                        :disabled="readOnlyMode && !editLocationDetails"
                                        :invalid="locationValidation?.external_reference.$error"
                                    />
                                    <div
                                        class="input-errors"
                                        v-for="error of locationValidation.external_reference
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="field w-3 flex-grow-1">
                                <label class="font-semibold" for="pickup_driver_remarks"
                                    >{{ $t('location.pickup_driver_remarks') }}:</label
                                >
                                <i
                                    class="pi pi-info-circle ml-2"
                                    style="font-size: 1rem; color: var(--takeables-secondary)"
                                    v-tooltip="$t('location.pickup_driver_remarks_tooltip')"
                                >
                                </i>
                                <div>
                                    <Textarea
                                        rows="3"
                                        cols="60"
                                        maxlength="180"
                                        v-model="locationObj.pickup_driver_remarks"
                                        @blur="locationValidation.pickup_driver_remarks.$touch()"
                                        id="pickup_driver_remarks"
                                        :placeholder="
                                            t('location.pickup_driver_remarks_placeholder')
                                        "
                                        :disabled="readOnlyMode && !editLocationDetails"
                                        :invalid="locationValidation?.pickup_driver_remarks.$error"
                                    />
                                    <div
                                        class="input-errors"
                                        v-for="error of locationValidation.pickup_driver_remarks
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="section shadow-2">
                        <div class="form-header">
                            <div>
                                <h3>{{ $t('location.pickupdays_label') }}</h3>
                            </div>

                            <div>
                                <Button
                                    outlined
                                    icon="pi pi-pencil"
                                    label="Edit"
                                    size="small"
                                    @click="enableEditPickUpDays()"
                                    v-if="!editPickUpDays && readOnlyMode"
                                />
                                <Button
                                    icon="pi pi-check"
                                    label="Save"
                                    size="small"
                                    @click="savePickUpDays()"
                                    v-if="editPickUpDays"
                                />
                            </div>
                        </div>
                        <div>
                            <div class="formgrid grid gap-3 justify-content-between px-4 pb-2">
                                <div class="flex align-items-center">
                                    <Checkbox
                                        v-model="locationObj.pick_up_days"
                                        inputId="monday"
                                        name="pickupDays"
                                        value="MONDAY"
                                        :disabled="readOnlyMode && !editPickUpDays"
                                        :inputClass="{ 'field-input': true }"
                                        @blur="locationValidation.pick_up_days.$touch()"
                                    />
                                    <label class="font-semibold ml-2" for="monday">
                                        {{ $t('location.pickupdays.monday') }}
                                    </label>
                                </div>
                                <div class="flex align-items-center">
                                    <Checkbox
                                        v-model="locationObj.pick_up_days"
                                        inputId="Tuesday"
                                        name="pickupDays"
                                        value="TUESDAY"
                                        :disabled="readOnlyMode && !editPickUpDays"
                                        :inputClass="{ 'field-input': true }"
                                        @blur="locationValidation.pick_up_days.$touch()"
                                    />
                                    <label class="font-semibold ml-2" for="Tuesday">
                                        {{ $t('location.pickupdays.tuesday') }}
                                    </label>
                                </div>
                                <div class="flex align-items-center">
                                    <Checkbox
                                        v-model="locationObj.pick_up_days"
                                        inputId="Wednesday"
                                        name="pickupDays"
                                        value="WEDNESDAY"
                                        :disabled="readOnlyMode && !editPickUpDays"
                                        :inputClass="{ 'field-input': true }"
                                        @blur="locationValidation.pick_up_days.$touch()"
                                    />
                                    <label class="font-semibold ml-2" for="Wednesday">
                                        {{ $t('location.pickupdays.wednesday') }}
                                    </label>
                                </div>
                                <div class="flex align-items-center">
                                    <Checkbox
                                        v-model="locationObj.pick_up_days"
                                        inputId="Thursday"
                                        name="pickupDays"
                                        value="THURSDAY"
                                        :disabled="readOnlyMode && !editPickUpDays"
                                        :inputClass="{ 'field-input': true }"
                                        @blur="locationValidation.pick_up_days.$touch()"
                                    />
                                    <label class="font-semibold ml-2" for="Thursday">
                                        {{ $t('location.pickupdays.thursday') }}
                                    </label>
                                </div>
                                <div class="flex align-items-center">
                                    <Checkbox
                                        v-model="locationObj.pick_up_days"
                                        inputId="Friday"
                                        name="pickupDays"
                                        value="FRIDAY"
                                        :disabled="readOnlyMode && !editPickUpDays"
                                        :inputClass="{ 'field-input': true }"
                                        @blur="locationValidation.pick_up_days.$touch()"
                                    />
                                    <label class="font-semibold ml-2" for="Friday">
                                        {{ $t('location.pickupdays.friday') }}
                                    </label>
                                </div>
                            </div>
                            <div
                                class="input-errors pl-3 mt-2"
                                v-for="error of locationValidation.pick_up_days.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                            <!-- <div class="py-4 px-3">
                                <div class="flex align-items-center gap-2">
                                    <label class="font-semibold" for="deliverySchedule"
                                        >{{ $t('location.delivery.label') }}:
                                    </label>
                                    <i
                                        class="pi pi-info-circle"
                                        style="font-size: 1rem; color: var(--takeables-secondary)"
                                        v-tooltip="$t('location.delivery.tooltip')"
                                    ></i>
                                    <div>
                                        <Button
                                            v-if="
                                                !editDeliverySchedule &&
                                                readOnlyMode &&
                                                deliveryScheduleOptions.length > 1
                                            "
                                            outlined
                                            icon="pi pi-pencil"
                                            label="Edit"
                                            size="small"
                                            @click="editDeliverySchedule = true"
                                        />
                                        <Button
                                            v-if="editDeliverySchedule"
                                            icon="pi pi-check"
                                            label="Save"
                                            size="small"
                                            @click="saveDeliverySchedule()"
                                        />
                                    </div>
                                </div>
                                 <template v-if="deliveryScheduleOptions.length > 1">
                                    <div>
                                        <div
                                            v-for="item in deliveryScheduleOptions"
                                            :key="item.value"
                                            class="flex align-items-center gap-2"
                                        >
                                            <RadioButton
                                                v-model="locationObj.delivery_schedule"
                                                :inputId="item.value"
                                                name="dynamic"
                                                :value="item.value"
                                                :disabled="readOnlyMode && !editDeliverySchedule"
                                                @blur="
                                                    locationValidation.delivery_schedule.$touch()
                                                "
                                            />
                                            <div
                                                class="input-errors"
                                                v-for="error of locationValidation.delivery_schedule
                                                    .$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">{{ error.$message }}</div>
                                            </div>
                                            <label :for="item.value">{{ item.label }}</label>
                                        </div>
                                    </div>
                                </template>
                                <template v-else> {{ deliveryScheduleOptions[0].label }} </template> 
                            </div>-->
                        </div>
                    </div>

                    <div class="section shadow-2">
                        <div class="flex justify-content-between">
                            <h3>{{ t('location.producerDetails') }}</h3>
                            <i
                                v-if="readOnlyMode"
                                class="pi pi-info-circle"
                                style="font-size: 1rem; color: var(--takeables-secondary)"
                                v-tooltip.left="
                                    $t('general.readOnlyFormToolTip', {
                                        CUSTOMER: customerName,
                                    })
                                "
                            ></i>
                        </div>
                        <div class="formgrid grid gap-3 justify-content-between px-4">
                            <div class="field w-3 flex-grow-1">
                                <label class="font-semibold" for="start_date"
                                    >{{ $t('location.start_date') }}:</label
                                >
                                <div>
                                    <Calendar
                                        id="start_date"
                                        v-model="startDate"
                                        @blur="locationValidation.start_date.$touch()"
                                        dateFormat="dd-mm-yy"
                                        :showIcon="!readOnlyMode"
                                        :disabled="readOnlyMode"
                                        :invalid="locationValidation?.start_date.$error"
                                    />
                                    <div
                                        class="input-errors"
                                        v-for="error of locationValidation.start_date.$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="field w-3 flex-grow-1">
                                <label class="font-semibold" for="end_date">{{
                                    $t('location.end_date')
                                }}</label>
                                <div>
                                    <span
                                        class="font-italic text-sm"
                                        v-if="!endDate && readOnlyMode"
                                        >{{ $t('location.no_end_date_message') }}</span
                                    >
                                    <Calendar
                                        v-else
                                        id="end_date"
                                        v-model="endDate"
                                        @blur="locationValidation.end_date.$touch()"
                                        dateFormat="dd-mm-yy"
                                        :showIcon="editEndDate || !readOnlyMode"
                                        :disabled="readOnlyMode && !editEndDate"
                                        :invalid="locationValidation?.end_date.$error"
                                    />
                                    <div
                                        class="input-errors"
                                        v-for="error of locationValidation.end_date.$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                                <div
                                    class="inline-save-wrapper"
                                    v-if="permStore.hasRole(ROLE.end_customer_manager)"
                                >
                                    <Button
                                        outlined
                                        icon="pi pi-pencil"
                                        label="Edit"
                                        size="small"
                                        @click="enableEditEndDate()"
                                        v-if="!editEndDate && readOnlyMode"
                                    />
                                    <Button
                                        icon="pi pi-check"
                                        label="Save"
                                        size="small"
                                        @click="saveEndDate()"
                                        v-if="editEndDate"
                                    />
                                </div>
                            </div>
                            <div class="field w-3 flex-grow-1">
                                <label class="font-semibold" for="nrOfEmployees">{{
                                    $t('location.nrOfEmployees')
                                }}</label>
                                <div>
                                    <InputNumber
                                        v-model="locationObj.nr_of_employees"
                                        type="text"
                                        id="nrOfEmployees"
                                        class="w-full"
                                        @blur="locationValidation.nr_of_employees.$touch()"
                                        :disabled="readOnlyMode && !editNrOfEmployees"
                                        :invalid="locationValidation?.nr_of_employees.$error"
                                    />
                                    <div
                                        class="input-errors"
                                        v-for="error of locationValidation.nr_of_employees.$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                    <div
                                        class="inline-save-wrapper"
                                        v-if="permStore.hasRole(ROLE.end_customer_manager)"
                                    >
                                        <Button
                                            outlined
                                            icon="pi pi-pencil"
                                            label="Edit"
                                            size="small"
                                            @click="enableEditNrOfEmployees()"
                                            v-if="!editNrOfEmployees && readOnlyMode"
                                        />
                                        <Button
                                            icon="pi pi-check"
                                            label="Save"
                                            size="small"
                                            @click="saveNrOfEmployees()"
                                            v-if="editNrOfEmployees"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="field w-3 flex-grow-1" v-if="subscriptionChoiceIsAvailable">
                                <label class="font-semibold" for="SubScriptionType"
                                    >{{ $t('customer.subScriptionType') }}:</label
                                >
                                <div>
                                    <DropDown
                                        v-model="locationObj.subscription_type"
                                        :options="allowedSubscriptionType"
                                        Placeholder="Selecteer een abonnement"
                                        :disabled="readOnlyMode"
                                        @blur="locationValidation.subscription_type.$touch()"
                                        :invalid="locationValidation?.subscription_type.$error"
                                    >
                                        <template #value="slotProps">
                                            {{ $t('subscription.' + slotProps.value) }}:
                                        </template>
                                        <template #option="slotProps">
                                            {{ $t('subscription.' + slotProps.option) }}:
                                        </template>
                                    </DropDown>
                                    <div
                                        class="input-errors"
                                        v-for="error of locationValidation.subscription_type
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="field w-3 flex-grow-1"
                                v-if="allowedCupSizes && allowedCupSizes.length > 1"
                            >
                                <label class="font-semibold" for="cupSize"
                                    >{{ $t('location.cupSize') }}:</label
                                >
                                <div>
                                    <MultiSelect
                                        v-model="locationObj.cupsizes"
                                        :options="allowedCupSizes"
                                        placeholder="Select CupSize"
                                        class="w-full md:w-20rem"
                                        :disabled="readOnlyMode"
                                        @blur="locationValidation.cupsizes.$touch()"
                                        :invalid="locationValidation?.cupsizes.$error"
                                    >
                                        <template #option="slotProps">
                                            <div class="flex align-items-center">
                                                {{ slotProps.option }}:
                                            </div>
                                        </template>
                                    </MultiSelect>
                                    <div
                                        class="input-errors"
                                        v-for="error of locationValidation.cupsizes.$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="flex justify-content-end py-3">
                    <Button
                        icon="pi pi-plus"
                        @click="addLocation"
                        rounded
                        v-if="!readOnlyMode"
                        :label="$t('location.addLocation')"
                    >
                    </Button>
                </div>
            </div>
        </div>
        <NotFoundPage v-else />
    </div>
</template>

<script setup lang="ts">
import Checkbox from 'primevue/checkbox'
import DropDown from 'primevue/dropdown'
import { ref, computed, watchEffect, onUnmounted, type Ref } from 'vue'
import AddressComponent from '@/address-management/AddressComponent.vue'
import type { Location, DeliverySchedule } from './customer'
import Calendar from 'primevue/calendar'
import ContactPerson from '@/customer-management/ContactPerson.vue'
import NotFoundPage from '@/views/NotFoundPage.vue'
import MultiSelect from 'primevue/multiselect'
import Textarea from 'primevue/textarea'
import { useToast } from 'primevue/usetoast'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useEndCustomerStore } from './EndCustomerStore'
import { useAuthCtxStore } from '@/authorization/authCtxStore'
import { usePermStore, ROLE, PERMISSION } from '@/authorization/permStore'
import { v4 as uuidv4 } from 'uuid'
import { minLength, minValue, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useConfirm } from 'primevue/useconfirm'

const { t } = useI18n()
const route = useRoute()
const toast = useToast()
const router = useRouter()

const endCustomerStore = useEndCustomerStore()
const confirm = useConfirm()
const readOnlyMode = ref(false)
let locationObj = ref({} as Location)

const editContactPersonDetails = ref(false)
const editLocationDetails = ref(false)
const editNrOfEmployees = ref(false)
const editEndDate = ref(false)
const editPickUpDays = ref(false)
const editDeliverySchedule = ref(false)
const loading = ref(true)

const permStore = usePermStore()
const authCtxStore = useAuthCtxStore()
const { customer } = storeToRefs(authCtxStore)
const customerName = computed(() => customer.value?.name)

const allowedCupSizes = computed(() => customer.value?.allowed_cupsizes)
const allowedSubscriptionType = computed(() => customer.value?.allowed_subscriptions)
const allowedDeliverySchedules = computed(() => customer.value?.allowed_delivery_schedules)

const deliveryScheduleLabel = (s: DeliverySchedule) =>
    s === 'ONCE_A_WEEK' ? t('location.delivery.once') : t('location.delivery.other')
const deliveryScheduleOption = (s: DeliverySchedule) => {
    return { value: s, label: deliveryScheduleLabel(s) }
}
const deliveryScheduleOptions: Ref<{ label: string; value: DeliverySchedule }[]> = computed(() => {
    return allowedDeliverySchedules.value
        ? allowedDeliverySchedules.value.map((s) => deliveryScheduleOption(s))
        : []
})
let isStreetAndTownRequired = ref(false)
const formRules = computed(() => {
    const localRules = {
        address: {
            street_no: { required },
            postcode: { required },
            street_no_addition: {},
            street_name: {},
            town: {},
        },
        contact_person: {
            name: { required },
            phone: { required },
            email: { required },
        },
        nr_of_employees: { minLength: minValue(1), required },
        pick_up_days: { minLength: minLength(2), required },
        subscription_type: {},
        cupsizes: {},
        delivery_schedule: {},
        description: {},
        building_number: {},
        external_reference: {},
        pickup_driver_remarks: {},
        start_date: {},
        end_date: {},
    }
    if (isStreetAndTownRequired.value) {
        localRules.address.street_name = { required }
        localRules.address.town = { required }
    }
    return localRules
})

const locationValidation = useVuelidate(formRules, locationObj as any)
defineExpose({
    locationValidation,
})

//const chosenCupSizes = ref(locationObj.value.cupsizes)

async function addLocation() {
    const validatationResult = await locationValidation.value.$validate()
    if (!validatationResult) {
        toast.add({
            severity: 'error',
            summary: 'Verplichte velden ontbreken',
            detail: '',
            life: 3000,
        })
        return
    }

    const location: Location = locationObj.value
    const result = await endCustomerStore.addLocationToCustomer(location)
    if (result) {
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Locatie is succesvol opgeslagen',
            life: 3000,
        })
        locationValidation.value.$reset()
        router.push({
            name: 'endcustomer_details',
            params: { end_customer_id: route.params.end_customer_id as string },
        })
    } else {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Locatie kon niet worden toegevoegd',
            life: 3000,
        })
    }
}

const startDate = computed({
    get: () => {
        // Format the date as dd-mm-yy
        if (locationObj.value.start_date) {
            return new Date(locationObj.value.start_date)
        } else {
            return null
        }
    },
    set: (newValue) => {
        // Update the rawDate when the user selects a new date
        locationObj.value.start_date = newValue ? dateStr(newValue) : null
    },
})

const endDate = computed({
    get: () => {
        // Format the date as dd-mm-yy
        if (locationObj.value.end_date) {
            return new Date(locationObj.value.end_date)
        } else {
            return null
        }
    },
    set: (newValue) => {
        // Update the rawDate when the user selects a new date
        locationObj.value.end_date = newValue ? dateStr(newValue) : null
    },
})
// Convert Date into date-only iso8601 string
function dateStr(rawDate: Date) {
    return rawDate.toJSON().split('T')[0]
}

function editContactPerson() {
    editContactPersonDetails.value = true
}
const subscriptionChoiceIsAvailable = computed(() => {
    return allowedSubscriptionType.value && allowedSubscriptionType.value.length > 1
})
async function saveContactPerson() {
    const result = await endCustomerStore.updateLocationContactPerson(
        locationObj.value,
        locationObj.value.contact_person,
    )
    if (result) {
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Contactpersoon opgeslagen',
            life: 3000,
        })
        locationValidation.value.contact_person.$reset()
        editContactPersonDetails.value = false
    } else {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'ContactPersoon niet opgeslagen',
            life: 3000,
        })
    }
}
function editLocationBtn() {
    editLocationDetails.value = true
}
async function saveLocation() {
    const result = await endCustomerStore.updateLocationDetails(
        locationObj.value,
        locationObj.value.description,
        locationObj.value.building_number,
        locationObj.value.external_reference,
        locationObj.value.pickup_driver_remarks,
    )
    if (result) {
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Locatie opgeslagen',
            life: 3000,
        })
        ;['description', 'building_number', 'external_reference', 'pickup_driver_remarks'].forEach(
            (field) => locationValidation.value[field].$reset(),
        )
        editLocationDetails.value = false
    } else {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Locatie niet opgeslagen',
            life: 3000,
        })
    }
}
function enableEditNrOfEmployees() {
    editNrOfEmployees.value = true
}
async function saveNrOfEmployees() {
    const result = await endCustomerStore.updateLocationNrOfEmployees(
        locationObj.value,
        locationObj.value.nr_of_employees ?? 0,
    )
    if (result) {
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Aantal medewerkers opgeslagen',
            life: 3000,
        })
        locationValidation.value.nr_of_employees.$reset()
        editNrOfEmployees.value = false
    } else {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Aantal medewerkers niet opgeslagen',
            life: 3000,
        })
    }
}
function enableEditEndDate() {
    editEndDate.value = true
}
async function saveEndDate() {
    const result = await endCustomerStore.deactivateLocationFromCustomer(
        locationObj.value.location_id,
        locationObj.value.end_date ?? null,
    )
    if (result) {
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Locatie beeindigd per opgegeven datum',
            life: 3000,
        })
        locationValidation.value.end_date.$reset()
        editEndDate.value = false
    } else {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Einddatum locatie niet opgeslagen',
            life: 3000,
        })
    }
}
function enableEditPickUpDays() {
    editPickUpDays.value = true
}
async function savePickUpDays() {
    const result = await endCustomerStore.updateLocationPickUpDays(
        locationObj.value,
        locationObj.value.pick_up_days,
    )
    if (result) {
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Ophaaldagen opgeslagen',
            life: 3000,
        })
        locationValidation.value.pick_up_days.$reset()
        editPickUpDays.value = false
    } else {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Ophaaldagen niet opgeslagen',
            life: 3000,
        })
    }
}

async function saveDeliverySchedule() {
    console.debug('Save delivery schedule')
    const loc = locationObj.value
    const result = await endCustomerStore.updateLocationDeliverySchedule(
        loc,
        loc.delivery_schedule!,
    )
    if (result) {
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: t('location.changed'),
            life: 3000,
        })
        editDeliverySchedule.value = false
    } else {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: t('location.unchanged'),
            life: 3000,
        })
    }
}

function createNewLocation(): Location {
    return {
        location_id: uuidv4(),
        end_customer_id:
            endCustomerStore.getActiveEndCustomer!.id || (route.params.customer_id as string),
        address: {
            street_no: undefined,
            street_name: '',
            street_type: '',
            floor: '',
            town: '',
            region: '',
            postcode: '',
            country: 'Netherlands',
        },
        contact_person: {
            name: endCustomerStore.getActiveEndCustomer?.contact_person.name ?? '',
            phone: endCustomerStore.getActiveEndCustomer?.contact_person.phone ?? '',
            email: endCustomerStore.getActiveEndCustomer?.contact_person.email ?? '',
        },
        nr_of_employees: 0,
        start_date: endCustomerStore.getActiveEndCustomer?.start_date ?? undefined,
        end_date: undefined,
        pick_up_days: [],
        subscription_type: endCustomerStore.getActiveEndCustomer?.subscription_type ?? 'WASH_ONLY',
        cupsizes: [],
        delivery_schedule: null,
        pickup_driver_remarks: null,
    }
}

watchEffect(async () => {
    try {
        if (route.params.location_id) {
            await endCustomerStore.getEndCustomerLocation(route.params.location_id as string)
            locationObj.value = endCustomerStore.selectedLocation!
            // If the route has a location_id, we are in read-only mode
            readOnlyMode.value = true
        } else {
            await endCustomerStore.setActiveEndCustomer(route.params.end_customer_id as string)
            locationObj.value = createNewLocation()
            readOnlyMode.value = false
        }
        loading.value = false
    } catch (error) {
        loading.value = false
    }
})

onUnmounted(() => {
    endCustomerStore.setSelectedLocation(undefined)
})

onBeforeRouteLeave((to, from, next) => {
    if (locationValidation.value.$anyDirty) {
        confirm.require({
            message: t('saveAlert.message'),
            header: t('saveAlert.title'),
            icon: 'pi pi-info-circle',
            position: 'top',
            rejectClass: 'p-button-secondary p-button-outlined',
            rejectLabel: t('saveAlert.back'),
            acceptLabel: t('saveAlert.leave'),
            group: 'positioned',
            accept: () => {
                next()
            },
            reject: () => {
                next(false)
            },
        })
    } else {
        next()
    }
})
</script>
<style scoped lang="scss">
h3 {
    font-size: 1.5rem;
    font-weight: 600;
}
.form-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    .inline-save-wrapper {
        float: right;
        margin-top: 3px;
    }
    .section {
        width: 40rem;
        border-radius: 6px;
        padding: 10px;
        flex-basis: auto;
        flex-grow: 1;

        .form-header {
            display: flex;
            justify-content: space-between;
            h3 {
                font-size: 1.5rem;
                font-weight: 600;
            }
        }
    }
}
</style>
