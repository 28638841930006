<script setup lang="ts">
import { ref, computed } from 'vue'

import DataTable from 'primevue/datatable'
import type { DataTableRowClickEvent } from 'primevue/datatable'
import Column from 'primevue/column'
import Chip from 'primevue/chip'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { useI18n } from 'vue-i18n'

import type { Components } from '@/types/openapi.d.ts'

import { type Shipment, useShipmentStore } from '../ShipmentStore'

type DateGroup = 'past' | 'today' | 'future'

const props = withDefaults(
    defineProps<{
        shipments: Shipment[]
        search?: boolean
        actions?: boolean
        grouping?: boolean
        multipleLocations?: boolean
    }>(),
    {
        search: false,
        actions: false,
        grouping: true,
        multipleLocations: false,
    },
)
const shipmentData = computed(() => {
    const today = new Date().toJSON().split('T')[0]
    return props.shipments.map((shipment) => {
        return {
            ...shipment,
            formattedAddress: formatLocation(shipment.location),
            future: new Date(shipment.planned_date).getTime() > new Date(today).getTime(),
            dateGroup: dateGroup(shipment, today),
        }
    })
})

const { t } = useI18n()
const confirm = useConfirm()
const toast = useToast()
const shipmentStore = useShipmentStore()

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
})

function dataKey(shipment: Shipment) {
    return shipment.shipment_id ?? `${shipment.location.location_id}_${shipment.planned_date}`
}

const expandedRows = ref<Shipment[]>([])
const expandedRowGroups = ref<DateGroup[]>(['today', 'future'])

function onRowClick(event: DataTableRowClickEvent) {
    let rowData = event.data
    updateExpandedRows(rowData)
}

function updateExpandedRows(rowData: Shipment) {
    if (expandedRows.value.includes(rowData)) {
        expandedRows.value = []
    } else {
        expandedRows.value = [rowData]
    }
}

const rowClass = (data: Shipment) => {
    const today = new Date().toJSON().split('T')[0]
    return {
        [data.type]: true,
        cancelled: !data.transporter_id && data.cancelled,
        today: data.planned_date === today,
        future: data.planned_date > today,
        past: data.planned_date < today,
    }
}

async function cancelShipment(shipment: Shipment) {
    await shipmentStore.cancel(shipment, true)
    toast.add({
        severity: 'success',
        summary: t('shipments.shipment_cancelled'),
        detail: t('shipments.shipment_cancelled_detail'),
        life: 3000,
    })
}

async function uncancelShipment(shipment: Shipment) {
    await shipmentStore.cancel(shipment, false)
    toast.add({
        severity: 'success',
        summary: t('shipments.shipment_uncancelled'),
        detail: t('shipments.shipment_uncancelled_detail'),
        life: 3000,
    })
}

const cancelShipmentConfirm = (event: any, data: Shipment) => {
    confirm.require({
        target: event.currentTarget,
        message: t('shipments.cancelShipmentModal.message'),
        icon: 'pi pi-exclamation-triangle',
        group: 'headless',
        accept: () => {
            cancelShipment(data)
        },
        reject: () => {},
    })
}

function formatLocation(location: Components.Schemas.Location): string {
    const { street_name, street_no, street_no_addition } = location.address
    return [street_name, street_no, street_no_addition, location?.building_number]
        .filter((x) => x)
        .join(' ')
}

const dateFormat = new Intl.DateTimeFormat('NL-nl', {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
})

function formatDate(date: string) {
    if (!date) {
        return '-'
    }
    // TODO: new Date parses date only string as UTC...
    return dateFormat.format(new Date(date))
}
function dateGroup(shipment: Shipment, today: string) {
    if (shipment.planned_date == today) {
        return 'today'
    } else if (shipment.planned_date > today) {
        return 'future'
    } else {
        return 'past'
    }
}

function transporterLink(shipment: Shipment): string {
    // TODO: get from backend, now a quick hack.
    // if we really want this, was just added for easier testing/debugging.
    // while we wait for transporter status implementation in our own backend.
    if (!shipment.transporter_id) {
        return ''
    }
    let apiDomain = 'api'
    if (
        ['localhost', 'develop.my.takeables.nl', 'staging.my.takeables.nl'].includes(
            globalThis.location.hostname,
        )
    ) {
        apiDomain = 'staging'
    }
    return `https://${apiDomain}.trans-mission.nl/tt/${shipment.transporter_id}/${shipment.location.address.postcode}`
}
</script>
<template>
    <DataTable
        :value="shipmentData"
        :dataKey="dataKey"
        :loading="shipmentStore.busy"
        stripedRows
        :rowClass="rowClass"
        style="min-width: 50rem"
        v-model:filters="filters"
        :globalFilterFields="['end_customer.company_name', 'formattedAddress']"
        v-model:expandedRows="expandedRows"
        @row-click="onRowClick"
        sortField="planned_date"
        :sortOrder="1"
        removableSort
        groupRowsBy="dateGroup"
        rowGroupMode="subheader"
        :expandableRowGroups="props.grouping"
        v-model:expandedRowGroups="expandedRowGroups"
    >
        <template v-if="props.search" #header>
            <div class="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon>
                        <i class="pi pi-search" />
                    </InputIcon>
                    <InputText
                        v-model="filters['global'].value"
                        :placeholder="t('general.search')"
                    />
                </IconField>
            </div>
        </template>
        <template #empty> {{ t('general.noResultsFound') }} </template>
        <template #loading> {{ t('general.loading') }} </template>
        <template v-if="props.grouping" #groupheader="slotProps">
            <template v-if="slotProps.data.dateGroup == 'past'">
                <div class="inline-flex align-items-center">
                    <i class="pi pi-history mr-2 ml-3" />
                    <span class="font-bold group-header-title">{{ t('shipments.past') }}</span>
                </div>
            </template>
            <template v-else-if="slotProps.data.dateGroup == 'today'">
                <div class="inline-flex align-items-center">
                    <i class="pi pi-calendar mr-2 ml-3" />
                    <span class="font-bold group-header-title">{{ t('shipments.today') }}</span>
                </div>
            </template>
            <template v-else-if="slotProps.data.dateGroup == 'future'">
                <div class="inline-flex align-items-center">
                    <i class="pi pi-fast-forward mr-2 ml-3" />
                    <span class="font-bold group-header-title">{{ t('shipments.future') }}</span>
                </div>
            </template>
            <template v-else>
                {{ slotProps.data.dateGroup }}
            </template>
        </template>
        <Column field="type" sortable :header="t('shipments.type')">
            <template v-slot:body="{ data }">
                <Chip
                    v-if="data.type == 'PICKUP'"
                    icon="pi pi-arrow-right"
                    :label="t('shipments.pickup')"
                    class="shipment-type"
                />
                <Chip
                    v-else-if="data.type == 'DELIVERY'"
                    icon="pi pi-sparkles"
                    :label="t('shipments.delivery')"
                    class="shipment-type"
                />
            </template>
        </Column>
        <Column
            v-if="false"
            field="end_customer.company_name"
            sortable
            :header="t('shipments.companyName')"
        ></Column>
        <Column
            v-if="props.multipleLocations"
            field="formattedAddress"
            sortable
            :header="t('shipments.location')"
        ></Column>
        <Column field="planned_date" sortable :header="t('shipments.planned_date')">
            <template v-slot:body="slotProps">
                {{ formatDate(slotProps.data.planned_date) }}
            </template>
        </Column>
        <Column :header="t('shipments.status')">
            <template #body="{ data }">
                <Chip
                    v-if="data.transporter_id"
                    icon="pi pi-calendar"
                    :label="t('shipments.scheduled')"
                />
                <Chip
                    v-else-if="data.cancelled"
                    icon="pi pi-ban"
                    :label="t('shipments.cancelled')"
                />
                <Chip v-else icon="pi pi-clock" :label="t('shipments.pending')" />
            </template>
        </Column>
        <Column v-if="props.actions" field="shipment" :header="t('shipments.actions')">
            <template #body="{ data }">
                <template v-if="!data.transporter_id">
                    <Button
                        v-if="data.cancelled"
                        rounded
                        :aria-label="t('shipments.schedule')"
                        v-tooltip="t('shipments.schedule')"
                        severity="secondary"
                        icon="pi pi-calendar-plus"
                        @click="uncancelShipment(data)"
                    />
                    <Button
                        v-else
                        rounded
                        :aria-label="t('shipments.cancel')"
                        v-tooltip="t('shipments.cancel')"
                        severity="danger"
                        icon="pi pi-ban "
                        @click="cancelShipmentConfirm($event, data)"
                    />
                </template>
            </template>
        </Column>

        <template #expansion="{ data }">
            <div class="p-3">TBD: Details of incoming shipments</div>
            <div v-if="data.transporter_id">
                Transporter:
                <a :href="transporterLink(data)" target="_blank">{{ data.transporter_id }}</a>
            </div>
        </template>
    </DataTable>
</template>
<style scoped lang="scss">
:deep(tr.cancelled) {
    text-decoration: line-through;
}
:deep(.p-column-title:first-letter) {
    text-transform: capitalize;
}
.group-header-title:first-letter {
    text-transform: capitalize;
}
.shipment-type {
    background-color: unset;
}
:deep(.today) {
    background-color: #03df2026;
}
:deep(.future) {
    background-color: #eeec1c26;
}
:deep(.past) {
    background-color: #f5f5f5;
}
</style>
