<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { usePermStore, PERMISSION } from '@/authorization/permStore'

const { t } = useI18n()
const permStore = usePermStore()
const hasPermission = computed(() => permStore.hasPermission(PERMISSION.order_product))
</script>
<template>
    <h1>{{ $t('sidebar.extraProducts') }}</h1>
    <div class="NoAccess" v-if="!hasPermission">
        {{ t('general.noAccess') }}
    </div>
    <div v-else>
        <router-view></router-view>
    </div>
</template>
