<template>
    <div class="add_endcustomer">
        <div class="form-section">
            <div class="section shadow-2">
                <div class="form-header">
                    <div>
                        <h3>{{ $t('ListEndCustomer.companyInformation') }}</h3>
                    </div>
                    <div>
                        <Button
                            outlined
                            icon="pi pi-pencil"
                            label="Edit"
                            size="small"
                            @click="editCompanyDetails()"
                            raised
                            v-if="!editDetails && readOnlyMode"
                        />
                        <Button
                            icon="pi pi-check"
                            label="Save"
                            size="small"
                            @click="saveCompanyDetails()"
                            v-if="editDetails"
                            raised
                        />
                    </div>
                </div>
                <div class="form-content">
                    <div class="field-wrapper">
                        <label for="companyName">{{ $t('customer.companyName') }}</label>
                        <div>
                            <InputText
                                v-model="newCustomerData.company_name"
                                type="text"
                                id="companyName"
                                :disabled="readOnlyMode && !editDetails"
                                @blur="endCustomerValidation.company_name.$touch()"
                                :invalid="endCustomerValidation.company_name.$error"
                            />
                            <div
                                class="input-errors"
                                v-for="error of endCustomerValidation.company_name.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="field-wrapper">
                        <label for="externalReference">{{
                            $t('customer.externalReference')
                        }}</label>
                        <div>
                            <InputText
                                v-model="newCustomerData.external_reference"
                                @blur="endCustomerValidation.external_reference.$touch()"
                                type="text"
                                id="externalReference"
                                :disabled="readOnlyMode && !editDetails"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="section shadow-2">
                <div class="form-header">
                    <div>
                        <h3>{{ $t('ListEndCustomer.deliveryDetails') }}</h3>
                    </div>
                    <div>
                        <Button
                            outlined
                            icon="pi pi-pencil"
                            label="Edit"
                            size="small"
                            raised
                            @click="enableEditServiceDetails()"
                            v-if="!editServiceDetails && readOnlyMode"
                        />
                        <Button
                            icon="pi pi-check"
                            label="Save"
                            size="small"
                            @click="saveServiceDetails()"
                            v-if="editServiceDetails"
                            raised
                        />
                    </div>
                </div>
                <div class="form-content">
                    <div class="field-wrapper">
                        <label for="startDate">{{ $t('customer.startDate') }}</label>
                        <div>
                            <Calendar
                                id="startDate"
                                v-model="startDate"
                                @blur="endCustomerValidation.start_date.$touch()"
                                dateFormat="dd-mm-yy"
                                :disabled="readOnlyMode && !editServiceDetails"
                                :showIcon="!readOnlyMode || editServiceDetails"
                                :invalid="endCustomerValidation.start_date.$error"
                            />
                            <div
                                class="input-errors"
                                v-for="error of endCustomerValidation.start_date.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="field-wrapper">
                        <label for="endDate">{{ $t('customer.endDate') }}</label>
                        <div>
                            <Calendar
                                id="endDate"
                                v-model="endDate"
                                @blur="endCustomerValidation.end_date.$touch()"
                                dateFormat="dd-mm-yy"
                                :disabled="readOnlyMode && !editServiceDetails"
                                :showIcon="!readOnlyMode || editServiceDetails"
                                :invalid="endCustomerValidation.end_date.$error"
                            />
                            <div
                                class="input-errors"
                                v-for="error of endCustomerValidation.end_date.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section shadow-2">
                <div class="form-header">
                    <div>
                        <h3>{{ $t('ListEndCustomer.contactperson') }}</h3>
                    </div>
                    <div>
                        <Button
                            outlined
                            icon="pi pi-pencil"
                            label="Edit"
                            size="small"
                            raised
                            @click="editContactPerson()"
                            v-if="!editContactPersonDetails && readOnlyMode"
                        />
                        <Button
                            icon="pi pi-check"
                            label="Save"
                            size="small"
                            @click="saveContactPerson()"
                            v-if="editContactPersonDetails"
                            raised
                        />
                    </div>
                </div>
                <ContactPerson
                    :contactPerson="newCustomerData.contact_person"
                    :read-only="readOnlyMode && !editContactPersonDetails"
                    :errors="errors"
                    :validation="endCustomerValidation.contact_person"
                />
            </div>
            <div class="section shadow-2" v-if="subscriptionChoiceIsAvailable">
                <div class="form-header">
                    <h3>{{ $t('customer.subScriptionType') }}</h3>
                </div>
                <div class="form-content">
                    <div class="field-wrapper">
                        <label style="width: 135px" for="SubScriptionType"
                            >{{ $t('customer.subScriptionType') }}:</label
                        >
                        <div>
                            <div v-if="readOnlyMode">
                                {{ newCustomerData.subscription_type }}
                            </div>
                            <DropDown
                                v-else
                                v-model="newCustomerData.subscription_type"
                                :options="allowedSubscriptionType"
                                Placeholder="Selecteer een abonnement"
                                :disabled="readOnlyMode"
                            >
                                <template #value="slotProps">
                                    {{ $t('subscription.' + slotProps.value) }}
                                </template>
                                <template #option="slotProps">
                                    {{ $t('subscription.' + slotProps.option) }}
                                </template>
                            </DropDown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import DropDown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import type { EndCustomer } from './customer'
import ContactPerson from './ContactPerson.vue'
import { useEndCustomerStore } from './EndCustomerStore'
import { useToast } from 'primevue/usetoast'
import { useAuthCtxStore } from '@/authorization/authCtxStore'
import { useVuelidate } from '@vuelidate/core'
import { onBeforeRouteLeave } from 'vue-router'
import { useConfirm } from 'primevue/useconfirm'
import { useI18n } from 'vue-i18n'
import { required } from '@vuelidate/validators'

const confirm = useConfirm()
const props = defineProps<{ endCustomer: EndCustomer; readOnly?: boolean; errors?: string[] }>()
const toast = useToast()
const authCtxStore = useAuthCtxStore()
const { t } = useI18n()

let newCustomerData = ref(props.endCustomer)
const allowedSubscriptionType = computed(() => {
    return authCtxStore.customer?.allowed_subscriptions ?? []
})

const readOnlyMode = ref(props.readOnly ?? false)
const editDetails = ref(false)
const editServiceDetails = ref(false)
const editContactPersonDetails = ref(false)
const endCustomerStore = useEndCustomerStore()

const formRules = {
    company_name: { required },
    contact_person: {
        name: { required },
        phone: { required },
        email: { required },
    },
    external_reference: {},
    start_date: { required },
    end_date: {},
}

const endCustomerValidation = useVuelidate(formRules, newCustomerData as any)

defineExpose({
    endCustomerValidation,
})

const startDate = computed<Date | null>({
    get: () => {
        if (newCustomerData.value.start_date) {
            return new Date(newCustomerData.value.start_date)
        } else {
            return null
        }
    },
    set: (newValue) => {
        // Update the rawDate when the user selects a new date
        newCustomerData.value.start_date = newValue ? dateStr(newValue) : ''
    },
})

const endDate = computed<Date | null>({
    get: () => {
        if (newCustomerData.value.end_date) {
            return new Date(newCustomerData.value.end_date)
        } else {
            return null
        }
    },
    set: (newValue: Date|null) => {
        // Update the rawDate when the user selects a new date
        newCustomerData.value.end_date = newValue ? dateStr(newValue) : ''
    },
})

// Convert Date into date-only iso8601 string
function dateStr(rawDate: Date) {
    return rawDate.toJSON().split('T')[0]
}

const subscriptionChoiceIsAvailable = computed(() => {
    return allowedSubscriptionType.value && allowedSubscriptionType.value.length > 1
})
function editCompanyDetails() {
    editDetails.value = true
}
async function saveCompanyDetails() {
    console.log('Saving company')
    const result = await endCustomerStore.updateEndCustomerDetails(
        newCustomerData.value.company_name,
        newCustomerData.value.external_reference ?? '',
    )

    if (result) {
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Klant opgeslagen',
            life: 3000,
        })
        endCustomerValidation.value.company_name.$reset()
        endCustomerValidation.value.external_reference.$reset()
        editDetails.value = false
    } else {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Klant niet opgeslagen',
            life: 3000,
        })
    }
}
function editContactPerson() {
    editContactPersonDetails.value = true
}
async function saveContactPerson() {
    console.log('savecontactpers')
    const result = await endCustomerStore.updateEndCustomerContactPerson(
        newCustomerData.value.contact_person,
    )
    if (result) {
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Contactpersoon opgeslagen',
            life: 3000,
        })
        endCustomerValidation.value.contact_person.$reset()
        editContactPersonDetails.value = false
    } else {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'ContactPersoon niet opgeslagen',
            life: 3000,
        })
    }
}
function enableEditServiceDetails() {
    editServiceDetails.value = true
}
async function saveServiceDetails() {
    const result = await endCustomerStore.updateEndCustomerServiceDetails(
        newCustomerData.value.start_date,
        newCustomerData.value.end_date,
    )

    if (result) {
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Leveringsgegevens opgeslagen',
            life: 3000,
        })
        endCustomerValidation.value.start_date.$reset()
        endCustomerValidation.value.end_date.$reset()
        editServiceDetails.value = false
    } else {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Leveringsgegevens niet opgeslagen',
            life: 3000,
        })
    }
}

onBeforeRouteLeave((to, from, next) => {
    if (endCustomerValidation.value.$anyDirty) {
        confirm.require({
            message: t('saveAlert.message'),
            header: t('saveAlert.title'),
            icon: 'pi pi-info-circle',
            position: 'top',
            rejectClass: 'p-button-secondary p-button-outlined',
            rejectLabel: t('saveAlert.back'),
            acceptLabel: t('saveAlert.leave'),
            group: 'positioned',
            accept: () => {
                next()
            },
            reject: () => {
                next(false)
            },
        })
    } else {
        next()
    }
})
</script>
<style scoped lang="scss">
h3 {
    margin: 0px 5px;
}
.form-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    .section {
        width: 20rem;
        border-radius: 6px;
        padding: 10px;
        flex-basis: auto;
        flex-grow: 1;
        .form-header {
            display: flex;
            justify-content: space-between;
            h3 {
                font-size: 1.5rem;
                font-weight: 600;
            }
        }
        .form-content {
            padding: 15px;
            padding-bottom: 0px;
            .field-wrapper {
                display: flex;
                margin-bottom: 20px;
                label {
                    width: 30%;
                    font-weight: 600;
                }
                div {
                    width: 70%;
                }
            }
        }
    }
}
</style>
```
