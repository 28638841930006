<template>
    <h1>{{ $t('myPage.title') }}</h1>
    <div class="formgrid">
        <div class="field grid">
            <label class="w-10rem">
                <b>
                    {{ $t('customer.companyName') }}
                </b>
            </label>
            <div class="">
                <p>{{ authCtxStore.customer?.name }}</p>
            </div>
        </div>
        <div class="field grid">
            <label class="w-10rem">
                <b> {{ $t('auth.username') }}: </b>
            </label>
            <div class="">
                <p>{{ userStore.user?.name }}</p>
            </div>
        </div>
        <div class="field grid">
            <label class="w-10rem">
                <b> {{ $t('auth.email') }}: </b>
            </label>
            <div class="flex align-items-center gap-2">
                <p>{{ userStore.user?.email }}</p>
                <i
                    v-if="roles?.includes('location_manager')"
                    class="pi pi-info-circle"
                    style="font-size: 1rem; color: var(--takeables-secondary)"
                    v-tooltip="
                        $t('auth.locationManagerEmailTooptip', {
                            END_CUSTOMER: endCustomerName,
                        })
                    "
                ></i>
            </div>
        </div>
        <div class="field grid">
            <label class="w-10rem">
                <b> {{ $t('auth.roles') }}: </b>
            </label>
            <div class="">
                <p v-for="role in roles" :key="role">
                    {{ role }}
                </p>
            </div>
        </div>
        <div class="field grid" v-if="roles?.includes('location_manager')">
            <label class="w-10rem">
                <b> {{ $t('customer.pickupAddress') }} </b>
            </label>
            <div class="">
                <p>
                    {{ pickupAddress }}
                </p>
            </div>
        </div>
        <div class="field grid" v-if="!roles?.includes('customer')">
            <label class="w-10rem">
                <b>
                    {{ $t('customer.contactPerson.phone') }}
                </b>
            </label>
            <div class="phone-number-wrapper">
                <div>
                    <!-- @ts-ignore -->
                    <InputMask
                        v-model="phoneNumber as string"
                        type="text"
                        id="phoneNumber"
                        mask="+31 999 999 999"
                        placeholder="+31 999 999 999"
                        :disabled="readOnlyMode"
                        @blur="
                            () => {
                                phoneValidation?.phoneNumber.$touch()
                            }
                        "
                        :invalid="phoneValidation?.phoneNumber.$error"
                    />
                    <div
                        class="input-errors"
                        v-for="error of phoneValidation.phoneNumber.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
                <div>
                    <Button
                        v-if="readOnlyMode"
                        outlined
                        icon="pi pi-pencil"
                        label="Edit"
                        size="small"
                        @click="readOnlyMode = false"
                        raised
                    />
                    <Button
                        v-else
                        icon="pi pi-check"
                        label="Save"
                        size="small"
                        @click="savePhoneNumber()"
                        raised
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAuthCtxStore } from '@/authorization/authCtxStore'
import { usePermStore, ROLE } from '@/authorization/permStore'
import { useEndCustomerStore } from '@/customer-management/EndCustomerStore'
import { useUserStore } from '@/authentication/userStore'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useToast } from 'primevue/usetoast'
import { onBeforeRouteLeave } from 'vue-router'
import { useConfirm } from 'primevue/useconfirm'

const confirm = useConfirm()
const toast = useToast()
const { t } = useI18n()
const permStore = usePermStore()
const authCtxStore = useAuthCtxStore()
const userStore = useUserStore()
const endCustomerStore = useEndCustomerStore()

const roles = computed(() => {
    // TODO: make backend result indicate if its a direct role (instead of relation
    const result = []
    for (const role of permStore.roles) {
        // switching to keep translation strings static
        switch (role.role_id) {
            case 'customer_manager':
                result.push(t('myPage.roles.customer_manager'))
                break
            case 'end_customer_manager':
                result.push(t('myPage.roles.end_customer_manager'))
                break
            case 'location_manager':
                result.push(t('myPage.roles.location_manager'))
                break
            case 'washer':
                result.push(t('myPage.roles.washer'))
                break
            default:
        }
    }
    return result
})

const pickupAddress = ref('-')
const phoneNumber = ref('-')
const readOnlyMode = ref(true)
const endCustomerName = ref('')

const formRules = {
    phoneNumber: { required },
}
const phoneValidation = useVuelidate(formRules, { phoneNumber })

onMounted(async () => {
    await getLocationManagerDetails()
    await getEndCustomerDetails()
    if (
        permStore.hasRole(ROLE.location_manager) &&
        endCustomerStore.selectedLocation?.end_customer_id
    ) {
        await endCustomerStore.setActiveEndCustomer(
            endCustomerStore.selectedLocation?.end_customer_id,
        )
        endCustomerName.value = endCustomerStore.activeEndCustomer?.contact_person.name || ''
    }
})

const getLocationManagerDetails = async () => {
    if (permStore.roles[0].role_id === ROLE.location_manager && permStore.roles[0].location_id) {
        const response = await endCustomerStore.getEndCustomerLocation(
            permStore.roles[0].location_id,
        )
        const addressObj = response?.address
        pickupAddress.value = `${addressObj?.street_no || ''} ${
            addressObj?.street_no_addition || ''
        } ${addressObj?.street_name || ''} ${addressObj?.town || ''} ${
            addressObj?.postcode || ''
        } ${addressObj?.country || ''}`

        if (response?.contact_person?.phone) {
            phoneNumber.value = response?.contact_person.phone
        }
    }
}

const getEndCustomerDetails = async () => {
    if (
        permStore.roles[0].role_id === ROLE.end_customer_manager &&
        permStore.roles[0].end_customer_id
    ) {
        await endCustomerStore.setActiveEndCustomer(permStore.roles[0].end_customer_id)
        if (endCustomerStore.activeEndCustomer?.contact_person.phone) {
            phoneNumber.value = endCustomerStore.activeEndCustomer?.contact_person.phone
        }
    }
}

const savePhoneNumber = async () => {
    // save phone number
    const role = permStore.roles[0].role_id
    if (role === ROLE.location_manager) {
        if (!endCustomerStore.selectedLocation) {
            console.error('selectedLocation not set in store')
            return
        }
        const result = await endCustomerStore.updateLocationContactPerson(
            endCustomerStore.selectedLocation,
            {
                ...endCustomerStore.selectedLocation.contact_person,
                phone: phoneNumber.value,
            },
        )
        if (result) {
            toast.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Contactpersoon opgeslagen',
                life: 3000,
            })
            phoneValidation.value.$reset()
            readOnlyMode.value = true
        } else {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'ContactPersoon niet opgeslagen',
                life: 3000,
            })
        }
    } else if (role === ROLE.end_customer_manager) {
        if (!endCustomerStore.activeEndCustomer) {
            console.error('activeEndCustomer not set in store')
            return
        }

        const result = await endCustomerStore.updateEndCustomerContactPerson({
            ...endCustomerStore.activeEndCustomer.contact_person,
            phone: phoneNumber.value,
        })
        if (result) {
            toast.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Contactpersoon opgeslagen',
                life: 3000,
            })
            phoneValidation.value.$reset()
            readOnlyMode.value = true
        } else {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'ContactPersoon niet opgeslagen',
                life: 3000,
            })
        }
    }
}

// forcing a deployment

const customers = computed(() => {
    return (
        permStore.roles.filter(({ customer_id }) => {
            return customer_id !== undefined && customer_id !== null && customer_id !== 'none'
        }) || []
    )
})

const endcustomers = computed(() => {
    return (
        permStore.roles.filter(({ end_customer_id }) => {
            return (
                end_customer_id !== undefined &&
                end_customer_id !== null &&
                end_customer_id !== 'none'
            )
        }) || []
    )
})

onBeforeRouteLeave((to, from, next) => {
    if (phoneValidation.value.$anyDirty) {
        confirm.require({
            message: t('saveAlert.message'),
            header: t('saveAlert.title'),
            icon: 'pi pi-info-circle',
            position: 'top',
            rejectClass: 'p-button-secondary p-button-outlined',
            rejectLabel: t('saveAlert.back'),
            acceptLabel: t('saveAlert.leave'),
            group: 'positioned',
            accept: () => {
                next()
            },
            reject: () => {
                next(false)
            },
        })
    } else {
        next()
    }
})

await userStore.fetchUser()
</script>
<style scoped lang="scss">
p {
    margin: 0;
}
.phone-number-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-left: -10px;
}
</style>
