import { ref, watchSyncEffect } from 'vue'
import { defineStore, acceptHMRUpdate } from 'pinia'

import { useAuthStore } from '@/authentication/authStore'
import cupflowClient from '@/shared/open_api_client/OpenApiClient'
import type { Components } from '@/types/openapi.d.ts'

export type Role = Components.Schemas.Role

// Permissions used for hasPermission calls.
// TODO: Do we want to push this whole type through openapi spec?
export const enum PERMISSION {
    customer_view = 'customer:customer:view',
    washing_cycle_view = 'washing:cycle:view',
    washing_cycle_add = 'washing:cycle:add',
    end_customer_add = 'customer:end_customer:add',
    end_customer_list = 'customer:end_customer:list',
    end_customer_view = 'customer:end_customer:view',
    end_customer_manage = 'customer:end_customer:manage',
    location_add = 'customer:location:add',
    location_list = 'customer:location:list',
    location_view = 'customer:location:view',
    location_manage = 'customer:location:manage',
    shipment_view = 'logistics:shipment:view',
    order_product = 'order:order:create',
}

// Reverse lookup map type:
type PermissionMap = { [K in PERMISSION]: string[] }

// Roles used in certain places. TODO: remove usages of these.
export const enum ROLE {
    customer_manager = 'customer_manager',
    end_customer_manager = 'end_customer_manager',
    location_manager = 'location_manager',
    washer = 'washer',
    washing = 'washing_manager',
}

// Permission store (and roles).
export const usePermStore = defineStore('perm', () => {
    const authStore = useAuthStore()

    const roles = ref<Role[]>([])
    const permissionMap = ref<PermissionMap>()

    const refresh = async () => {
        const response = await cupflowClient.get_authorization_auth_account_authorization_get()
        roles.value = response.data.roles
        permissionMap.value = response.data.permissions as PermissionMap
    }

    // computedAsync:
    watchSyncEffect(async () => {
        if (authStore.user) {
            await refresh()
        } else {
            roles.value = []
        }
    })

    const hasPermission = (permission: string): boolean => {
        for (const role of roles.value) {
            if (role.permissions.includes(permission)) {
                return true
            }
        }
        return false
    }

    // Has the authenticated user the given role.
    const hasRole = (role: ROLE) => {
        for (const r of roles.value) {
            if (r.role_id == role) {
                return true
            }
        }
        return false
    }

    // Reverse lookup resource IDs for a given permission.
    const idsForPermission = (perm: PERMISSION): string[] => {
        return (permissionMap.value ? permissionMap.value[perm] : []) ?? []
    }

    return {
        roles,
        refresh,
        hasPermission,
        hasRole,
        idsForPermission,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePermStore, import.meta.hot))
}
