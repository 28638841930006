import { createI18n } from 'vue-i18n';
// import nlNL from '@/i18n/locales/nl.json'
// import enUS from '@/i18n/locales/en.json'
import messages from '@intlify/unplugin-vue-i18n/messages'

export const i18n = createI18n({
    locale: 'nl',
    fallbackLocale: 'en',
    legacy: false,
    globalInjection: true,
    messages
    // : {
    //     en: enUS,
    //     nl: nlNL,
    // }
  });