import type { Components } from '@/types/openapi.d.ts'

type Address = Components.Schemas.Address

/**
 * Format an address to its streeet as a single line string.
 */
export function formatStreet(address: Address, nonBreaking = true) {
    const parts = [address.street_name, address.street_no]
    if (address.street_no_addition) {
        parts.push(address.street_no_addition)
    }
    const separator = nonBreaking ? '\xa0' : ' '
    return parts.join(separator)
}


/**
 * Format an address to a single line string.
 */
export function formatAddress(address: Address) {
  return [formatStreet(address), address.postcode, address.town].join(' ')
}
