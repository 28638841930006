import WasherLayout from './WasherLayout.vue'
import WasherMenuPage from './WasherMenuPage.vue'
import IncomingCratePage from './IncomingCratePage.vue'
import OutgoingCratePage from './OutgoingCratePage.vue'
import RegisterCratePage from './RegisterCratePage.vue'

// Routes for the special UI on scanner devices:
export const washerRoutes = [
    {
        path: '/washer',
        component: WasherLayout,
        children: [
            {
                path: 'menu',
                name: 'washer_menu',
                component: WasherMenuPage,
            },
            {
                path: 'register-crate',
                name: 'washer_register_crate',
                component: RegisterCratePage,
            },
            {
                path: 'incoming-crate',
                name: 'washer_incoming_crate',
                component: IncomingCratePage,
            },
            {
                path: 'outgoing-crate',
                name: 'washer_outgoing_crate',
                component: OutgoingCratePage,
            },
        ],
    },
]
