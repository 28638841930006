<script setup lang="ts">
import { ref } from 'vue'

import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import InputErrors from '@/shared/components/InputErrors.vue'
import { type EndCustomerLocation, type Account } from '@/stores/LocationStore'

const props = defineProps<{
    location: EndCustomerLocation
    addAccount: (name: string, email: string) => Promise<Account>
}>()
const emit = defineEmits<{
    (e: 'added', account: Account): void
    (e: 'cancelled'): void
}>()

const rules = {
    name: { required },
    email: { required, email },
}
const model = ref({
    name: '',
    email: '',
})
const busy = ref(false)

const v = useVuelidate(rules, model)
const { t } = useI18n()

const onCancel = () => {
    emit('cancelled')
}
const onSubmit = async () => {
    v.value.$touch()
    const isValid = await v.value.$validate()
    if (isValid) {
        busy.value = true
        const newAccount = await props.addAccount(model.value.name, model.value.email)
        emit('added', newAccount)
        busy.value = false
    }
}
</script>
<template>
    <form @submit.prevent="onSubmit">
        <div class="field grid">
            <label for="name" class="col-fixed" style="width: 10em">{{
                $t('customer.contactPerson.name')
            }}</label>
            <div class="col">
                <InputText autofocus v-model="v.name.$model" :invalid="v.name.$error" id="name" />
                <InputErrors :errors="v.name.$errors" />
            </div>
        </div>
        <div class="field grid">
            <label for="email" class="col-fixed" style="width: 10em">{{
                $t('customer.contactPerson.email')
            }}</label>
            <div class="col">
                <InputText v-model="v.email.$model" :invalid="v.email.$error" id="email" />
                <InputErrors :errors="v.email.$errors" />
            </div>
        </div>
        <div class="flex justify-content-between flex-wrap my-4">
            <Button
                icon="pi pi-times"
                rounded
                severity="secondary"
                :label="t('general.cancel')"
                :loading="busy"
                @click="onCancel"
            />
            <Button
                icon="pi pi-user-plus"
                rounded
                :label="t('location.addManager')"
                :loading="busy"
                type="submit"
            />
        </div>
    </form>
</template>
