<template>
    <div class="container">
        <sidebar></sidebar>
        <div class="w-full">
            <div
                class="flex justify-content-center align-items-center col-4 border-round-top bg-white logo-wrapper"
            >
                <img :src="logoImg" class="logo" style="height: 50px" />
            </div>
            <div v-if="breadcrumbs.length > 0">
                <Breadcrumb :model="breadcrumbs">
                    <template #item="{ item, props }">
                        <router-link
                            v-if="item.route"
                            v-slot="{ href, navigate }"
                            :to="item.route"
                            :key="item.route"
                            custom
                        >
                            <a :href="href" v-bind="props.action" @click="navigate">
                                <span :class="[item.icon, 'text-color']" />
                                <span class="text-color text-sm font-normal">{{ item.label }}</span>
                            </a>
                        </router-link>
                        <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                            <span class="text-color text-sm font-normal cursor-pointer">{{
                                item.label
                            }}</span>
                        </a>
                    </template>
                </Breadcrumb>
                <div class="bg-white pt-2 pl-5">
                    <Button @click="router.go(-1)">
                        <i class="pi pi-arrow-left"></i>
                    </Button>
                </div>
            </div>
            <div
                class="col-12 border-round-bottom bg-white py-0 px-0 h-auto overflow-hidden"
                :class="{ 'border-top-right-radius': breadcrumbs.length === 0 }"
            >
                <div
                    class="overflow-auto px-5 default-height"
                    :class="{ 'height-with-breadcrumb': breadcrumbs.length > 0 }"
                >
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, watch, onMounted, ref, type Ref } from 'vue'
import { storeToRefs } from 'pinia'

import { useAuthCtxStore } from '@/authorization/authCtxStore'
import { usePermStore, ROLE } from '@/authorization/permStore'
import { useBreadcrumbStore, type breadcrumb } from '@/stores/BreadcrumbStore'
import router from '@/router'

const authCtxStore = useAuthCtxStore()
const permStore = usePermStore()
const breadcrumbStore = useBreadcrumbStore()

const logoImg = computed(
    () => '/customer-logos/' + (authCtxStore.customer?.logo ?? 'takeables-logo.png'),
)

const roles = computed(() => {
    return permStore.roles.map(({ role_id }) => role_id)
})

const { breadcrumbItems } = storeToRefs(breadcrumbStore)
const breadcrumbs: Ref<breadcrumb[]> = ref(breadcrumbItems.value)

watch(breadcrumbItems, () => {
    breadcrumbs.value = breadcrumbItems.value
})

// Function to get the background color based on user role
function getRoleThemeColor() {
    if (roles.value && roles.value.length > 0) {
        if (roles.value[0] === ROLE.customer_manager) {
            return '--takeables-customer-theme'
        } else if (roles.value[0] === ROLE.end_customer_manager) {
            return '--takeables-end-customer-theme'
        } else if (roles.value[0] === ROLE.location_manager) {
            return '--takeables-location-manager-theme'
        }
    }
}

// Function to get the background color based on the URL
function getBackgroundThemeColor() {
    const currentURL = window.location.hostname
    switch (true) {
        case currentURL.includes('localhost'):
        case currentURL.includes('develop'):
            return '--takeables-develop-env-theme-color'

        case currentURL.includes('staging'):
            return '--takeables-staging-env-theme-color'

        default:
            return '--takeables-prod-env-theme-color'
    }
}

onMounted(() => {
    // Set the background color based on the URL
    document.documentElement.style.setProperty(
        '--color-background',
        'var(' + getBackgroundThemeColor() + ')',
    )

    // Set the role color based on the user role
    document.documentElement.style.setProperty(
        '--takeables-role-theme-color',
        'var(' + getRoleThemeColor() + ')',
    )
})
</script>

<style scoped lang="scss">
.container {
    background: var(--color-background);
    display: flex;
    padding: 50px;
    height: 100vh;
    gap: 40px;
}

.logo-wrapper {
    border-top: 10px solid var(--takeables-role-theme-color);
    height: 85px;
    width: 300px;
}

.border-top-right-radius {
    border-top-right-radius: var(--border-radius);
}

.text-color {
    color: var(--color-background) !important;
}
.p-menuitem-link:hover {
    color: var(--color-background);
    text-decoration: underline;
}

.default-height {
    height: calc(100vh - 185px);
}

.height-with-breadcrumb {
    height: calc(100vh - 225px);
}
</style>
