<script setup lang="ts">
import { watch, ref } from 'vue'

import { useI18n } from 'vue-i18n'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog'
import { useConfirm } from 'primevue/useconfirm'

import {
    useLocationStore,
    type Account as _Account,
    type EndCustomerLocation,
} from '@/stores/LocationStore'
import AddManager from './AddManager.vue'

import { useLocationManagers } from '../composables/locationManagers'

interface Account extends _Account {
    busy?: boolean
}

const props = defineProps<{
    location: EndCustomerLocation
}>()

const showAddDialog = ref(false)

const { t } = useI18n()
const confirm = useConfirm()
const locationStore = useLocationStore()
const { managers, loadingManagers, addManager, removeManager } = useLocationManagers(props.location.location_id)

const onRemove = (ev: Event, account: Account): void => {
    confirm.require({
        target: ev.currentTarget as HTMLElement,
        message: t('location.confirmRemoveManager'),
        icon: 'pi pi-exclamation-triangle',
        group: 'headless',
        accept: () => {
            remove(account)
        },
        reject: () => {},
    })
}

const remove = async (account: Account): Promise<void> => {
    account.busy = true
    await removeManager(account)
}

const onAdded = (acc: _Account) => {
    console.log('added', acc)
    showAddDialog.value = false
}
</script>
<template>
    <DataTable :value="managers as Account[]" :loading="loadingManagers">

        <template #empty>{{ t('location.emptyManagersList') }}</template>
        <template #header>
            <div class="flex flex-wrap align-items-center justify-content-end">
                <Button
                    icon="pi pi-plus"
                    rounded
                    raised
                    severity="secondary"
                    :label="$t('location.addManager')"
                    @click="showAddDialog = true"
                />
            </div>
        </template>
        <Column field="name" :header="t('customer.contactPerson.name')" />
        <Column field="email" :header="t('customer.contactPerson.email')" />
        <Column :exportable="false" bodyStyle="text-align: right">
            <template #body="{ data }">
                <Button
                    icon="pi pi-trash"
                    outlined
                    rounded
                    severity="danger"
                    :aria-label="t('general.remove')"
                    @click="onRemove($event, data)"
                    :loading="data.busy"
                />
            </template>
        </Column>
    </DataTable>
    <Dialog
        v-model:visible="showAddDialog"
        modal
        :header="t('location.addManager')"
        :style="{ width: '42em' }"
    >
        <AddManager
            :location="props.location"
            :addAccount="addManager"
            @added="onAdded"
            @cancelled="showAddDialog = false"
        />
    </Dialog>
</template>
