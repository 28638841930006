import { type Ref, ref, computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import cupflowClient from '@/shared/open_api_client/OpenApiClient'
import type { Components } from '@/types/openapi.d.ts'
import axios from 'axios'

type CupSize = Components.Schemas.CupSize

// For now we keep it here, since it probably won't change often (not worth an API call)
// But do keep it in sync with the same const variable in the backend.
const CRATE_CAPACITY = { '180': 800, '230': 384 } as const

export const useCrateStore = defineStore(
    'CrateStore',
    () => {
        async function fetchCupSizeOptions(crate_id: string) {
            try {
                const response = await cupflowClient.cups_size_options_crates_cup_size_options_get({
                    crate_id,
                })
                return response.data
            } catch (error) {
                console.error(error)
                return []
            }
        }

        async function fetchPartialCratesBasedOnLocation(
            location_id: string,
        ): Promise<Components.Schemas.CrateCupCountList | null> {
            try {
                const response =
                    await cupflowClient.list_partially_filled_crates_location__location_id__partially_filled_get(
                        {
                            location_id,
                        },
                    )
                return response.data
            } catch (error) {
                console.error(error)
                return null
            }
        }

        async function detachCratesFromLocation(crate_id: string): Promise<boolean> {
            const response =
                await cupflowClient.detach_from_location_crates_detach_from_location_post({
                    crate_id,
                })
            return response.data.succes
        }

        async function registerOutgoingCrate(
            crate_id: string,
            end_customer_location: string,
        ): Promise<boolean> {
            const response =
                await cupflowClient.register_outgoing_crate_crates_register_outgoing_post({
                    crate_id,
                    end_customer_location,
                })
            return response.status === 200
        }

        async function registerWashCycleOutgoing(
            object: Components.Schemas.RegisterOutgoingRequest,
        ): Promise<boolean> {
            try {
                const response =
                    await cupflowClient.register_outgoing_washing_wash_cycle_outgoing_post(
                        null,
                        object,
                    )
                return response.status === 200
            } catch (error) {
                console.error(error)
                return false
            }
        }

        async function registerIncomingCrate(
            object: Components.Schemas.BodyRegisterIncomingCrateShipmentsRegisterIncomingCratePost,
        ): Promise<Components.Schemas.RegisterIncomingCrateReponse | null> {
            try {
                const response =
                    await cupflowClient.register_incoming_crate_shipments_register_incoming_crate_post(
                        null,
                        object,
                    )
                if (response.status === 200) {
                    return response.data
                }
                return null
            } catch (error) {
                console.error(error)
                return null
            }
        }

        function crateCapacity(cupSize: CupSize): number {
            return CRATE_CAPACITY[cupSize]
        }

        return {
            fetchCupSizeOptions,
            crateCapacity,
            detachCratesFromLocation,
            registerOutgoingCrate,
            registerWashCycleOutgoing,
            registerIncomingCrate,
            fetchPartialCratesBasedOnLocation,
        }
    },
    {},
)
