<template>
    <div>
        <h2>Pick Clean Cups</h2>
        <Button
            rounded
            class="h-2rem w-2rem absolute top-0 left-0 mt-3 ml-5"
            icon="pi pi-arrow-left"
            :pt="{
                icon: {
                    class: 'text-sm',
                },
            }"
            @click="() => $router.push({ name: 'pickup_menu' })"
        >
        </Button>
        <div class="card-wrapper text-black-alpha-90">
            <div class="w-full">
                <Tag
                    style="background-color: #8e0444; color: white"
                    class="text-md w-full ellipsis"
                    :value="selectedTask?.company"
                ></Tag>
                <Tag
                    class="text-md location-tag w-full ellipsis"
                    :value="selectedTask?.location"
                ></Tag>
            </div>
            <div class="mb-1">
                <h3 class="text-center font-bold p-0 underline">Crates</h3>
                <p class="m-0 text-xs" v-if="unitType === 'PALLET'">
                    Swipe horizontally to see all the crates for this pallet. (
                    <span class="font-bold"
                        >{{ selectedTask?.crates?.filter((crate) => crate.scanned).length }}/{{
                            selectedTask?.crates?.length
                        }}</span
                    >
                    crates scanned )
                </p>
                <div class="card flex" style="max-height: 100px">
                    <div
                        class="flex flex-column align-items-center justify-content-evenly flex-wrap my-2"
                        style="width: 100%; overflow: scroll"
                    >
                        <div
                            v-for="crates in selectedTask?.crates"
                            :key="crates.crate_id"
                            class="flex align-items-center text-md"
                        >
                            <div class="p-1 flex align-items-center gap-1">
                                <svg
                                    v-if="crates?.scanned"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    height="20px"
                                    fill="currentColor"
                                    style="color: #28c628"
                                >
                                    <path
                                        d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
                                    />
                                </svg>
                                {{ crates.crate_id }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-column gap-2">
                <InputGroup>
                    <InputText
                        ref="crateIdElement"
                        class="h-3rem text-lg border-noround-right"
                        id="input"
                        :pt="{
                            root: ({ props }) => ({
                                class: [props.invalid ? 'border-2' : undefined],
                            }),
                        }"
                        v-model="scannedCrateId"
                        @input="saveTask"
                        :invalid="!isCrateValid"
                        type="text"
                        placeholder="Scan Crate ID"
                    />
                    <Button
                        icon="pi pi-times"
                        severity="danger"
                        @click="
                            () => {
                                scannedCrateId = ''
                                isCrateValid = true
                                if (crateIdElement?.$el) {
                                    crateIdElement.$el.focus()
                                }
                            }
                        "
                    />
                </InputGroup>

                <InputGroup v-if="showShipmentLabel()">
                    <InputText
                        ref="shipmentLabelElement"
                        class="h-3rem text-lg border-noround-right"
                        id="input"
                        :pt="{
                            root: ({ props }) => ({
                                class: [props.invalid ? 'border-2' : undefined],
                            }),
                        }"
                        v-model="scannedShipmentLabel"
                        @input="saveTask"
                        :invalid="!isShipmentLabelValid"
                        :disabled="isFormLoading"
                        type="text"
                        placeholder="Shipment Label"
                    />
                    <Button
                        icon="pi pi-times"
                        severity="danger"
                        @click="
                            () => {
                                scannedShipmentLabel = ''
                                isShipmentLabelValid = true
                                if (shipmentLabelElement?.$el) {
                                    shipmentLabelElement.$el.focus()
                                }
                            }
                        "
                    />
                </InputGroup>
            </div>

            <div class="text-right">
                <Button
                    class="text-lg mt-2"
                    rounded
                    icon="pi pi-check"
                    label="Picked"
                    :pt="{
                        icon: {
                            class: 'text-lg ml-2',
                        },
                    }"
                    iconPos="right"
                    @click="saveTask"
                    :loading="isFormLoading"
                >
                </Button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { usePickupStore } from '@/pick-tasks/PickupStore'
import { ref, onMounted, onUnmounted, watchEffect, type Ref } from 'vue'
import { router } from '@/router'
import Tag from 'primevue/tag'
import { useToast } from 'primevue/usetoast'
import { storeToRefs } from 'pinia'
import type { Components } from '@/types/openapi'
import { type IPickupTask } from '@/pick-tasks/PickupStore'

type CrateResponse = Components.Schemas.CrateResponse & { shipment_unit_id?: string }
type Product = Components.Schemas.Product
type Address = Components.Schemas.Address
type ShipmentUnitUpdate = Components.Schemas.ShipmentUnitUpdate
type ShipmentResponse = Components.Schemas.ShipmentResponse

const pickupStore = usePickupStore()
const { submitScannedCrates, fetchPendingDeliveryShipments } = pickupStore
const { pendingShipments } = storeToRefs(pickupStore)
const toast = useToast()

const scannedCrateId = ref('')
const scannedShipmentLabel = ref('')
const successCratesArr: Ref<ShipmentUnitUpdate[]> = ref([])

const crateIdElement = ref()
const shipmentLabelElement = ref()
const isCrateValid = ref(true)
const isShipmentLabelValid = ref(true)
const isFormLoading = ref(false)
const unitType = ref('')

const props = defineProps<{
    shipment_id: string
}>()
const selectedTask: Ref<IPickupTask | undefined> = ref()

// show shipment label input field only when the unit type is PALLET and there is only one crate left to scan
function showShipmentLabel(): boolean {
    if (unitType.value !== 'PALLET') {
        return true
    }

    const totNumberOfCrates = selectedTask.value?.crates?.length
    const numberOfScannedCrates = selectedTask.value?.crates?.filter((crate) => crate.scanned)
        .length
    if (
        unitType.value === 'PALLET' &&
        totNumberOfCrates &&
        numberOfScannedCrates &&
        totNumberOfCrates - numberOfScannedCrates === 1
    ) {
        return true
    }
    return false
}

const unwatch = watchEffect(async () => {
    if (props.shipment_id) {
        await fetchPendingDeliveryShipments()
    }
    if (pendingShipments.value) {
        const shipmentObj: ShipmentResponse | undefined = pendingShipments.value.find(
            (shipment: any) => shipment.shipment_id === props.shipment_id,
        )

        if (!shipmentObj || !shipmentObj.units) {
            return
        }

        const { units } = shipmentObj

        if (shipmentObj) {
            const { location, shipment_id } = shipmentObj
            const { address } = location
            const stringAddress = formatShipmentAddress(address)
            const cleanCups: CrateResponse[] = []
            units.forEach((unit) => {
                if (unit.crates.length > 0) {
                    unitType.value = unit.unit_type
                }
                unit.crates.forEach((crate) => {
                    cleanCups.push({ ...crate, shipment_unit_id: unit.shipment_unit_id })
                })
            })
            if (cleanCups && cleanCups.length > 0) {
                selectedTask.value = {
                    type: 'Clean Cups',
                    company: shipmentObj?.end_customer?.company_name ?? 'COMPANY_NAME',
                    location: stringAddress,
                    crates: cleanCups,
                    shipment_id,
                }
            }
        }
    }
})

function formatShipmentAddress(address: Address) {
    const streetInfo = address.street_no_addition
        ? `${address.street_no}-${address.street_no_addition}`
        : address.street_no
    return `${address.street_name} ${streetInfo}, ${address.postcode}, ${address.town}`
}

onMounted(() => {
    if (crateIdElement.value.$el) {
        crateIdElement.value.$el.focus()
    }
})

onUnmounted(() => {
    unwatch()
})

async function saveTask() {
    if (!selectedTask.value) {
        return
    }

    getCrateIdFromURL()
    if (showShipmentLabel()) {
        getShipmentLabelFromURL()
    } else {
        isShipmentLabelValid.value = true
    }

    if (!(isCrateValid.value && isShipmentLabelValid.value)) {
        return
    }

    isFormLoading.value = true

    const scannedCratesIndex = selectedTask.value?.crates?.findIndex(
        (a) => a.crate_id === scannedCrateId.value,
    )

    if (scannedCratesIndex !== undefined && scannedCratesIndex > -1) {
        selectedTask.value.crates![scannedCratesIndex].scanned = true
        successCratesArr.value.push({
            crate_id: scannedCrateId.value,
            transporter_label: scannedShipmentLabel.value,
            shipment_unit_id: selectedTask.value.crates![scannedCratesIndex].shipment_unit_id!,
        })
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Picked',
            life: 3000,
        })
    } else {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Incorrect crate was scanned',
            life: 3000,
        })
    }

    if (selectedTask.value.crates!.every((crate) => crate.scanned)) {
        if (unitType.value === 'PALLET') {
            // set the last scanned shipmentLabel to all the units, since
            // all crates goes to one pallet
            successCratesArr.value = successCratesArr.value.map((unit) => {
                return { ...unit, transporter_label: scannedShipmentLabel.value }
            })
        }
        const response = await submitScannedCrates({
            shipment_id: selectedTask.value.shipment_id!,
            units: successCratesArr.value,
        })
        if (response) {
            router.push({ name: 'pickup_menu' })
        } else {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Something went wrong',
                life: 3000,
            })
        }
    }

    scannedCrateId.value = ''
    scannedShipmentLabel.value = ''
    isFormLoading.value = false
    isCrateValid.value = true
    isShipmentLabelValid.value = true
    if (crateIdElement.value?.$el && shipmentLabelElement.value?.$el) {
        crateIdElement.value.$el.blur()
        shipmentLabelElement.value?.$el.blur()
    }

    crateIdElement.value?.$el.focus()
}

function getCrateIdFromURL() {
    if (scannedCrateId.value) {
        try {
            // https://my.takeables.nl/crate/qr_scan/23jq4pnpt7
            const url = new URL(scannedCrateId.value)
            if (!url.hostname.includes('takeables')) {
                toast.add({
                    severity: 'error',
                    summary: 'Invalid Crate ID',
                    detail: 'Please scan a valid code',
                    life: 4000,
                })
                isCrateValid.value = false
                return
            }

            const code = url.pathname.replace(/\/$/, '').split('/').pop()
            if (code) {
                scannedCrateId.value = code
                isCrateValid.value = true
                focusOnShipmentLabel()
            }
            return
        } catch (error) {
            // if crate_id is not a URL, validate the crate id which should have 10 characters
            if (scannedCrateId.value.length === 10) {
                isCrateValid.value = true
                focusOnShipmentLabel()
            } else {
                isCrateValid.value = false
            }
            return
        }
    }
    isCrateValid.value = false
}

function getShipmentLabelFromURL() {
    if (scannedShipmentLabel.value) {
        try {
            // external URL from Koopman is found
            const url = new URL(scannedShipmentLabel.value)
            const code = url.pathname.replace(/\/$/, '').split('/').pop()
            if (code) {
                scannedShipmentLabel.value = code
                isShipmentLabelValid.value = true
            }

            return
        } catch (error) {
            // if shipment_label is not a URL, validate the shipment label which should have more than 10 characters and ends with 3 digits
            const shipmentId = scannedShipmentLabel.value
            if (shipmentId.length > 10 && /^\d{3}$/.test(shipmentId.slice(-3))) {
                isShipmentLabelValid.value = true
            } else {
                isShipmentLabelValid.value = false
            }
            return
        }
    }
    isShipmentLabelValid.value = false
}

function focusOnShipmentLabel() {
    if (shipmentLabelElement.value?.$el) {
        shipmentLabelElement.value?.$el.focus()
    }
}
</script>

<style scoped lang="scss">
h2 {
    color: white;
    text-align: center;
    padding: 0px;
    font-weight: bold;
}

.card-wrapper {
    display: flex;
    flex-direction: column;
    margin: 10px;
    background-color: white;
    border-radius: 12px;
    padding: 10px;
    overflow-y: scroll;
    height: 75vh;
}

.location-tag {
    background-color: #07112f;
    color: white;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
}

.visibility-hidden {
    visibility: hidden;
}
</style>
