import { defineStore } from 'pinia'
import cupflowClient from '@/shared/open_api_client/OpenApiClient'
import { type Ref, ref } from 'vue'
import type { Address } from '@/address-management/Address'

import type { Paths } from '@/types/openapi.d.ts'

export const useAddressStore = defineStore('addressStore', () => {
    const currentAddress: Ref<Address> = ref({} as Address)
    const isValid: Ref<boolean> = ref(false)
    const errorMessage: Ref<string> = ref('')

    async function getAddressForPostCodeHouseNumber(
        postcode: string,
        houseNumber: string,
        houseNumberAddition: string,
    ) {
        if (postcode && houseNumber) {
            //@ts-ignore TODO: issue with the type, will have to look into
            const getAddressForPostCodeHouseNumberRequest: Paths.GetAddressForPostcodeHousenumberAddressGetAddressForPostcodeHousenumberGet.Parameters<Paths.GetAddressForPostcodeHousenumberAddressGetAddressForPostcodeHousenumberGet.QueryParameters> =
                {
                    house_number: houseNumber,
                    house_number_addition: houseNumberAddition,
                    postcode: postcode,
                }

            try {
                const result =
                    await cupflowClient.get_address_for_postcode_housenumber_address_get_address_for_postcode_housenumber_get(
                        getAddressForPostCodeHouseNumberRequest,
                    )
                if (result === undefined) {
                    isValid.value = false
                } else {
                    currentAddress.value = result.data
                    isValid.value = true
                }
            } catch (error) {
                console.log(error)
                isValid.value = false
            }
        }
    }

    return { isValid, currentAddress, getAddressForPostCodeHouseNumber, errorMessage }
})
