<template>
    <div
        class="bg-primary border-1 border-white h-full border-round flex flex-column align-items-center"
    >
        <div class="pt-2 px-3 w-full">
            <div class="text-center">
                <img src="@/assets/takeables.svg" alt="TakeAbles Logo" class="logo h-1rem" />
            </div>
            <Button
                rounded
                class="h-3rem w-3rem absolute top-0 left-0 mt-3 ml-5"
                icon="pi pi-arrow-left"
                :pt="{
                    icon: {
                        class: 'text-2xl',
                    },
                }"
                @click="() => $router.push({ name: 'washer_menu' })"
            >
            </Button>
        </div>
        <h4 class="text-center font-bold mt-0 mb-3">Register Crate</h4>
        <div class="p-card py-2" style="width: 95%">
            <Stepper v-model:activeStep="active" orientation="vertical">
                <StepperPanel
                    :pt="{
                        separator: {
                            style: 'margin-left: 30px',
                        },
                    }"
                >
                    <template #header="{ index, clickCallback }">
                        <button
                            class="bg-transparent border-none inline-flex flex-column gap-2"
                            @click="clickCallback"
                        >
                            <span
                                :class="[
                                    'border-circle border-2 w-2rem h-2rem inline-flex align-items-center justify-content-center',
                                    {
                                        'bg-primary border-primary': index <= active,
                                        'surface-border': index > active,
                                    },
                                ]"
                            >
                                <i class="pi pi-qrcode" />
                            </span>
                        </button>
                        <div class="w-full" @click="clickCallback">
                            <InputText
                                ref="crateIdElement"
                                class="h-3rem text-lg"
                                id="input"
                                v-model="crateId"
                                @keyup="jumpToLocationSelection"
                                type="text"
                                placeholder="Crate ID"
                            />
                        </div>
                    </template>
                    <template #content="{ nextCallback }">
                        <span class="mr-1"></span>
                    </template>
                </StepperPanel>
                <StepperPanel
                    :pt="{
                        separator: {
                            style: 'margin-left: 30px',
                        },
                    }"
                >
                    <template #header="{ index, clickCallback }">
                        <button
                            class="bg-transparent border-none inline-flex flex-column gap-2"
                            @click="clickCallback"
                        >
                            <span
                                :class="[
                                    'border-circle border-2 w-2rem h-2rem inline-flex align-items-center justify-content-center',
                                    {
                                        'bg-primary border-primary': index <= active,
                                        'surface-border': index > active,
                                    },
                                ]"
                            >
                                <i class="pi pi-map-marker" />
                            </span>
                        </button>
                        <div class="w-full">
                            <DropDown
                                ref="locationSelectionElement"
                                :options="locationList"
                                optionLabel="name"
                                optionValue="value"
                                placeholder="Select Location"
                                v-model="end_customer_location"
                                :highlightOnSelect="false"
                                @change="active = 1"
                            />
                        </div>
                    </template>
                </StepperPanel>
            </Stepper>
            <div style="float: right">
                <Button
                    class="text-lg my-3 mr-2"
                    rounded
                    icon="pi pi-save"
                    label="Submit"
                    :pt="{
                        icon: {
                            class: 'text-lg ml-2',
                        },
                    }"
                    iconPos="right"
                    @click="registerCrate"
                >
                </Button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useCrateStore } from '@/washing-management/CrateStore'
import { useLocationStore } from '@/stores/LocationStore'
import DropDown from 'primevue/dropdown'

const toast = useToast()
const crateStore = useCrateStore()
const locationStore = useLocationStore()
const locationList = computed(
    () =>
        locationStore.locations?.map((location) => {
            return {
                name:
                    location?.address.street_no +
                    ', ' +
                    location?.address.street_name +
                    ', ' +
                    location?.address.town,
                value: location.location_id,
            }
        }) ?? [],
)

const cupSizes = ref([
    { name: '180CC', value: '180' },
    { name: '230CC', value: '230' },
])

const active = ref(0)
const crateIdElement = ref()
const crateId = ref()
const end_customer_location = ref('')

onMounted(() => {
    if (crateIdElement.value.$el) {
        crateIdElement.value.$el.focus()
    }
})

function getCrateIdFromURL() {
    if (crateId.value) {
        try {
            // https://my.takeables.nl/crate/qr_scan/23jq4pnpt7
            const url = new URL(crateId.value)
            const code = url.pathname.replace(/\/$/, '').split('/').pop()
            crateId.value = code
            return code
        } catch (error) {
            // do nothing since the crateId is not a URL
            if (crateId.value.length === 10) {
                return crateId.value
            }
            return undefined
        }
    }
}

function jumpToLocationSelection() {
    if (!getCrateIdFromURL()) {
        return
    }

    active.value = 1

    // remove focus from crateId input
    if (crateIdElement.value.$el) {
        crateIdElement.value.$el.blur()
    }
}

async function registerCrate() {
    const isCrateRegistered = await crateStore.registerOutgoingCrate(
        crateId.value,
        end_customer_location.value,
    )
    if (isCrateRegistered) {
        toast.add({
            severity: 'success',
            detail: 'Success',
            life: 3000,
        })

        // reset the form
        crateId.value = ''
        active.value = 0
        end_customer_location.value = ''
    } else {
        toast.add({
            severity: 'error',
            detail: 'Failed',
            life: 3000,
        })
    }
}
</script>

<style scoped lang="scss">
.p-stepper {
    flex-basis: 40rem;
}
</style>
