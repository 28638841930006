<!-- Verify account with a token -->
<script setup lang="ts">
import { ref } from 'vue'
import type { Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Card from 'primevue/card'
import Password from 'primevue/password'
import { useToast } from 'primevue/usetoast'
import { jwtDecode, InvalidTokenError } from 'jwt-decode'
import { useAuthStore, VerifyErrorCode } from '@/authentication/authStore'
import { router } from '@/router'

const props = defineProps<{
    token: string
}>()
const { t } = useI18n()
const toast = useToast()

const authStore = useAuthStore()
const invalidToken = ref(false)
const username: Ref<string | null> = ref(null)
const password: Ref<string | null> = ref(null)

try {
    username.value = jwtDecode<{ email: string }>(props.token).email
} catch (e) {
    if (e instanceof InvalidTokenError) {
        invalidToken.value = true
    } else {
        throw e
    }
}

async function verify() {
    const result = await authStore.verify(props.token, password.value!)
    if (result.error == VerifyErrorCode.BAD_TOKEN) {
        invalidToken.value = true
    } else if (result.error === VerifyErrorCode.ALREADY_VERIFIED) {
        //alreadyVerified = true
        // do we need to explain this with different message?
        // for now, the quick way:
        toast.add({
            severity: 'success',
            summary: t('AccountVerify.success'),
            detail: t('AccountVerify.successDetail'),
            life: 5000,
        })
        await router.push({ name: 'login' })
    } else if (result.user) {
        toast.add({
            severity: 'success',
            summary: t('AccountVerify.success'),
            detail: t('AccountVerify.successDetail'),
            life: 5000,
        })
        await router.push({ name: 'login' })
    }
}
</script>
<template>
    <div class="container">
        <div
            class="flex align-items-center justify-content-center gap-8 bg-primary border-round-lg shadow-7 p-5 w-max"
        >
            <div class="flex flex-column align-items-center justify-content-center">
                <img
                    src="@/assets/takeables.svg"
                    alt="TakeAbles Logo"
                    class="logo m-4"
                    height="50"
                />
                <img
                    src="@/assets/default_cups.png"
                    alt="TakeAbles Cups"
                    class="logo"
                    height="150"
                />
            </div>
            <div>
                <Card v-if="!invalidToken">
                    <template #title>{{ t('AccountVerify.title') }} </template>
                    <template #content>
                        <form class="formgrid nested-grid" @submit.prevent="verify">
                            <div class="field col-12 grid">
                                <!-- show username, for password manager autofill and user confirmation -->
                                <label class="col-4" for="username">{{ t('auth.username') }}</label>
                                <div class="col-8">
                                    <!-- extra container to match password-input structure and get the same alignments :) -->
                                    <InputText
                                        class="w-full"
                                        v-model="username"
                                        autocomplete="username email"
                                        aria-describedby="username-help"
                                        disabled
                                    />
                                </div>
                                <small class="col-offset-4 col-8" id="username-help">{{
                                    t('AccountVerify.usernameHelp')
                                }}</small>
                            </div>
                            <div class="field col-12 grid">
                                <label class="col-4" for="password">{{
                                    t('AccountVerify.newPassword')
                                }}</label>

                                <Password
                                    id="password"
                                    class="col-8 password"
                                    aria-describedby="password-help"
                                    :placeholder="t('auth.password')"
                                    v-model="password"
                                    required
                                    toggleMask
                                    :pt="{
                                        input: { class: 'w-full' },
                                        showIcon: { style: 'right: 1em' },
                                    }"
                                />
                                <small class="col-offset-4 col-8" id="password-help">{{
                                    t('AccountVerify.passwordHelp')
                                }}</small>
                            </div>
                            <div class="field col-12 justify-content-end pt-5">
                                <div class="flex justify-content-end pr-3">
                                    <Button type="submit" rounded>{{
                                        t('AccountVerify.submit')
                                    }}</Button>
                                </div>
                            </div>
                        </form>
                    </template>
                </Card>
                <Card v-else>
                    <template #title>{{ t('AccountVerify.invalidToken') }}</template>
                    <template #content>
                        <p>{{ t('AccountVerify.invalidTokenHelp') }}</p>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
label {
    &::after {
        content: ':';
    }
}
</style>
