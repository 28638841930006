import { watchEffect } from 'vue'
import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'

import { usePermStore } from '@/authorization/permStore'
/**
 * Store to help with (Sentry) tracing.
 */
export const useTrackingStore = defineStore('tracking', () => {
    const permStore = usePermStore()

    watchEffect(async () => {
        let mainRole: string | undefined | null
        if (permStore.roles.length > 0) {
            const role = permStore.roles[0]
            const rid = role.customer_id || role.end_customer_id || role.location_id
            mainRole = `${role.role_id}[${rid}]`
        }
        Sentry.setContext('account', { mainRole })
    })

    const init = () => {
        /* noop for now */
    }
    return {
        init,
    }
})
