<template>
    <div class="p-sidebar" :class="{ 'p-sidebar-active': sidebarVisible }" v-show="sidebarVisible">
        <div class="p-sidebar-header">
            <router-link to="/dashboard"
                ><img src="@/assets/takeables.svg" alt="TakeAbles Logo" class="logo"
            /></router-link>
        </div>
        <div class="p-sidebar-content">
            <ul class="p-menu p-menulist">
                <li>
                    <div class="p-sidebar-item">
                        <div class="p-side-bar-item-hover">
                            <router-link to="/dashboard" class="p-menuitem-link">
                                <i class="pi pi-home"></i>
                                {{ $t('sidebar.dashboard') }}
                            </router-link>
                        </div>
                    </div>
                </li>
                <template v-for="item in optionalMenuEntries" :key="item.to.name">
                    <hr class="p-menu-seperator" />
                    <li>
                        <div class="p-sidebar-item">
                            <div class="p-side-bar-item-hover">
                                <router-link :to="item.to" class="p-menuitem-link">
                                    <i :class="['pi', item.i]"></i>
                                    {{ item.name }}
                                </router-link>
                            </div>
                        </div>
                    </li>
                </template>

                <hr class="p-menu-seperator" />
                <li>
                    <div class="p-sidebar-item">
                        <div class="p-side-bar-item-hover">
                            <router-link to="/my" class="p-menuitem-link">
                                <i class="pi pi-user"></i>
                                {{ $t('sidebar.mydata') }}
                            </router-link>
                        </div>
                    </div>
                </li>
                <hr class="p-menu-seperator" />
                <li>
                    <div class="p-sidebar-item">
                        <div class="p-side-bar-item-hover">
                            <router-link to="/login" class="p-menuitem-link">
                                <i class="pi pi-sign-out"></i>
                                {{ $t('sidebar.logout') }}
                            </router-link>
                        </div>
                    </div>
                </li>
                <hr class="p-menu-seperator" />
                <li>
                    <div class="p-sidebar-item">
                        <div class="flags-wrapper">
                            <SelectButton
                                v-model="selectedLanguage"
                                :options="options"
                                @change="toggleLanguage"
                                :allowEmpty="false"
                                optionValue="language"
                                optionLabel="label"
                                :pt="{
                                    button: ({ context }) => ({
                                        class: [
                                            !context.active ? 'opacity-40' : undefined,
                                            'mr-3 bg-transparent border-none shadow-none px-0 py-2',
                                        ],
                                    }),
                                }"
                            >
                                <template #option="slotProps">
                                    <img
                                        :src="slotProps.option.icon"
                                        :alt="slotProps.option.value"
                                        width="30"
                                        height="20"
                                    />
                                </template>
                            </SelectButton>
                        </div>
                    </div>
                </li>
                <hr class="p-menu-seperator" />
            </ul>
            <div class="p-sidebar-footer">
                <img :src="cupImg" alt="cup logo" class="h-12rem" @error="fallbackImage" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import type { RouteLocationRaw } from 'vue-router'

import { useAuthStore } from '@/authentication/authStore'
import { useAuthCtxStore } from '@/authorization/authCtxStore'
import { usePermStore, PERMISSION } from '@/authorization/permStore'
import { languageStore, Language } from '@/i18n/languageStore'
import SelectButton, { type SelectButtonChangeEvent } from 'primevue/selectbutton'
import { computed, onMounted, ref, type Ref } from 'vue'
import nlFlag from '@/assets/flags/nl.svg'
import enFlag from '@/assets/flags/gb.svg'

interface IFlagOptions {
    language: Language
    icon: string
}

const { t } = useI18n()
const authStore = useAuthStore()
const permStore = usePermStore()
const authCtxStore = useAuthCtxStore()
const langStore = languageStore()

const cupImg = computed(
    () => '/cup-logos/' + (authCtxStore.customer?.cup_image ?? 'default_cups.png'),
)

const options: Ref<IFlagOptions[]> = ref([
    { language: Language.NL, icon: nlFlag, label: 'Nederlands' },
    { language: Language.EN, icon: enFlag, label: 'English' },
])
const selectedLanguage: Ref<Language> = ref(langStore.selectedLanguage)

const sidebarVisible = computed(() => authStore.isAuthenticated)

type MenuEntry = { to: RouteLocationRaw; i: string; name: string }
const optionalMenuEntries = computed(() => {
    const entries: MenuEntry[] = []
    if (permStore.hasPermission(PERMISSION.end_customer_list)) {
        entries.push({
            to: { name: 'endcustomer_list' },
            i: 'pi-users',
            name: t('sidebar.customerManagement'),
        })
    } else if (permStore.hasPermission(PERMISSION.location_list)) {
        entries.push({
            to: { name: 'endcustomer_locations' },
            i: 'pi-building',
            name: t('sidebar.locationManagement'),
        })
    } else {
        // Location manager, make a direct link to their location
        const location_id = authCtxStore.idForPermission(PERMISSION.location_view)
        if (location_id) {
            entries.push({
                to: { name: 'endcustomer_location_update', params: { location_id } },
                i: 'pi-building',
                name: t('sidebar.locationManagement'),
            })
        }
    }
    if (permStore.hasPermission(PERMISSION.washing_cycle_view)) {
        entries.push({
            to: { name: 'washing_management' },
            i: 'pi-sync',
            name: t('sidebar.washingDetails'),
        })
    }
    if (permStore.hasPermission(PERMISSION.shipment_view)) {
        const allowed = permStore.idsForPermission(PERMISSION.shipment_view)
        const end_customer_ids = permStore.idsForPermission(PERMISSION.end_customer_manage)
        if (allowed.length === 1) {
            entries.push({
                to: { name: 'shipments_location', params: { location_id: allowed[0] } },
                i: 'pi-truck',
                name: t('sidebar.shipment'),
            })
        } else if (end_customer_ids.length === 1) {
            const end_customer_id = end_customer_ids[0]
            if (allowed.includes(end_customer_id)) {
                entries.push({
                    to: { name: 'shipments_end_customer', params: { end_customer_id } },
                    i: 'pi-truck',
                    name: t('sidebar.shipment'),
                })
            }
        }
    }
    if (permStore.hasPermission(PERMISSION.order_product)) {
        entries.push({
            to: { name: 'order_select' },
            i: 'pi-cart-plus',
            name: t('sidebar.extraProducts'),
        })
    }
    return entries
})

onMounted(() => {
    toggleLanguage({ value: selectedLanguage.value })
})

function fallbackImage(element: any) {
    element.target.src = '/cup-logos/default_cups.png'
}

function toggleLanguage({ value }: SelectButtonChangeEvent | { value: Language }) {
    selectedLanguage.value = value
    langStore.setLanguage(value)
}
</script>

<style scoped lang="scss">
.p-sidebar {
    min-width: 300px;
    max-width: 300px;
    background-color: var(--color-background);
    color: #222;
    box-shadow: none;
}

.p-sidebar-active {
    transform: translateX(0);
}

.p-sidebar-header {
    display: flex;
    border-bottom: 1px solid var(--color-background);
}

.logo {
    width: 180px;
}

.p-sidebar-content {
    padding-top: 16px;
    margin: 0;
    flex-grow: initial;
}

.p-menu {
    list-style: none;
    background-color: var(--color-background);
    width: 100%;
    border: 0pt;
}

.p-menulist {
    padding: 0;
    margin: 0px;
}

.p-menuitem-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
    padding: 8px 0;
    border-top: 1px;
    padding-left: 20px;
}

.p-menuitem-link i {
    margin-right: 10px;
}

.sub-menu {
    list-style: none;
    margin-left: 20px;
    /* Adjust the indentation as needed */
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}

.sub-menu li {
    padding: 5px 0;
}

.has-submenu .submenu-indicator {
    margin-left: auto;
    font-weight: bold;
    transform: rotate(0deg);
    transition: transform 0.2s;
}

.has-submenu.open .submenu-indicator {
    transform: rotate(90deg);
}

.p-menu li:hover .sub-menu {
    max-height: 1000px;
    /* Adjust to a suitable value to display the sub-menu */
}

.p-sidebar-item {
    padding: 10px;
}

.p-side-bar-item-hover {
    &:hover {
        background-color: rgba(112, 208, 255, 0.2);
        border-radius: 30px;
    }

    .router-link-active {
        background-color: rgba(112, 208, 255, 0.2);
        border-radius: 30px;
    }
}

.p-menu-seperator {
    margin: 0;
    border: none;
    height: 1px;
    background-color: #fefcf6;
    opacity: 20%;
}

.flags-wrapper {
    padding-left: 20px;
    display: flex;
    align-items: center;
}

.p-sidebar-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 55px;
}
</style>
