import OrderPage from './OrderPage.vue'
import OrderSelect from './OrderSelect.vue'
import OrderConfirm from './OrderConfirm.vue'
import { useOrderStore } from './orderStore'

export const OrderRoutes = [
    {
        path: 'order',
        component: OrderPage,
        children: [
            {
                path: '',
                name: 'order_select',
                component: OrderSelect,
            },
            {
                path: 'confirm',
                name: 'order_confirm',
                component: OrderConfirm,
                beforeEnter: () => {
                    const orderStore = useOrderStore()
                    if (!orderStore.isValid()) {
                        return { name: 'order_select' }
                    }
                },
            },
        ],
    },
]
