<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Button from 'primevue/button'
import InputNumber from 'primevue/inputnumber'
import { useVuelidate } from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'

import { type Product } from '../productStore'
import { useOrderStore } from '../orderStore'

const props = defineProps<{
    product: Product
}>()

const { t } = useI18n()
const orderStore = useOrderStore()

const rules = {
    amount: { required, minValue: minValue(1) },
}
const model = ref({
    amount: 1,
})
const v = useVuelidate(rules, model)

const addToCart = async () => {
    v.value.$touch()
    const isValid = await v.value.$validate()
    if (isValid) {
        orderStore.addToCart(props.product.type, model.value.amount)
    }
}
</script>
<template>
    <div class="grid" :id="`product-select-${product.type}`">
        <div class="col flex justify-content-center align-content-center">
            <img class="h-5rem m-3" alt="{{ product.name }}" :src="product.img" />
        </div>
        <div class="col flex">
            <div class="flex align-items-center">
                {{ product.name }}
            </div>
        </div>
        <div class="col flex">
            <div class="flex align-items-center">
                {{ product.desc }}
            </div>
        </div>
        <div class="col flex">
            <InputNumber
                class="flex align-items-center"
                v-model="v.amount.$model"
                :invalid="v.amount.$error"
                type="number"
                showButtons
            />
        </div>
        <div class="col flex">
            <div class="flex align-items-center">
                <Button
                    class="flex align-items-center"
                    icon="pi pi-cart-plus"
                    :label="t('orderProducts.addToOrder')"
                    @click="addToCart"
                />
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
.product-img {
    height: 100px;
    max-width: 100px;
}
</style>
