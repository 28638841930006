import { defineStore } from 'pinia'
import axiosInstance from '@/shared/axios/AxiosInstance'
import type { Components } from '@/types/openapi.d.ts'
import dayjs from 'dayjs'
import axios from 'axios'

import cupflowClient from '@/shared/open_api_client/OpenApiClient'
import { usePermStore } from '@/authorization/permStore'
import type { EndCustomer, ContactPerson, Location } from '@/customer-management/customer'

interface EndCustomerStore {
    endCustomers: Components.Schemas.EndCustomer[]
    activeEndCustomer: Components.Schemas.EndCustomer | undefined
    newEndCustomer: Components.Schemas.EndCustomer | undefined
    locations: Components.Schemas.Location[]
    selectedLocation: Components.Schemas.Location | undefined
    errors: string[]
}

export const useEndCustomerStore = defineStore('EndCustomerStore', {
    state: (): EndCustomerStore => ({
        endCustomers: [],
        activeEndCustomer: undefined,
        newEndCustomer: undefined,
        locations: [],
        errors: [],
        selectedLocation: undefined,
    }),
    getters: {
        getActiveEndCustomer(): Components.Schemas.EndCustomer | undefined {
            return this.activeEndCustomer
        },
        getNewEndCustomer(): EndCustomer {
            this.newEndCustomer = {
                id: '',
                customer_id: '0',
                company_name: '',
                contact_person: {
                    name: '',
                    phone: '',
                    email: '',
                },
                selfservice: true,
                start_date: '',
                end_date: undefined,
                external_reference: '',
                subscription_type: 'WASH_ONLY',
            }

            return this.newEndCustomer
        },
    },
    actions: {
        async setActiveEndCustomer(endCustomerID: string) {
            try {
                const response =
                    await cupflowClient.get_end_customer_end_customer_management__end_customer_id__get(
                        {
                            end_customer_id: endCustomerID,
                        },
                    )
                this.activeEndCustomer = response.data
            } catch (e) {
                if (axios.isAxiosError(e) && e.response?.status === 404) {
                    this.activeEndCustomer = undefined
                } else {
                    throw e
                }
            }
        },
        async addEndCustomer() {
            const result =
                await cupflowClient.register_end_customer_end_customer_management_register_post(
                    {},
                    this.newEndCustomer!,
                )
            this.activeEndCustomer = result.data
            this.fetchEndCustomers()
            this.locations = []
            //TODO: remove duplicate code.
            this.newEndCustomer = {
                id: '',
                customer_id: '0',
                company_name: '',
                contact_person: {
                    name: '',
                    phone: '',
                    email: '',
                },
                selfservice: true,
                start_date: '',
                end_date: undefined,
                external_reference: '',
                subscription_type: 'WASH_ONLY',
            }
            await usePermStore().refresh()
        },
        setSelectedLocation(location: Components.Schemas.Location | undefined) {
            this.selectedLocation = location
        },
        async fetchEndCustomers() {
            const data = await cupflowClient.list_endcustomers_end_customer_management_list_get()
            this.endCustomers = data.data.end_customers
        },
        async addLocationToCustomer(location: Components.Schemas.Location): Promise<boolean> {
            console.log('Adding location to customer from store')

            location.end_customer_id = this.activeEndCustomer?.id!
            const registerLocationRequest: Components.Schemas.RegisterLocationRequest = {
                nr_of_employees: location.nr_of_employees,
                end_customer_id: location.end_customer_id,
                address: location.address,
                contact_person: location.contact_person,
                start_date: location.start_date,
                end_date: location.end_date,
                pick_up_days: location.pick_up_days,
                description: location.description,
                building_number: location.building_number,
                external_reference: location.external_reference,
                cupsizes: location.cupsizes,
                subscription_type: location.subscription_type,
                delivery_schedule: location.delivery_schedule,
                pickup_driver_remarks: location.pickup_driver_remarks,
            }
            // const registerLocationRequest: Components.Schemas.RegisterLocationRequest = {location: register_location_request}

            const result =
                await cupflowClient.register_location_end_customer_management_location_register_post(
                    {},
                    registerLocationRequest,
                )
            await usePermStore().refresh()
            await this.fetchLocationsForEndCustomer(this.activeEndCustomer?.id!)
            return result.status == 200
        },
        async deactivateLocationFromCustomer(
            location_id: string,
            deactivate_from: string | null,
        ): Promise<Boolean> {
            console.log('Removing location: ' + location_id)
            if (deactivate_from != null) {
                deactivate_from = dayjs(new Date(deactivate_from)).format('YYYY-MM-DD')
            }
            const result =
                await cupflowClient.deactivate_location_end_customer_management_location__location_id__deactivate_delete(
                    { location_id: location_id, deactivate_from: deactivate_from },
                )
            if (!result.data.succes) {
                this.errors.push(result.data.error_message ?? '')
            }
            this.fetchLocationsForEndCustomer(this.activeEndCustomer?.id!)
            return result.data.succes
        },
        async fetchLocationsForEndCustomer(endCustomerId: string): Promise<Location[]> {
            try {
                const response =
                    await cupflowClient.list_location_end_customer_management__end_customer_id__location_list_get(
                        { end_customer_id: endCustomerId },
                    )
                this.locations = response.data.location
                return response.data.location
            } catch (e) {
                if (axios.isAxiosError(e) && e.response?.status === 404) {
                    return []
                } else {
                    throw e
                }
            }
        },
        async updateEndCustomerContactPerson(contactPerson: ContactPerson): Promise<boolean> {
            const result =
                await cupflowClient.update_details_end_contactperson_end_customer_management__end_customer_id__update_contact_person_put(
                    { end_customer_id: this.getActiveEndCustomer?.id! },
                    { contact_person: contactPerson },
                )
            return result.data.succes
        },
        async updateEndCustomerServiceDetails(
            start_date: string,
            end_date: string | null | undefined,
        ): Promise<boolean> {
            const result =
                await cupflowClient.update_service_details_end_customer_management__end_customer_id__update_service_details_put(
                    { end_customer_id: this.getActiveEndCustomer?.id! },
                    { start_date: start_date, end_date: end_date },
                )
            return result.data.succes
        },
        async updateEndCustomerDetails(
            company_name: string,
            external_reference: string,
        ): Promise<boolean> {
            // Parameters<Paths.UpdateDetailsEndCustomerEndCustomerManagementCustomerIdUpdateDetailsPut.QueryParameters> =
            //     Parameters<Paths.UpdateDetailsEndCustomerEndCustomerManagementCustomerIdUpdateDetailsPut.QueryParameters>()
            // parameters.end_customer_id = this.activeEndCustomer?.id!
            const result =
                await cupflowClient.update_details_end_customer_end_customer_management__end_customer_id__update_details_put(
                    { end_customer_id: this.getActiveEndCustomer?.id! },
                    { company_name: company_name, external_reference: external_reference },
                )
            return result.data.succes
        },
        async updateLocationContactPerson(
            location: Location,
            contactPerson: ContactPerson,
        ): Promise<boolean> {
            const result =
                await cupflowClient.change_contact_person_end_customer_management_location__location_id__change_contactperson_put(
                    { location_id: location.location_id },
                    contactPerson,
                )
            return result.data.succes
        },
        async updateLocationNrOfEmployees(
            location: Location,
            nrOfEmployees: number,
        ): Promise<boolean> {
            const result =
                await cupflowClient.change_nr_of_employees_end_customer_management_location__location_id__change_nr_of_employees_put(
                    { location_id: location.location_id },
                    { nr_of_employees: nrOfEmployees },
                )
            return result.data.succes
        },
        async updateLocationPickUpDays(
            location: Location,
            pickUpDays: Components.Schemas.PickUpDays[],
        ): Promise<boolean> {
            const result =
                await cupflowClient.change_pickup_days_end_customer_management_location__location_id__change_pickup_days_put(
                    { location_id: location.location_id },
                    pickUpDays,
                )
            return result.data.succes
        },
        async updateLocationDetails(
            location: Location,
            description: string | null | undefined,
            building_number: string | null | undefined,
            external_reference: string | null | undefined,
            pickup_driver_remarks: string | null | undefined,
        ): Promise<boolean> {
            console.log('Updating details of location')
            const result =
                await cupflowClient.change_location_details_end_customer_management_location__location_id__change_location_details_put(
                    { location_id: location.location_id },
                    {
                        description: description,
                        building_number: building_number,
                        external_reference: external_reference,
                        pickup_driver_remarks: pickup_driver_remarks,
                    },
                )
            return result.data.succes
        },
        async updateLocationDeliverySchedule(
            location: Location,
            deliverySchedule: Components.Schemas.DeliverySchedule,
        ): Promise<boolean> {
            const result =
                await cupflowClient.change_delivery_schedule_end_customer_management_location__location_id__change_delivery_schedule_put(
                    { location_id: location.location_id },
                    deliverySchedule,
                )
            return result.status == 200
        },
        async getEndCustomerLocation(locationId: string): Promise<Components.Schemas.Location> {
            const result =
                await cupflowClient.location_details_end_customer_management_location__location_id__get(
                    {
                        location_id: locationId,
                    },
                )
            this.setSelectedLocation(result.data)
            return result.data
        },
        async getEndCustomerById(
            endCustomerID: string,
        ): Promise<Components.Schemas.EndCustomer | undefined> {
            try {
                const response =
                    await cupflowClient.get_end_customer_end_customer_management__end_customer_id__get(
                        {
                            end_customer_id: endCustomerID,
                        },
                    )
                return response.data
            } catch (e) {
                if (axios.isAxiosError(e) && e.response?.status === 404) {
                    return undefined
                } else {
                    throw e
                }
            }
        },
    },
})
