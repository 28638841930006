import axios from 'axios'
import { backendURI } from '@/shared/constant/Config'
import applyCaseMiddleware from 'axios-case-converter';

const axiosInstance = 
   axios.create({
        baseURL: backendURI,
    })

export default axiosInstance
