import { computed, ref, watchEffect } from 'vue'
import { defineStore, acceptHMRUpdate } from 'pinia'

import { i18n } from '@/i18n'
import { useAuthStore } from '@/authentication/authStore'
import type { Components } from '@/types/openapi.d.ts'
import cupflowClient from '@/shared/open_api_client/OpenApiClient'

import imgCup180 from './assets/cup_180.png'
import imgCup230 from './assets/cup_230.png'
import imgCrate from './assets/box-icon.svg'

type ProductMeta = Components.Schemas.BearerResponse | Components.Schemas.CrateProductDetails
export type ProductType = Components.Schemas.Product

export type Product = {
    type: ProductType
    name: string
    desc: string
    img: string
    cups?: number
}

export const useProductStore = defineStore(
    'ProductStore',
    () => {
        const t = i18n.global.t
        const authStore = useAuthStore()
        const apiProducts = ref()
        const loading = ref(true)

        const fetchProducts = async () => {
            loading.value = true
            const response = await cupflowClient.product_list_orders_products__get()
            loading.value = false
            return response.data
        }
        //computedAsync
        watchEffect(async () => {
            apiProducts.value = authStore.isAuthenticated ? await fetchProducts() : null
        })

        const displayProduct = (product: ProductType, meta: Record<string, any>) => {
            switch (product) {
                case 'BOX_CUPS_180CC': {
                    return {
                        type: product,
                        name: t('orderProducts.products.BOX_CUPS_180CC.name'),
                        desc: t('orderProducts.products.BOX_CUPS_180CC.description', {
                            nr: meta.cups,
                        }),
                        img: imgCup180,
                        cups: meta.cups,
                    }
                }
                case 'BOX_CUPS_230CC': {
                    return {
                        type: product,
                        name: t('orderProducts.products.BOX_CUPS_230CC.name'),
                        desc: t('orderProducts.products.BOX_CUPS_230CC.description', {
                            nr: meta.cups,
                        }),
                        img: imgCup230,
                        cups: meta.cups,
                    }
                }
                case 'CRATE': {
                    return {
                        type: product,
                        name: t('orderProducts.products.CRATE.name'),
                        desc: t('orderProducts.products.CRATE.description'),
                        img: imgCrate,
                    }
                }
                default: {
                    const check: never = product
                    throw new Error(`Unknow product: ${check}`)
                }
            }
        }
        // Transform API model. Flatten and enrich with i18n texts.
        const products = computed<Product[]>(() => {
            if (apiProducts.value) {
                return Object.entries(apiProducts.value.products).map(([pType, meta]) => {
                    return {
                        ...displayProduct(pType as ProductType, meta as ProductMeta),
                    }
                })
            }
            return []
        })

        const getProduct = (type: ProductType) => {
            return products.value.find((product) => {
                return product.type === type
            })
        }

        return {
            products,
            loading,
            getProduct,
        }
    },
    {},
)

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useProductStore, import.meta.hot))
}
