<!-- Login.vue -->
<template>
    <div class="container">
        <div
            class="flex align-items-center justify-content-center flex-column gap-1 bg-primary border-round-lg shadow-7 p-5 w-max md:flex-row md:gap-8"
        >
            <div class="flex flex-column align-items-center justify-content-center">
                <img src="@/assets/takeables.svg" alt="TakeAbles Logo" class="logo md:m-4" />
                <img
                    src="@/assets/default_cups.png"
                    alt="TakeAbles Cups"
                    class="logo h-8rem md:h-10rem hidden md:inline"
                />
            </div>
            <div>
                <form @submit.prevent="login">
                    <Card style="width: 20em; background-color: transparent" class="shadow-none">
                        <template #title>
                            <p class="font-bold text-color-secondary mb-0">LOGIN</p>
                            <p class="text-white text-xs hidden md:flex">
                                Welcome to the TakeAbles.nl application!
                            </p>
                        </template>
                        <template #content>
                            <div class="field">
                                <InputText
                                    v-model="username"
                                    :placeholder="t('auth.username')"
                                    class="col-fixed h-3rem text-xl md:text-base md:h-full"
                                    style="width: 100%"
                                    :pt="{
                                        root: {
                                            style: 'background-color: var(--color-background)',
                                            class: 'surface-border text-color-secondary shadow-none',
                                        },
                                    }"
                                />
                            </div>
                            <div class="field">
                                <InputText
                                    v-model="password"
                                    type="password"
                                    :placeholder="t('auth.password')"
                                    class="col-fixed h-3rem text-xl md:text-base md:h-full"
                                    style="width: 100%"
                                    :pt="{
                                        root: {
                                            style: 'background-color: var(--color-background)',
                                            class: 'surface-border text-color-secondary shadow-none',
                                        },
                                    }"
                                />
                            </div>
                            <Button
                                type="submit"
                                class="h-3rem text-xl md:text-base md:h-full"
                                rounded
                                :loading="pending"
                                :pt="{
                                    root: {
                                        class: 'w-full justify-content-center font-bold',
                                    },
                                }"
                                >Login</Button
                            >
                        </template>
                    </Card>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import Card from 'primevue/card'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'

import { useAuthStore } from '@/authentication/authStore'
import { useAuthCtxStore } from '@/authorization/authCtxStore'
import { ROLE } from '@/authorization/permStore'

const { t } = useI18n()

const username = ref('')
const password = ref('')
const pending = ref(false)
const authStore = useAuthStore()
const authCtxStore = useAuthCtxStore()
const toast = useToast()
const router = useRouter()
const route = useRoute()

// Watcher to navigate 'by role'
// TODO: find a better place for this?
watch(
    () => authCtxStore.mainRoleId,
    (roleId) => {
        if (roleId) {
            if (roleId == ROLE.washer) {
                router.push({ name: 'washer_menu' })
            } else {
                router.push({ name: 'dashboard' })
            }
        } else {
            // so... no role... :/
            console.error('No role after login.')
            pending.value = false
        }
    },
    { flush: 'sync' },
)

async function login() {
    pending.value = true
    // TODO: validate inputs first. At least avoid calling when empty
    if (await authStore.login(username.value, password.value)) {
        toast.add({
            severity: 'success',
            summary: 'Login successful',
            detail: 'Welcome to the TakeAbles.nl application!',
            life: 3000,
        })
        if (route.query.redir && typeof route.query.redir == 'string') {
            router.replace({ path: decodeURIComponent(route.query.redir) })
        }
    } else {
        toast.add({
            severity: 'error',
            summary: 'Login failed',
            detail: 'Incorrect username or password',
            life: 3000,
        })
        pending.value = false
    }
    // router.go(-1)
}
</script>

<style scoped lang="scss">
.container {
    display: flex;
    min-height: 100vh;
    height: 100vh;
    align-items: center;
    align-self: center;
}

.p-button:active {
    background: var(--takeables-secondary);
    color: black;
    border-color: var(--takeables-secondary);
}

.p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0px 0px 0px 0.2rem var(--takeables-secondary-box-shadow);
}
</style>
