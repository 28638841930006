<template>
    <div class="bg-white border-round h-full flex flex-column">
        <div class="pt-3 px-3 w-full">
            <div class="text-center">
                <img src="@/assets/logo.png" alt="TakeAbles Logo" class="logo h-2rem" />
            </div>
            <Button
                class="logout-btn"
                icon="pi pi-sign-out"
                @click="router.push({ name: 'login' })"
            >
            </Button>
        </div>

        <div class="p-5 overflow-auto">
            <div class="flex flex-column gap-5" style="font-size: 20px">
                <div
                    class="menu-btn incoming"
                    @click="router.push({ name: 'washer_incoming_crate' })"
                >
                    <i class="pi pi-truck mr-3" style="font-size: 2rem"></i>
                    Incoming Shipment
                </div>
                <div
                    class="menu-btn outgoing"
                    @click="router.push({ name: 'washer_outgoing_crate' })"
                >
                    <i class="pi pi-sync mr-3" style="font-size: 2rem"></i>
                    Clean Crates
                </div>
                <div class="menu-btn pick-tasks" @click="router.push({ name: 'pickup_menu' })">
                    <i class="pi pi-list-check mr-3" style="font-size: 2rem"></i>
                    Pick Tasks
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Button from 'primevue/button'
import { useRouter } from 'vue-router'

const router = useRouter()
</script>

<style scoped lang="scss">
.menu-btn {
    font-size: 20px;
    font-weight: bolder;
    padding: 20px;
    height: 100px;
    border-radius: 10px;
    box-shadow:
        0px 1px 10px rgba(0, 0, 0, 0.12),
        0px 4px 5px rgba(0, 0, 0, 0.14),
        0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.register {
    background-color: #5e758b;
    color: white;
}

.incoming {
    background-color: #07112f;
}

.outgoing {
    background-color: #8e0444;
    color: white;
}

.pick-tasks {
    background-color: #195a40;
    color: white;
}

.logout-btn {
    position: absolute;
    right: 0px;
    margin: 20px;
    top: 0px;
}
</style>
