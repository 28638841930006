<template>
    <div class="form-content">
        <div class="field-wrapper">
            <label for="contactName">{{ $t('customer.contactPerson.name') }}</label>
            <div>
                <InputText
                    v-model="newContactPerson.name"
                    type="text"
                    id="contactName"
                    :disabled="readOnly"
                    @blur="
                        () => {
                            contactPersonValidation$?.name.$touch()
                            emit('get-contact-person', newContactPerson)
                        }
                    "
                    :invalid="contactPersonValidation$?.name.$error"
                />
                <div
                    class="input-errors"
                    v-for="error of contactPersonValidation$?.name.$errors"
                    :key="error.$uid"
                >
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
        </div>
        <div class="field-wrapper">
            <label for="phoneNumber">{{ $t('customer.contactPerson.phone') }}</label>
            <div>
                <!-- @ts-ignore -->
                <InputMask
                    v-model="newContactPerson.phone as string"
                    type="text"
                    id="phoneNumber"
                    mask="+31 999 999 999"
                    placeholder="+31 999 999 999"
                    :disabled="readOnly"
                    @blur="
                        () => {
                            contactPersonValidation$?.phone.$touch()
                            emit('get-contact-person', newContactPerson)
                        }
                    "
                    :invalid="contactPersonValidation$?.phone.$error"
                />
                <div
                    class="input-errors"
                    v-for="error of contactPersonValidation$?.phone.$errors"
                    :key="error.$uid"
                >
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
        </div>

        <div class="field-wrapper">
            <label for="email">{{ $t('customer.contactPerson.email') }}</label>
            <div>
                <InputText
                    v-model="newContactPerson.email"
                    type="email"
                    id="email"
                    placeholder="name@domain.ext"
                    :disabled="readOnly"
                    @blur="
                        () => {
                            contactPersonValidation$?.email.$touch()
                            emit('get-contact-person', newContactPerson)
                        }
                    "
                    :invalid="contactPersonValidation$?.email.$error"
                />
                <div
                    class="input-errors"
                    v-for="error of contactPersonValidation$?.email.$errors"
                    :key="error.$uid"
                >
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ContactPerson } from './customer'
import { ref } from 'vue'
const props = defineProps<{
    contactPerson: ContactPerson
    readOnly?: boolean
    validation?: any
}>()
const emit = defineEmits(['get-contact-person'])

let newContactPerson = ref(props.contactPerson)
const contactPersonValidation$ = props.validation
</script>

<style scoped lang="scss">
.form-content {
    padding: 15px;
    padding-bottom: 0px;
    .field-wrapper {
        display: flex;
        margin-bottom: 20px;
        label {
            width: 30%;
            font-weight: 600;
        }
        div {
            width: 70%;
        }
    }
}
</style>
