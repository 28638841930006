<script setup lang="ts">
import { watchEffect } from 'vue'
import { storeToRefs } from 'pinia'
import Panel from 'primevue/panel'
import Divider from 'primevue/divider'
import Calendar from 'primevue/calendar'
import Button from 'primevue/button'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'
import { useRouter } from 'vue-router'

import { useLocationStore } from '@/stores/LocationStore'
import InputErrors from '@/shared/components/InputErrors.vue'

import { useOrderStore } from '../orderStore'
import LocationDisplay from './LocationDisplay.vue'
import LocationSelect from './LocationSelect.vue'
import OrderCart from './OrderCart.vue'

const { t } = useI18n()
const router = useRouter()
const locationStore = useLocationStore()
const orderStore = useOrderStore()

const { preSelectedLocation } = storeToRefs(locationStore)
const { selectedLocation, selectedDate, cartCount } = storeToRefs(orderStore)

watchEffect(() => {
    if (preSelectedLocation.value) {
        selectedLocation.value = preSelectedLocation.value
    }
})
watchEffect(() => {
    if (!selectedDate.value) {
        selectedDate.value = orderStore.minimumDate
    }
})

const rules = {
    location: { required },
    // @ts-ignore bug, minValue does allow Date
    preferredDate: { required, minValue: minValue(orderStore.minimumDate) },
}

const v = useVuelidate(rules, { location: selectedLocation, preferredDate: selectedDate })

const confirm = async () => {
    v.value.$touch()
    const isValid = await v.value.$validate()
    if (isValid) {
        router.push({ name: 'order_confirm' })
    }
}
</script>
<template>
    <Panel :header="t('orderProducts.overview.title')">
        <div class="formgrid grid">
            <div class="field grid col-12">
                <label for="location" class="col-3">
                    {{ t('orderProducts.location') }}
                </label>
                <div class="col">
                    <LocationDisplay
                        v-if="preSelectedLocation"
                        id="location"
                        :location="preSelectedLocation"
                        data-testid="location-display"
                    />
                    <div v-else>
                        <!-- @vue-ignore obj of refs, $model is inferred as wrong type...-->
                        <LocationSelect
                            id="location"
                            v-model="v.location.$model"
                            :invalid="v.location.$error"
                            data-testid="location-select"
                        />
                        <InputErrors :errors="v.location.$errors" />
                    </div>
                </div>
            </div>
            <div class="field grid col-12">
                <label for="delivery" class="col-3">
                    {{ t('orderProducts.preferredDeliveryDate') }}
                </label>
                <div class="col">
                    <!-- @vue-ignore obj of refs, $model is inferred as wrong type...-->
                    <Calendar
                        v-model="v.preferredDate.$model"
                        :invalid="v.preferredDate.$error"
                        :minDate="orderStore.minimumDate"
                        :disabledDays="orderStore.disabledDays"
                        :disabledDates="orderStore.disabledDates"
                        selectOtherMonths
                        id="delivery"
                        :inputclass="{ 'field-input': true }"
                        dateformat="dd-mm-yy"
                        :showIcon="true"
                    />
                    <InputErrors :errors="v.preferredDate.$errors" />
                </div>
            </div>
            <Divider />
            <div class="field grid col-12">
                <OrderCart v-if="cartCount > 0" editable />
            </div>
            <div v-if="cartCount > 0" class="col-12 flex justify-content-end">
                <Button
                    rounded
                    icon="pi pi-check-square"
                    :label="t('orderProducts.confirmation')"
                    @click="confirm"
                    data-testid="submit-button"
                />
            </div>
        </div>
    </Panel>
</template>
