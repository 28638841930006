<template>
    <div>
        <h1 class="p-0 flex align-items-center gap-2">
            {{ t('shipments.title') }}
            <i
                class="pi pi-info-circle"
                style="font-size: 1rem; color: var(--takeables-secondary)"
                v-tooltip="$t('shipments.pickups_tooltip')"
            ></i>
        </h1>
        <div class="mb-4">
            <ShipmentList :shipments="shipments" :multipleLocations="multipleLocations" actions search />
        </div>
    </div>
</template>
<style scoped lang="scss">
:deep(tr.cancelled) {
    text-decoration: line-through;
}
</style>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import ShipmentList from './components/ShipmentList.vue'
import { useShipmentStore } from './ShipmentStore'

const { t } = useI18n()
const shipmentStore = useShipmentStore()
const { shipments, multipleLocations } = storeToRefs(shipmentStore)
</script>
