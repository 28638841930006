export const environmentList = {
    DEVELOPMENT: 'development',
    LOCALHOST: 'localhost',
    STAGING: 'staging',
    PRODUCTION: 'production',
}

export const backendURI = import.meta.env.VITE_APP_BACKEND_URI


