<script setup lang="ts">
import { useTrackingStore } from '@/stores/TrackingStore'
const trackingStore = useTrackingStore()
trackingStore.init()
</script>
<template>
    <Toast :style="{ width: 'auto' }" />
    <ConfirmPopup group="headless" :style="{ width: '25vw' }"></ConfirmPopup>
    <ConfirmDialog group="positioned" :style="{ width: '25vw' }"></ConfirmDialog>
    <Suspense>
        <router-view />
    </Suspense>
</template>

