<script setup lang="ts">
import AccountVerify from '@/authentication/AccountVerify.vue'
import { useI18n } from 'vue-i18n'
defineProps<{
    token: string
}>()
const { t } = useI18n()
</script>
<template>
    <div class="flex flex-column align-items-center justify-content-center">
        <div class="mt-5">
            <AccountVerify :token="token"></AccountVerify>
        </div>
    </div>
</template>
<i18n>
{
  "en": {
    "title": "Account verification"
  },
  "nl": {
    "title": "Account verificatie"
  }
}
</i18n>
