<template>
    <div>
        <h2>Pick New Cups</h2>
        <Button
            rounded
            class="h-2rem w-2rem absolute top-0 left-0 mt-3 ml-5"
            icon="pi pi-arrow-left"
            :pt="{
                icon: {
                    class: 'text-sm',
                },
            }"
            @click="() => $router.push({ name: 'pickup_menu' })"
        >
        </Button>
        <div class="card-wrapper text-black-alpha-90">
            <div class="w-full">
                <Tag
                    style="background-color: #8e0444; color: white"
                    :value="selectedTask?.company"
                    class="text-md w-full ellipsis"
                ></Tag>
                <Tag
                    class="text-md location-tag w-full ellipsis"
                    :value="selectedTask?.location"
                ></Tag>
            </div>
            <div>
                <div class="card flex justify-content-center">
                    <div class="flex flex-column m-3">
                        <h3 class="text-center font-bold p-0 underline">Boxes</h3>
                        <p class="mb-2 text-center">Please select a box first</p>
                        <Listbox
                            v-model="selectedBox"
                            :options="boxOptions"
                            optionLabel="name"
                            dataKey="product_id"
                            :optionDisabled="(box) => box.scanned"
                            aria-labelledby="custom-list-box"
                        >
                            <template #option="slotProps">
                                <div class="flex align-items-center">
                                    <svg
                                        v-if="slotProps.option.scanned"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512"
                                        height="1rem"
                                        fill="currentColor"
                                        style="color: #28c628"
                                        class="mr-2"
                                    >
                                        <path
                                            d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
                                        />
                                    </svg>
                                    <div>{{ slotProps.option.name }}</div>
                                </div>
                            </template>
                        </Listbox>
                    </div>
                </div>
            </div>
            <div>
                <InputGroup>
                    <InputText
                        ref="shipmentLabelElement"
                        class="h-3rem text-lg border-noround-right"
                        id="input"
                        :pt="{
                            root: ({ props }) => ({
                                class: [props.invalid ? 'border-2' : undefined],
                            }),
                        }"
                        v-model="scannedShipmentLabel"
                        @input="saveTask"
                        :invalid="!isShipmentLabelValid"
                        :disabled="isFormLoading"
                        type="text"
                        placeholder="Shipment Label"
                    />
                    <Button
                        icon="pi pi-times"
                        severity="danger"
                        @click="
                            () => {
                                scannedShipmentLabel = ''
                                isShipmentLabelValid = true
                                if (shipmentLabelElement?.$el) {
                                    shipmentLabelElement.$el.focus()
                                }
                            }
                        "
                    />
                </InputGroup>
            </div>

            <div class="text-right">
                <Button
                    class="text-lg mt-2"
                    type="button"
                    label="Picked"
                    icon="pi pi-check"
                    @click="saveTask()"
                ></Button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { usePickupStore } from '@/pick-tasks/PickupStore'
import { onUnmounted, ref, watchEffect, type Ref } from 'vue'
import Tag from 'primevue/tag'
import { useToast } from 'primevue/usetoast'
import { router } from '@/router'
import type { Components } from '@/types/openapi'
import Listbox from 'primevue/listbox'
import { storeToRefs } from 'pinia'
import { type IPickupTask } from '@/pick-tasks/PickupStore'

type ProductResponse = Components.Schemas.ProductResponse & { shipment_unit_id?: string }
type Product = Components.Schemas.Product
type Address = Components.Schemas.Address
type ShipmentUnitUpdate = Components.Schemas.ShipmentUnitUpdate
type ShipmentResponse = Components.Schemas.ShipmentResponse

interface IBox {
    name: string
    scanned: boolean
    product_id: string
    box_type: Product
    shipment_unit_id: string | undefined
}

const pickupStore = usePickupStore()
const { submitScannedCrates, fetchPendingDeliveryShipments } = pickupStore
const { pendingShipments } = storeToRefs(pickupStore)
const toast = useToast()

const scannedShipmentLabel = ref('')
const successCratesArr: Ref<ShipmentUnitUpdate[]> = ref([])

const selectedBox: Ref<IBox | undefined> = ref()
const boxOptions: Ref<IBox[]> = ref([])

const shipmentLabelElement = ref()
const isShipmentLabelValid = ref(true)
const isFormLoading = ref(false)

const props = defineProps<{
    shipment_id: string
}>()
const selectedTask: Ref<IPickupTask | undefined> = ref()

const unwatch = watchEffect(async () => {
    if (props.shipment_id) {
        await fetchPendingDeliveryShipments()
    }
    if (pendingShipments.value) {
        const shipmentObj: ShipmentResponse | undefined = pendingShipments.value.find(
            (shipment: any) => shipment.shipment_id === props.shipment_id,
        )

        if (!shipmentObj || !shipmentObj.units) {
            return
        }

        const { units } = shipmentObj

        if (shipmentObj) {
            const { location, shipment_id } = shipmentObj
            const { address } = location
            const stringAddress = formatShipmentAddress(address)
            const newCups: ProductResponse[] = []
            units.forEach((unit) => {
                const newCup: ProductResponse | undefined = unit.products.find(
                    (item: ProductResponse) => item.product !== 'CRATE',
                )
                if (newCup) {
                    newCups.push({ ...newCup, shipment_unit_id: unit.shipment_unit_id })
                }
            })
            selectedTask.value = {
                type: 'New Cups',
                company: shipmentObj?.end_customer?.company_name ?? 'COMPANY_NAME',
                location: stringAddress,
                products: newCups,
                shipment_id,
            }
            boxOptions.value =
                selectedTask.value?.products?.map((crate) => ({
                    name: formatCupSize(crate) as string,
                    scanned: crate.scanned as boolean,
                    product_id: crate.product_id as string,
                    box_type: crate.product as Product,
                    shipment_unit_id: crate.shipment_unit_id,
                })) || []
        }
    }
})

onUnmounted(() => {
    unwatch()
})

function formatShipmentAddress(address: Address) {
    const streetInfo = address.street_no_addition
        ? `${address.street_no}-${address.street_no_addition}`
        : address.street_no
    return `${address.street_name} ${streetInfo}, ${address.postcode}, ${address.town}`
}

function formatCupSize(crate: any) {
    return crate.product?.split('_')[2]
}

async function saveTask() {
    if (!selectedTask.value) {
        return
    }

    getShipmentLabelFromURL()

    if (!selectedBox.value) {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Please select a box first',
            life: 3000,
        })
        return
    }

    if (!scannedShipmentLabel.value) {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Please fill in the missing fields',
            life: 3000,
        })
        if (shipmentLabelElement?.value.$el) {
            shipmentLabelElement.value.$el.focus()
        }
        return
    }

    if (!isShipmentLabelValid.value) {
        return
    }

    boxOptions.value = boxOptions.value.map((box) => {
        if (box.product_id === selectedBox.value?.product_id) {
            return { ...selectedBox.value, scanned: true }
        }
        return box
    })

    successCratesArr.value.push({
        shipment_unit_id: selectedBox.value.shipment_unit_id!,
        transporter_label: scannedShipmentLabel.value,
        crate_id: null,
    })

    toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Picked',
        life: 3000,
    })

    selectedBox.value = undefined

    if (boxOptions.value.every((crate) => crate.scanned)) {
        const response = await submitScannedCrates({
            shipment_id: selectedTask.value.shipment_id!,
            units: successCratesArr.value,
        })
        if (response) {
            router.push({ name: 'pickup_menu' })
        } else {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Something went wrong',
                life: 3000,
            })
        }
    }

    scannedShipmentLabel.value = ''
    if (shipmentLabelElement?.value.$el) {
        shipmentLabelElement.value.$el.blur()
    }
}

function getShipmentLabelFromURL() {
    if (scannedShipmentLabel.value) {
        try {
            // external URL from Koopman is found
            const url = new URL(scannedShipmentLabel.value)

            const code = url.pathname.replace(/\/$/, '').split('/').pop()
            if (code) {
                scannedShipmentLabel.value = code
                isShipmentLabelValid.value = true
            }

            return
        } catch (error) {
            //  // if shipment_label is not a URL, validate the shipment label which should have more than 10 characters and ends with 3 digits
            const shipmentId = scannedShipmentLabel.value
            if (shipmentId.length > 10 && /^\d{3}$/.test(shipmentId.slice(-3))) {
                isShipmentLabelValid.value = true
            } else {
                isShipmentLabelValid.value = false
            }
            return
        }
    }
    isShipmentLabelValid.value = false
}
</script>

<style scoped lang="scss">
h2 {
    color: white;
    text-align: center;
    padding: 0px;
    font-weight: bold;
}

.card-wrapper {
    display: flex;
    flex-direction: column;
    margin: 10px;
    background-color: white;
    border-radius: 12px;
    padding: 10px;
    overflow-y: scroll;
    height: 75vh;
}

.location-tag {
    background-color: #07112f;
    color: white;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
}

.visibility-hidden {
    visibility: hidden;
}
</style>
