import { defineStore } from 'pinia'
import { i18n } from '@/i18n/index'
import { computed, ref, type ComputedRef, type Ref } from 'vue'

export enum Language {
    NL = 'nl',
    EN = 'en',
}

export const languageStore = defineStore('languageStore', () => {
    // State
    const language: Ref<Language> = ref(Language.NL)

    // Getters
    const selectedLanguage: ComputedRef<Language> = computed(
        () => (localStorage.getItem('locale') as Language) || language,
    )

    // Actions
    const setLanguage = (language: Language) => {
        i18n.global.locale.value = language
        language = language
        localStorage.setItem('locale', language)
    }

    return {
        language,
        setLanguage,
        selectedLanguage,
    }
})
