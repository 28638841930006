import { ref, watchEffect } from 'vue'
import { defineStore, acceptHMRUpdate } from 'pinia'

import { usePermStore, ROLE } from '@/authorization/permStore'
import cupflowClient from '@/shared/open_api_client/OpenApiClient'
import type { Components } from '@/types/openapi.d.ts'
import type { Location as _Location } from '@/customer-management/customer'

export type Account = Components.Schemas.Account
export type EndCustomerLocation = _Location // Avoid clash with global Location

export const useLocationStore = defineStore(
    'LocationStore',
    () => {
        const permStore = usePermStore()

        const includeExpired = ref(false)
        const locations = ref<EndCustomerLocation[]>()
        const preSelectedLocation = ref<EndCustomerLocation>()

        // basically 'computedAsync', but for now:
        watchEffect(async () => {
            for (const role of permStore.roles) {
                if (role.role_id == ROLE.washer || role.role_id == ROLE.washing) {
                    locations.value = await fetchAllLocations()
                } else if (role.role_id === ROLE.end_customer_manager) {
                    const end_customer_id = role.end_customer_id!
                    locations.value = await fetchLocations(end_customer_id, includeExpired.value)
                } else if (role.role_id == ROLE.location_manager) {
                    preSelectedLocation.value = await fetchLocation(role.location_id!)
                    locations.value = [preSelectedLocation.value!]
                } else {
                    // Anything else an error with the authorization...
                    console.warn(
                        'Authorization error: end customer or location manager role required.',
                    )
                }
            }
        })

        const get = async (locationID: string): Promise<EndCustomerLocation> => {
          if (preSelectedLocation.value && locationID == preSelectedLocation.value.location_id) {
            return preSelectedLocation.value
          }
          const found = locations.value && locations.value.find((l) => l.location_id==locationID)
          return found ?? await fetchLocation(locationID)
        }

        return {
            locations,
            preSelectedLocation,
            get,
        }
    },
    {},
)

async function fetchAllLocations() {
    const response =
        await cupflowClient.list_all_locations_end_customer_management_location_list_get()
    return response.data.location // 's'??
}

async function fetchLocations(end_customer_id: string, includeExpired: boolean) {
    const response =
        await cupflowClient.list_location_end_customer_management__end_customer_id__location_list_get(
            { end_customer_id, include_expired: includeExpired },
        )
    return response.data.location // 's'??
}

async function fetchLocation(location_id: string) {
    const response =
        await cupflowClient.location_details_end_customer_management_location__location_id__get({
            location_id,
        })
    return response.data
}

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useLocationStore, import.meta.hot))
}
