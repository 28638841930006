import 'primevue/resources/primevue.min.css' //core CSS
import 'primeicons/primeicons.css' //icons
import 'primeflex/primeflex.css'
import '@/assets/style.scss'

import '@/assets/themes/takeables/theme.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPersistedState from 'pinia-plugin-persistedstate'

import App from '@/App.vue'
import router from '@/router'
import PrimeVue from 'primevue/config'
import * as Sentry from '@sentry/vue'

import { i18n } from '@/i18n/index'

import Toast from 'primevue/toast'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Tooltip from 'primevue/tooltip'
import InputMask from 'primevue/inputmask'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmPopup from 'primevue/confirmpopup'
import Breadcrumb from 'primevue/breadcrumb'
import Skeleton from 'primevue/skeleton'
import DataTable from 'primevue/datatable'
import Row from 'primevue/row'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import Chip from 'primevue/chip'
import Card from 'primevue/card'
import Stepper from 'primevue/stepper'
import StepperPanel from 'primevue/stepperpanel'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import SelectButton from 'primevue/selectbutton'
import FloatLabel from 'primevue/floatlabel'
import Message from 'primevue/message'
import InputGroup from 'primevue/inputgroup'
import InputGroupAddon from 'primevue/inputgroupaddon'
import InputSwitch from 'primevue/inputswitch'

import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'

import Sidebar from '@/shared/components/SideBar.vue'

import setupInterceptors from '@/shared/axios/SetupInterceptors'

const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPersistedState)

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
    ],
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [import.meta.env.VITE_SENTRY_TRACE_PROPAGATION_TARGETS],
})

app.use(pinia)
app.use(router)
app.use(PrimeVue, {
    // opening the MultiSelect breaks the layout in Chrome, therefore
    // this is a workaround to fix it. The issue is tracked here:
    // https://github.com/primefaces/primevue/issues/4773
    pt: {
        multiselect: {
            root: { class: 'relative' },
        },
    },
})
app.use(i18n)
app.use(ToastService)
app.use(ConfirmationService)

app.component('Button', Button)
app.component('Calendar', Calendar)
app.component('Dropdown', Dropdown)
app.component('Sidebar', Sidebar)
app.component('InputText', InputText)
app.component('InputNumber', InputNumber)
app.component('Toast', Toast)
app.component('InputMask', InputMask)
app.component('ConfirmDialog', ConfirmDialog)
app.component('ConfirmPopup', ConfirmPopup)
app.component('Breadcrumb', Breadcrumb)
app.component('Skeleton', Skeleton)
app.component('DataTable', DataTable)
app.component('Row', Row)
app.component('Column', Column)
app.component('ColumnGroup', ColumnGroup)
app.component('Chip', Chip)
app.component('Card', Card)
app.component('Stepper', Stepper)
app.component('StepperPanel', StepperPanel)
app.component('IconField', IconField)
app.component('InputIcon', InputIcon)
app.component('SelectButton', SelectButton)
app.component('FloatLabel', FloatLabel)
app.component('Message', Message)
app.component('InputGroup', InputGroup)
app.component('InputGroupAddon', InputGroupAddon)
app.component('InputSwitch', InputSwitch)

app.directive('tooltip', Tooltip)

setupInterceptors

app.mount('#app')
