import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref, watchEffect, watchSyncEffect } from 'vue'

import cupflowClient from '@/shared/open_api_client/OpenApiClient'
import { useAuthStore } from '@/authentication/authStore'
import { usePermStore, PERMISSION } from '@/authorization/permStore'
import type { Customer } from '@/customer-management/customer'

/**
 * Authorized objects store.
 *
 * Store managing object tree/hierachy an authenticated user is allowed to view or manage.
 */
export const useAuthCtxStore = defineStore('authContext', () => {
    const authStore = useAuthStore()
    const permStore = usePermStore()

    // 'The' customer of an authenticated users.
    // @Deprecated. An user could have more nowadays, so it should be picked explictly.
    const customer = ref<Customer | null>(null)
    const end_customer_id = ref<string>()

    // computedAsync:
    watchEffect(async () => {
        if (authStore.isAuthenticated && permStore.hasPermission(PERMISSION.customer_view)) {
            const response = await cupflowClient.get_customer_login_customer__get()
            customer.value = response.data
        } else {
            customer.value = null
        }
    })

    // User used to have 1 fixed role, but they can have many nowadays.
    // Related to above single customer logic, same problem applies here, we keep it all in this store for now.
    const mainRoleId = ref<string>()
    watchSyncEffect(() => {
        if (permStore.roles) {
            // because of current backend logic, we can pick the first one for now.
            mainRoleId.value = permStore.roles.length > 0 ? permStore.roles[0].role_id : undefined
        }
    })

    const idForPermission = (perm: PERMISSION): string|null => {
      const ids = permStore.idsForPermission(perm)
      return ids.length > 0 ? ids[0] : null
    }
    // TODO: move into end customer store:
    watchEffect(async() => {
      if(authStore.isAuthenticated) {
        const ids = permStore.idsForPermission(PERMISSION.end_customer_view)
        if (ids.length === 1) {
          end_customer_id.value = ids[0]
        }
      }
    })

    return {
        customer,
        end_customer_id,
        mainRoleId,
        idForPermission,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthCtxStore, import.meta.hot))
}
