<script setup lang="ts">
import { computed, watchEffect, ref } from 'vue'
import { useLocationStore, type EndCustomerLocation } from '@/stores/LocationStore'
import LocationManagerList from './components/LocationManagerList.vue'

const props = defineProps<{
    location_id: string
}>()

const selectedLocation = ref<EndCustomerLocation>()
const locationStr = computed(() => {
    const addr = selectedLocation.value?.address
    return addr ? [addr.street_name, addr.town].join(' ') : ''
})

const locationStore = useLocationStore()

watchEffect(async () => {
    try {
        selectedLocation.value = await locationStore.get(props.location_id)
    } catch (e) {
        console.error(e)
        throw e
        /* handle error */
    }
})
</script>
<template>
    <div v-if="selectedLocation" class="h-full">
        <div class="action-buttons">
            <h1>{{ $t('location.managersFor', { location: locationStr }) }}</h1>
        </div>
        <div>
            <LocationManagerList :location="selectedLocation" />
        </div>
    </div>
</template>
