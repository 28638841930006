<template>
    <div>
        <div class="action-buttons">
            <h1>{{ $t('NewEndCustomer.title') }}</h1>
        </div>
        <EndCustomerComponent :endCustomer="newCustomerData" ref="endCustomerComponentRef" />
        <!-- <div v-show="!newCustomerData.selfservice">
            <LocationList :locations="newCustomerData.locations" />
        </div> -->
        <div class="flex justify-content-end mt-3">
            <Button
                @click="create_account()"
                rounded
                icon="pi pi-plus"
                :label="$t('NewEndCustomer.create_account')"
            >
            </Button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useEndCustomerStore } from './EndCustomerStore'
import EndCustomerComponent from './EndCustomerComponent.vue'
import type { EndCustomer } from './customer'

import { ref, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { useToast } from 'primevue/usetoast'

import { useI18n } from 'vue-i18n'

const router = useRouter()
const toast = useToast()
const { t } = useI18n()
const endCustomerComponentRef = ref()

const endCustomerStore = useEndCustomerStore()
const selfservice = ref(true)
let newCustomerData: EndCustomer = reactive(endCustomerStore.getNewEndCustomer)

async function create_account() {
    const result = await endCustomerComponentRef.value.endCustomerValidation.$validate()
    if (!result) {
        toast.add({
            severity: 'error',
            summary: 'Verplichte velden ontbreken',
            detail: '',
            life: 3000,
        })
        return
    }

    await endCustomerStore.addEndCustomer()
    if (endCustomerStore.activeEndCustomer) {
        endCustomerComponentRef.value.endCustomerValidation.$reset()
        toast.add({
            severity: 'success',
            summary: 'Account aangemaakt',
            detail: 'Er is een account aangemaakt voor de klant',
            life: 3000,
        })
        router.push({
            name: 'endcustomer_details',
            params: { end_customer_id: endCustomerStore.activeEndCustomer?.id },
        })
    }
}
</script>
