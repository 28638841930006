<template>
    <div class="action-buttons">
        <h1 class="p-0">Wasbeurten</h1>
        <Button
            v-if="permStore.hasPermission(PERMISSION.washing_cycle_add)"
            icon="pi pi-plus"
            label="Wasbeurt registreren"
            @click="router.push({ name: 'register_edit_wash_cycle' })"
            rounded
        ></Button>
    </div>
    <DataTable
        :value="washCycles"
        stripedRows
        style="min-width: 50rem"
        dataKey="wash_cycle_id"
        size="small"
        class="mt-3"
        v-model:expandedRows="expandedRows"
        sortField="wash_date"
        :sortOrder="-1"
        v-model:filters="filters"
        :globalFilterFields="globalFilterFields"
    >
        <template #header>
            <div class="flex justify-content-between">
                <h3 class="p-0 font-bold">Wasbeurten van de afgelopen 7 dagen</h3>
                <IconField iconPosition="left">
                    <InputIcon>
                        <i class="pi pi-search" />
                    </InputIcon>
                    <InputText
                        :placeholder="t('general.search')"
                        v-model="filters['global'].value"
                    />
                </IconField>
            </div>
        </template>
        <template #empty> {{ t('general.noResultsFound') }} </template>
        <template #loading> {{ t('general.loading') }} </template>
        <Column expander style="width: 5rem" />
        <Column field="end_customer_company_name" sortable :header="t('washing.company')"> </Column>
        <Column field="formatted_address" sortable :header="t('washing.location')"> </Column>
        <Column field="wash_date" sortable :header="t('washing.wash_date')">
            <template v-slot:body="slotProps">
                {{ slotProps.data.wash_date }}
            </template>
        </Column>
        <Column field="total_per_size" :header="t('washing.incoming')">
            <template v-slot:body="slotProps">
                <div
                    v-for="(item, index) in slotProps.data.total_per_size"
                    :key="index"
                    class="col-12"
                >
                    {{ item.cup_size }}CC: {{ item.cup_count }}
                </div>
            </template>
        </Column>
        <Column field="total_eol_cups_per_size" :header="t('washing.broken_cups')">
            <template v-slot:body="slotProps">
                <div
                    v-for="(item, index) in slotProps.data.total_eol_cups_per_size"
                    :key="index"
                    class="col-12"
                >
                    {{ item.cup_size }}CC: {{ item.cup_count }}
                </div>
            </template>
        </Column>
        <Column field="total_outgoing_per_size" :header="t('washing.outgoing')">
            <template v-slot:body="slotProps">
                <div
                    v-for="(item, index) in slotProps.data.total_outgoing_per_size"
                    :key="index"
                    class="col-12"
                >
                    {{ item.cup_size }}CC: {{ item.cup_count }}
                </div>
            </template>
        </Column>
        <Column>
            <template #body="{ data }">
                <Button
                    v-if="permStore.hasPermission(PERMISSION.washing_cycle_add)"
                    outlined
                    icon="pi pi-pencil"
                    text
                    rounded
                    @click="navigateToWashWashCycleModify(data)"
                />
                <Button
                    v-if="permStore.hasPermission(PERMISSION.washing_cycle_add)"
                    outlined
                    icon="pi pi-trash"
                    text
                    rounded
                    @click="removeWashWashCycle($event, data)"
                />
            </template>
        </Column>
        <template #expansion="slotProps">
            <DataTable :value="slotProps.data.outgoing_crates">
                <Column field="crate_id" header="Krat" />
                <Column field="cup_count" header="Aantal bekers" />
                <Column field="cup_size" header="Beker formaat" />
            </DataTable>
        </template>
    </DataTable>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useWashCycleStore } from '@/washing-management/WashCycleStore'
import Button from 'primevue/button'
import { storeToRefs } from 'pinia'
import { usePermStore, PERMISSION } from '@/authorization/permStore'
import { useConfirm } from 'primevue/useconfirm'
import { FilterMatchMode } from 'primevue/api'
const router = useRouter()

const { t } = useI18n()
const confirm = useConfirm()

const permStore = usePermStore()
const washCycleStore = useWashCycleStore()

const expandedRows = ref([])

const { washCycles } = storeToRefs(washCycleStore)

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
})
const globalFilterFields = ['end_customer_company_name', 'formatted_address']

onMounted(() => {
    washCycleStore.fetchWashCyclesAdmin()
})

function navigateToWashWashCycleModify(data: any) {
    router.push({ name: 'register_edit_wash_cycle', params: { wash_cycle_id: data.wash_cycle_id } })
}

const removeWashWashCycle = (event: any, data: any) => {
    confirm.require({
        target: event.currentTarget,
        message: 'Weet u zeker dat u deze wasbeurt wilt verwijderen?',
        icon: 'pi pi-exclamation-triangle',
        group: 'headless',
        accept: async () => {
            await washCycleStore.DeleteWashCycle(data.wash_cycle_id)
            await washCycleStore.fetchWashCyclesAdmin()
        },
        reject: () => {},
    })
}
</script>
<style scoped lang="scss">
.p-inputwrapper {
    width: 100%;
}
</style>
