<template>
    <div
        class="bg-primary border-1 border-white h-full border-round flex flex-column align-items-center"
    >
        <div class="pt-2 px-3 w-full">
            <div class="text-center">
                <img src="@/assets/takeables.svg" alt="TakeAbles Logo" class="logo h-1rem" />
            </div>
            <Button
                rounded
                class="h-3rem w-3rem absolute top-0 left-0 mt-3 ml-5"
                icon="pi pi-arrow-left"
                :pt="{
                    icon: {
                        class: 'text-2xl',
                    },
                }"
                @click="() => $router.push({ name: 'washer_menu' })"
            >
            </Button>
        </div>
        <h4 class="text-center font-bold mt-0 mb-3">Incoming Crate</h4>
        <div class="p-card py-2 px-2 flex flex-column gap-2" style="width: 95%">
            <div class="w-full">
                <div class="flex align-items-center gap-2">
                    <InputSwitch v-model="hasNoCrate" />
                    <span @click="hasNoCrate = !hasNoCrate" class="text-lg">No Crate</span>
                </div>
            </div>
            <div class="w-full" v-if="!hasNoCrate">
                <InputGroup>
                    <InputText
                        ref="crateIdElement"
                        class="h-3rem text-lg border-noround-right"
                        id="input"
                        :pt="{
                            root: ({ props }) => ({
                                class: [props.invalid ? 'border-2' : undefined],
                            }),
                        }"
                        v-model="crateId"
                        @input="submitIncomingCrate()"
                        :invalid="!isCrateValid"
                        :disabled="isFormLoading"
                        type="text"
                        placeholder="Crate ID"
                    />
                    <Button
                        icon="pi pi-times"
                        severity="danger"
                        @click="
                            () => {
                                crateId = ''
                                isCrateValid = false
                                if (crateIdElement?.$el) {
                                    crateIdElement.$el.focus()
                                }
                            }
                        "
                    />
                </InputGroup>
            </div>

            <div class="w-full">
                <InputGroup>
                    <InputText
                        ref="shipmentLabelElement"
                        class="h-3rem text-lg border-noround-right"
                        id="input"
                        :pt="{
                            root: ({ props }) => ({
                                class: [props.invalid ? 'border-2' : undefined],
                            }),
                        }"
                        v-model="shipmentLabel"
                        @input="submitIncomingCrate()"
                        :invalid="!isShipmentLabelValid"
                        :disabled="isFormLoading"
                        type="text"
                        placeholder="Shipment Label"
                    />
                    <Button
                        icon="pi pi-times"
                        severity="danger"
                        @click="
                            () => {
                                shipmentLabel = ''
                                isShipmentLabelValid = false
                                if (shipmentLabelElement?.$el) {
                                    shipmentLabelElement.$el.focus()
                                }
                            }
                        "
                    />
                </InputGroup>
            </div>

            <div class="text-right">
                <Button
                    class="text-lg"
                    rounded
                    icon="pi pi-save"
                    label="Submit"
                    :pt="{
                        icon: {
                            class: 'text-lg ml-2',
                        },
                    }"
                    iconPos="right"
                    @click="submitIncomingCrate"
                    :loading="isFormLoading"
                >
                </Button>
            </div>
        </div>

        <div class="w-full flex flex-column align-items-center" v-if="submitedCrateInfo.crate_id">
            <p class="font-bold m-2">Last Saved Crate</p>
            <div class="flex flex-column submitted-info-wrapper">
                <div>
                    <span class="text-xs font-italic">Crate ID:</span>
                    <p class="font-bold m-0">{{ submitedCrateInfo.crate_id }}</p>
                </div>
                <div>
                    <span class="text-xs font-italic">Transporter ID:</span>
                    <p class="font-bold m-0">{{ submitedCrateInfo.transporter_id }}</p>
                </div>
                <div>
                    <span class="text-xs font-italic">Address: </span>
                    <p class="font-bold m-0">{{ submitedCrateInfo.location }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, onScopeDispose, ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useCrateStore } from '@/washing-management/CrateStore'

const toast = useToast()
const crateStore = useCrateStore()

const crateIdElement = ref()
const shipmentLabelElement = ref()
const crateId = ref()
const shipmentLabel = ref()
const submitedCrateInfo = ref({
    crate_id: '',
    transporter_id: '',
    location: '',
})
const isCrateValid = ref(true)
const isShipmentLabelValid = ref(true)
const isFormLoading = ref(false)
const hasNoCrate = ref(false)
let crateIdTimeout: ReturnType<typeof setTimeout>
let shipmentLabelTimeout: ReturnType<typeof setTimeout>

onMounted(() => {
    if (crateIdElement.value.$el) {
        crateIdElement.value.$el.focus()
    }
})

function getCrateIdFromURL() {
    if (crateId.value) {
        try {
            // https://my.takeables.nl/crate/qr_scan/23jq4pnpt7
            const url = new URL(crateId.value)
            if (!url.hostname.includes('takeables')) {
                toast.add({
                    severity: 'error',
                    summary: 'Invalid Crate ID',
                    detail: 'Please scan a valid code',
                    life: 4000,
                })
                isCrateValid.value = false
                return
            }

            const code = url.pathname.replace(/\/$/, '').split('/').pop()
            crateId.value = code
            isCrateValid.value = true
            focusOnShipmentLabel()
            return
        } catch (error) {
            // do nothing since the crateId is not a URL
            if (crateId.value.length === 10) {
                isCrateValid.value = true
                focusOnShipmentLabel()
            } else {
                isCrateValid.value = false
            }
            return
        }
    }
    isCrateValid.value = false
}

function getShipmentLabelFromURL() {
    if (shipmentLabel.value) {
        try {
            // external URL from Koopman is found
            const url = new URL(shipmentLabel.value)
            const code = url.pathname.replace(/\/$/, '').split('/').pop()
            shipmentLabel.value = code
            isShipmentLabelValid.value = true

            return
        } catch (error) {
            // do nothing since the shipmentLabel is not a URL
            const shipmentId = shipmentLabel.value
            if (shipmentId.length > 10 && /^\d{3}$/.test(shipmentId.slice(-3))) {
                isShipmentLabelValid.value = true
            } else {
                isShipmentLabelValid.value = false
            }
            return
        }
    }
    isShipmentLabelValid.value = false
}

function focusOnShipmentLabel() {
    if (shipmentLabelElement.value.$el) {
        shipmentLabelElement.value.$el.focus()
    }
}

async function submitIncomingCrate() {
    if (hasNoCrate.value) {
        isCrateValid.value = true
        crateId.value = 'NO_CRATE'
    } else {
        getCrateIdFromURL()
    }
    getShipmentLabelFromURL()

    if (!(isCrateValid.value && isShipmentLabelValid.value)) {
        return
    }

    isFormLoading.value = true
    const crateResponse = await crateStore.registerIncomingCrate({
        crate_id: crateId.value,
        transporter_label: shipmentLabel.value,
    })

    if (crateResponse) {
        const { crate_id, location, transporter_id } = crateResponse
        const { address } = location
        submitedCrateInfo.value = {
            crate_id,
            transporter_id,
            location: address.street_no + ', ' + address.street_name + ' ' + address.town,
        }

        isCrateValid.value = true
        isShipmentLabelValid.value = true

        toast.add({
            severity: 'success',
            detail: 'Success',
            life: 3000,
        })

        // reset the form
        crateId.value = ''
        shipmentLabel.value = ''

        isFormLoading.value = false
        hasNoCrate.value = false

        crateIdTimeout = setTimeout(() => {
            if (crateIdElement.value.$el) {
                crateIdElement.value.$el.focus()
            }
        }, 100)
    } else {
        isCrateValid.value = false
        isShipmentLabelValid.value = false

        toast.add({
            severity: 'error',
            detail: 'Fout: geen locatie bij krat en zending. Neem contact op met de team manager.',
            life: 3000,
        })
        isFormLoading.value = false
        shipmentLabelTimeout = setTimeout(() => {
            if (shipmentLabelElement.value?.$el) {
                shipmentLabelElement.value.$el.focus()
            }
        }, 100)
    }
}

onScopeDispose(() => {
    clearTimeout(crateIdTimeout)
    clearTimeout(shipmentLabelTimeout)
})
</script>

<style scoped lang="scss">
.p-stepper {
    flex-basis: 40rem;
}
.submitted-info-wrapper {
    margin: 0px 12px 12px 12px;
    width: 85%;
    padding: 10px;
    border-radius: 0.5rem;
    background-color: white;
    color: black;
}
</style>
