<script setup lang="ts">
import type { ErrorObject } from '@vuelidate/core'
const props = defineProps<{
    errors: ErrorObject[]
}>()
</script>
<template>
    <div class="input-errors" v-for="error of props.errors" :key="error.$uid">
        <div class="error-msg">{{ error.$message }}</div>
    </div>
</template>
