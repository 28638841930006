<template>
    <div class="action-buttons">
        <h1>Wasbeurt registreren</h1>
    </div>
    <Card>
        <template #content>
            <div v-if="mode === 'create'">
                <div class="flex justify-content-between px-3 gap-1">
                    <div class="field w-3">
                        <label for="location">Locatie</label>
                        <div>
                            <LocationSelect
                                inputId="location"
                                v-model="washCycle.location"
                                @change="
                                    () => {
                                        washCycle.cup_size = null
                                        if (cupSizes && cupSizes.length === 1) {
                                            washCycle.cup_size = cupSizes[0]?.code
                                        }
                                    }
                                "
                                @blur="v.location.$touch()"
                                :invalid="v.location.$error"
                            />
                            <InputErrors :errors="v.location.$errors" />
                        </div>
                    </div>
                    <div class="field w-3">
                        <label for="cup_size">Beker formaat</label>
                        <div>
                            <DropDown
                                inputId="cup_size"
                                :options="cupSizes"
                                optionValue="code"
                                optionLabel="name"
                                placeholder="Select the cup size"
                                v-model="washCycle.cup_size"
                                :disabled="cupSizes && cupSizes.length === 1"
                                :invalid="v.cup_size.$error"
                                @blur="v.cup_size.$touch()"
                            />
                            <InputErrors :errors="v.cup_size.$errors" />
                        </div>
                    </div>
                    <div class="field w-3">
                        <label for="end-of-life">End-of-life</label>
                        <div>
                            <InputNumber
                                inputId="end-of-life"
                                v-model="washCycle.broken"
                                :invalid="v.broken.$error"
                                @blur="v.broken.$touch()"
                            />
                            <InputErrors :errors="v.broken.$errors" />
                        </div>
                    </div>
                    <div class="field w-3">
                        <label for="outgoing">Schoon</label>
                        <div>
                            <InputNumber
                                inputId="outgoing"
                                v-model="washCycle.outgoing"
                                :invalid="v.outgoing.$error"
                                @blur="v.outgoing.$touch()"
                            />
                            <InputErrors :errors="v.outgoing.$errors" />
                        </div>
                    </div>
                </div>
                <div class="flex justify-content-end">
                    <Button @click="addWashCycle()">Toevoegen</Button>
                </div>
            </div>
            <div v-else>
                <div class="flex flex-column justify-content-between px-3 gap-1">
                    <div class="flex justify-content-between align-items-end">
                        <div class="field w-3">
                            <label for="location">Locatie</label>
                            <div>
                                <LocationSelect
                                    id="location"
                                    v-model="washCycle.location"
                                    disabled
                                    @change="
                                        () => {
                                            washCycleList.forEach((item: any) => {
                                                item.cup_size = null
                                                if (cupSizes && cupSizes.length === 1) {
                                                    item.cup_size = cupSizes[0]?.code
                                                }
                                            })
                                        }
                                    "
                                />
                            </div>
                        </div>
                        <div>
                            <Button
                                class="add-crate-btn"
                                icon="pi pi-plus"
                                label="Add crate"
                                @click="addCrate()"
                            />
                        </div>
                    </div>
                    <DataTable
                        v-model:editingRows="editingRows"
                        :value="washCycleList"
                        editMode="row"
                        dataKey="id"
                        @row-edit-save="onRowEditSave"
                        size="small"
                        :rowClass="rowClass"
                    >
                        <Column field="crate_id" header="Crate Id" style="width: 20%">
                            <template #editor="{ data, field }">
                                <InputText
                                    v-if="typeof data.id === 'number'"
                                    v-model="data[field]"
                                />
                            </template>
                        </Column>
                        <Column field="cup_size" header="Cup Size" style="width: 20%">
                            <template #editor="{ data, field }">
                                <span v-if="typeof data.id === 'string'"> {{ data[field] }}</span>
                                <DropDown
                                    v-else
                                    id="cup_size"
                                    :options="cupSizes"
                                    optionValue="code"
                                    optionLabel="name"
                                    placeholder="Select the cup size"
                                    v-model="data[field]"
                                    :disabled="cupSizes && cupSizes.length === 1"
                                />
                            </template>
                        </Column>
                        <Column field="eol_cups" header="EOL Cups" style="width: 20%">
                            <template #body="{ data, field }">
                                <span v-if="typeof data.id === 'string'">{{ data[field] }}</span>
                            </template>
                            <template #editor="{ data, field }">
                                <InputText
                                    v-if="typeof data.id === 'string'"
                                    v-model="data[field]"
                                />
                            </template>
                        </Column>
                        <Column field="clean_cups" header="Clean Cups" style="width: 20%">
                            <template #editor="{ data, field }">
                                <InputText
                                    v-if="typeof data.id === 'number'"
                                    v-model="data[field]"
                                />
                            </template>
                        </Column>
                        <Column
                            :rowEditor="true"
                            style="width: 10%; min-width: 8rem"
                            bodyStyle="text-align:center"
                        ></Column>
                        <Column>
                            <template #body="{ data, index }">
                                <Button
                                    v-if="typeof data.id === 'number'"
                                    outlined
                                    icon="pi pi-trash"
                                    text
                                    rounded
                                    @click="removeCrate($event, index)"
                                />
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div class="flex justify-content-end pt-3">
                    <Button @click="updateWashCycle">Submit</Button>
                </div>
            </div>
        </template>
    </Card>
</template>
<script setup lang="ts">
import { ref, onMounted, computed, reactive, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'primevue/usetoast'
import { useWashCycleStore } from '@/washing-management/WashCycleStore'
import Button from 'primevue/button'
import DropDown from 'primevue/dropdown'
import LocationSelect from '@/order/components/LocationSelect.vue'
import InputErrors from '@/shared/components/InputErrors.vue'
import router from '@/router'
import type { Components } from '@/types/openapi'
import { onBeforeRouteLeave } from 'vue-router'
import { useConfirm } from 'primevue/useconfirm'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import lodash from 'lodash'

const { t } = useI18n()
const toast = useToast()
const confirm = useConfirm()

interface ICrate {
    id: any
    crate_id: null
    cup_size: Components.Schemas.CupSize | null
    clean_cups: null
    eol_cups: null
}

const washCycleStore = useWashCycleStore()
const mode: Ref<'create' | 'update'> = ref('create')
const washCycle = reactive({
    location: null as Components.Schemas.Location | null,
    cup_size: null as string | null,
    incoming: null,
    broken: null,
    outgoing: null,
})

const createFormRules = {
    location: { required },
    cup_size: { required },
    incoming: {},
    broken: { required },
    outgoing: { required },
}

const cupSizes = computed(() => {
    if (washCycle.location?.cupsizes) {
        return washCycle.location?.cupsizes.map((cup_size) => ({
            name: `${cup_size}CC`,
            code: cup_size,
        }))
    }
})

const props = defineProps<{
    wash_cycle_id?: string
}>()
const washCycleList: Ref<ICrate[]> = ref([])
const editingRows: Ref<ICrate[]> = ref([])
const initialList = ref([])
const v = useVuelidate(createFormRules, washCycle)

const onRowEditSave = (event: any) => {
    const { newData, index } = event
    washCycleList.value[index] = newData
}

const rowClass = (data: any) => {
    return [{ 'eol-row': typeof data.id === 'string' }]
}

const addCrate = () => {
    const newCrate: Ref<ICrate> = ref({
        id: washCycleList.value.length,
        crate_id: null,
        cup_size: cupSizes.value && cupSizes.value.length === 1 ? cupSizes.value[0].code : null,
        clean_cups: null,
        eol_cups: null,
    })

    const idexOfLastCrate = washCycleList.value.findIndex((crate) => typeof crate.id === 'string')
    washCycleList.value.splice(idexOfLastCrate, 0, newCrate.value)
    editingRows.value = [...editingRows.value, newCrate.value]
}

const removeCrate = (event: any, index: number) => {
    confirm.require({
        target: event.currentTarget,
        message: 'Weet u zeker dat u dit wilt verwijderen?',
        icon: 'pi pi-exclamation-triangle',
        group: 'headless',
        accept: () => {
            washCycleList.value.splice(index, 1)
        },
        reject: () => {},
    })
}

onMounted(async () => {
    washCycleStore.fetchWashCyclesAdmin()
    if (props.wash_cycle_id) {
        mode.value = 'update'
        const res = await washCycleStore.GetWashCycle(props.wash_cycle_id)
        const { eol_cups_per_size, outgoing_crates, location } = res.data
        washCycle.location = location
        washCycleList.value = outgoing_crates.map((item: any, idx: number) => ({
            id: idx,
            crate_id: item.crate_id,
            cup_size: item.cup_size,
            clean_cups: item.cup_count,
            eol_cups: 0,
        }))

        // add new rows to show the EOL cups
        cupSizes.value?.forEach((cupsize, idx) => {
            washCycleList.value.push({
                id: '000' + idx,
                crate_id: null,
                cup_size: cupsize.code,
                clean_cups: null,
                eol_cups: eol_cups_per_size.reduce((acc: number, eol: any) => {
                    if (eol.cup_size === cupsize.code) {
                        return eol.cup_count + acc
                    }
                    return acc
                }, 0),
            })
        })

        initialList.value = Object.assign([], washCycleList.value)
    }
})

async function addWashCycle() {
    const isValid = await v.value.$validate()
    if (!isValid) {
        return
    }
    //await washCycleStore.registerWashCycle('hrmdeit9h4', '180', 10, 20, 10)
    // Ok, data not good here. Have to setup the null-thingies properly. But make it to work first.
    await washCycleStore.registerWashCycle(
        // @ts-ignore
        washCycle.location?.location_id,
        // @ts-ignore
        washCycle.cup_size,
        //@ts-ignore
        washCycle.incoming,
        //@ts-ignore
        washCycle.broken,
        //@ts-ignore
        washCycle.outgoing,
    )
    await washCycleStore.fetchWashCyclesAdmin()
    toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Washbeurt opgeslagen',
        life: 3000,
    })
    washCycle.location = null
    washCycle.cup_size = null
    washCycle.incoming = null
    washCycle.broken = null
    washCycle.outgoing = null
    v.value.$reset()
}

async function updateWashCycle() {
    const eolCups = washCycleList.value.filter((crate: any) => typeof crate.id === 'string')
    const cleanCups = washCycleList.value.filter((crate: any) => typeof crate.id !== 'string')

    const requestObject: Components.Schemas.PatchWashCycle = {
        location_id: washCycle.location?.location_id,
        eol_cups_per_size: eolCups.map((crate: any) => ({
            crate_id: null,
            cup_size: crate.cup_size,
            cup_count: crate.eol_cups || 0,
        })),
        outgoing_crates: cleanCups.map((crate: any) => ({
            crate_id: crate.crate_id,
            cup_size: crate.cup_size,
            cup_count: crate.clean_cups || 0,
        })),
    }
    const response = await washCycleStore.UpdateWashCycle(
        props.wash_cycle_id as string,
        requestObject,
    )
    if (response.status === 200) {
        initialList.value = Object.assign([], washCycleList.value)
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Washbeurt opgeslagen',
            life: 3000,
        })
        router.push({ name: 'washing_management' })
    } else {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'kon niet opslaan',
            life: 3000,
        })
    }
}
onBeforeRouteLeave((to, from, next) => {
    const createFormIsDirty = v.value.$anyDirty && mode.value === 'create'
    const updateFormIsDirty =
        !lodash.isEqual(washCycleList.value, initialList.value) && mode.value === 'update'

    if (createFormIsDirty || updateFormIsDirty) {
        confirm.require({
            message: t('saveAlert.message'),
            header: t('saveAlert.title'),
            icon: 'pi pi-info-circle',
            position: 'top',
            rejectClass: 'p-button-secondary p-button-outlined',
            rejectLabel: t('saveAlert.back'),
            acceptLabel: t('saveAlert.leave'),
            group: 'positioned',
            accept: () => {
                next()
            },
            reject: () => {
                next(false)
            },
        })
    } else {
        next()
    }
})
</script>
<style scoped lang="scss">
.p-inputwrapper {
    width: 100%;
}
:deep(.eol-row) {
    background-color: #8e034512;
}
</style>
