import WashCycleManagement from './WashCycleManagement.vue'
import WashCycleRegister from './WashCycleRegister.vue'

export const WashingRoutes = [
    {
        name: 'washing_management',
        path: 'washing/list',
        component: WashCycleManagement,
    },
    {
        name: 'register_edit_wash_cycle',
        path: 'washing/register/:wash_cycle_id?',
        component: WashCycleRegister,
        props: true,
        meta: {
            breadcrumbs: [
                {
                    icon: 'pi pi-sync',
                    route: '/washing/list',
                },
                {
                    label: 'Wasbeurt registreren',
                },
            ],
        },
    },
]
