<script setup lang="ts">
import DataView from 'primevue/dataview'
import ProgressSpinner from 'primevue/progressspinner'

import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

import { useProductStore } from './productStore'
import OrderForm from './components/OrderForm.vue'
import ProductSelect from './components/ProductSelect.vue'

const { t } = useI18n()
const productStore = useProductStore()
const { products, loading } = storeToRefs(productStore)
</script>

<template>
    <OrderForm />
    <h2 class="mt-5">{{ t('orderProducts.chooseProduct') }}</h2>
    <DataView v-if="!loading" :value="products" dataKey="type">
        <template #list="slotProps">
            <div class="grid grid-nogutter">
                <div v-for="(item, index) in slotProps.items" :key="index" class="col-12">
                    <ProductSelect
                        :product="item"
                        :class="{ 'border-top-1 surface-border': index !== 0 }"
                    />
                </div>
            </div>
        </template>
    </DataView>
    <div v-else class="flex justify-content-center">
        <!-- TODO: shared loader compoenent for the project -->
        <ProgressSpinner />
        </div>
</template>
