<script setup lang="ts">
import { defineModel } from 'vue'
import Dropdown from 'primevue/dropdown'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

import { useLocationStore, type EndCustomerLocation } from '@/stores/LocationStore'
import LocationDisplay from './LocationDisplay.vue'
import { formatAddress } from '@/shared/locations'

const props = defineProps<{
    inputId?: string
}>()
const model = defineModel<EndCustomerLocation | null>({ required: true })

const { t } = useI18n()
const locationStore = useLocationStore()
const { locations } = storeToRefs(locationStore)

const locationLabel = (location: EndCustomerLocation) => formatAddress(location.address)
</script>
<template>
    <Dropdown
        :inputId="props.inputId"
        v-model="model"
        :location="!locations"
        :options="locations"
        datakey="location_id"
        :optionLabel="locationLabel"
        :placeholder="t('LocationSelect.placeholder')"
    >
        <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center">
                <LocationDisplay :location="slotProps.value" />
            </div>
            <span v-else>{{ slotProps.placeholder }}</span>
        </template>
        <template #option="slotProps">
            <div class="flex align-items-center">
                <LocationDisplay :location="slotProps.option" />
            </div>
        </template>
    </Dropdown>
</template>
