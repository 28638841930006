import Button from 'primevue/button';
<template>
    <div class="content-wrapper">
        <h1>404</h1>
        <h2>{{ $t('pageNotFound.title') }}</h2>
        <h3>{{ $t('pageNotFound.message') }}</h3>
        <Button
            icon="pi pi-home"
            :label="$t('pageNotFound.back')"
            @click="router.push({ name: 'dashboard' })"
            rounded
        ></Button>
    </div>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'

const router = useRouter()
</script>
<style scoped lang="scss">
.content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0px;
    text-align: center;

    h1 {
        font-size: 60px;
        font-weight: 900;
    }
    h2 {
        font-size: 50px;
        font-weight: 700;
    }
    h3 {
        font-size: 20px;
    }
}
</style>
