<template>
    <h1>Dashboard</h1>
    <div class="mb-4">
        <Card>
            <template #content>
                <ShipmentList :shipments="shipmentsToday" :multipleLocations="multipleLocations" />
            </template>
        </Card>

        <Card class="mt-3">
            <template #content>
                <DataTable
                    :value="washCycles"
                    stripedRows
                    style="min-width: 50rem"
                    datekey="id"
                    sortField="wash_date"
                    :sortOrder="-1"
                    size="small"
                >
                    <template #header>
                        <h3 class="p-0 m-0 font-bold flex align-items-center gap-2">
                            {{ t('washing.cycles') }}
                            <i
                                class="pi pi-info-circle"
                                style="font-size: 1rem; color: var(--takeables-secondary)"
                                v-tooltip="$t('shipments.deliveries_tooltip')"
                            ></i>
                        </h3>
                    </template>
                    <template #empty> {{ t('general.noResultsFound') }} </template>
                    <template #loading> {{ t('general.loading') }} </template>
                    <Column
                        field="end_customer_company_name"
                        sortable
                        :header="t('washing.company')"
                    ></Column>
                    <Column field="formatted_address" sortable :header="t('washing.location')">
                    </Column>
                    <Column field="wash_date" sortable :header="t('washing.wash_date')">
                        <template v-slot:body="slotProps">
                            {{ slotProps.data.wash_date }}
                        </template>
                    </Column>
                    <Column field="formatted_cup_count_per_size">
                        <template #header>
                            <span>{{ t('washing.incoming') }} </span>
                            <i
                                class="pi pi-info-circle tooltip-icon ml-2"
                                v-tooltip="$t('washing.incoming_tooltip')"
                            ></i>
                        </template>
                    </Column>
                    <Column field="formatted_broken_cups_per_size">
                        <template #header>
                            <span>{{ t('washing.broken_cups') }} </span>
                            <i
                                class="pi pi-info-circle tooltip-icon ml-2"
                                v-tooltip="$t('washing.broken_cups_tooltip')"
                            ></i>
                        </template>
                    </Column>
                    <Column field="formatted_outgoing_crates">
                        <template #header>
                            <span>{{ t('washing.outgoing') }} </span>
                            <i
                                class="pi pi-info-circle tooltip-icon ml-2"
                                v-tooltip="$t('washing.outgoing_tooltip')"
                            ></i>
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="p-3">TBD: Details of outgoing shipments</div>
                    </template>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useShipmentStore } from '@/shipments/ShipmentStore'
import { useWashCycleStore } from '@/washing-management/WashCycleStore'
import ShipmentList from '@/shipments/components/ShipmentList.vue'

const { t } = useI18n()
const shipmentStore = useShipmentStore()
const washCycleStore = useWashCycleStore()

const { shipmentsToday, multipleLocations } = storeToRefs(shipmentStore)
const { washCycles } = storeToRefs(washCycleStore)

onBeforeMount(() => {
    washCycleStore.fetchWashCyclesAdmin()
})
</script>
