import { type Ref, ref, computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import cupflowClient from '@/shared/open_api_client/OpenApiClient'
import type { Components } from '@/types/openapi.d.ts'

import type { WashCycle } from '@/washing-management/WashCycle'
import { useEndCustomerStore } from '@/customer-management/EndCustomerStore'

type WashCycleRequest = Components.Schemas.RegisterWashCycleRequest
type PatchWashCycle = Components.Schemas.PatchWashCycle
type CupCount = Components.Schemas.CupCount
type CupSize = Components.Schemas.CupSize

export const useWashCycleStore = defineStore(
    'WashCycleStore',
    () => {
        const washCycles: Ref<WashCycle[]> = ref([])
        const endCustomerStore = useEndCustomerStore()

        async function fetchWashCycles() {
            const response = await cupflowClient.get_washing_cycles_washing_wash_cycle__get()
            washCycles.value = await Promise.all(
                response.data.map((item) => formatWashCycleData(item)),
            )
        }
        async function fetchWashCyclesAdmin(exclude_with_cups: boolean = false) {
            const start_date = new Date()
            start_date.setDate(start_date.getDate() - 7)
            const response =
                await cupflowClient.get_washing_cycles_overview_washing_wash_cycle_overview_get({
                    start_date: start_date.toJSON().split('T', 1)[0],
                    end_date: undefined,
                    exclude_with_cups: exclude_with_cups,
                })
            washCycles.value = await Promise.all(
                response.data.map((item) => formatWashCycleData(item)),
            )
        }
        const registerWashCycle = async (
            location_id: string,
            cup_size: CupSize,
            incoming: number,
            broken: number,
            outgoing: number,
        ) => {
            const request: WashCycleRequest = {
                location_id: location_id,
                end_of_life_cups: [{ cup_count: broken, cup_size: cup_size }],
                outgoing_cups: [{ crate_id: null, cup_count: outgoing, cup_size: cup_size }],
            }
            return await cupflowClient.register_wash_cycle_washing_wash_cycle_register_post(
                null,
                request,
            )
        }

        const UpdateWashCycle = async (wash_cycle_id: string, patch_wash_cycle: PatchWashCycle) => {
            return await cupflowClient.edit_wash_cycle_washing_wash_cycle__wash_cycle_id__patch(
                wash_cycle_id,
                patch_wash_cycle,
            )
        }

        const GetWashCycle = async (wash_cycle_id: string) => {
            return await cupflowClient.get_wash_cycle_washing_wash_cycle__wash_cycle_id__get(
                wash_cycle_id,
            )
        }

        const DeleteWashCycle = async (wash_cycle_id: string) => {
            return await cupflowClient.delete_wash_cycle_washing_wash_cycle__wash_cycle_id__delete(
                wash_cycle_id,
            )
        }

        async function formatWashCycleData(washCycle: WashCycle): Promise<WashCycle> {
            let endCustomer = undefined
            if (washCycle?.location?.end_customer_id) {
                endCustomer = await endCustomerStore.getEndCustomerById(
                    washCycle?.location?.end_customer_id,
                )
            }

            return {
                ...washCycle,
                end_customer_company_name: endCustomer?.company_name
                    ? endCustomer.company_name
                    : '',
                formatted_address: washCycle.location ? formatLocation(washCycle.location) : '',
                formatted_cup_count_per_size: formatCupCountPerSize(washCycle.total_per_size),
                formatted_outgoing_crates: formatCupCountPerSize(washCycle.total_outgoing_per_size),
                formatted_broken_cups_per_size: formatCupCountPerSize(
                    washCycle.total_eol_cups_per_size,
                ),
            }
        }
        return {
            washCycles,
            fetchWashCycles,
            fetchWashCyclesAdmin,
            registerWashCycle,
            UpdateWashCycle,
            GetWashCycle,
            DeleteWashCycle,
        }
    },
    {},
)

function formatLocation(location: Components.Schemas.Location): string {
    const { street_name, street_no, street_no_addition } = location.address
    return [street_name, street_no, street_no_addition, location?.building_number]
        .filter((x) => x)
        .join(' ')
}

function formatCupCountPerSize(cupCounts: CupCount[] | null): string {
    if (!cupCounts) return ''
    return cupCounts.map((cupCount) => `${cupCount.cup_size}CC: ${cupCount.cup_count}`).join(' ')
}
