<template>
    <div class="h-full">
        <div
            v-if="loading"
            class="h-full flex flex-column justify-content-center align-items-center"
        >
            <Skeleton shape="circle" size="5rem"></Skeleton>
        </div>
        <div v-else-if="activeEndCustomer">
            <div class="action-buttons">
                <div class="flex align-items-center gap-2">
                    <h1>{{ $t('CustomerDetails.title') }}</h1>
                </div>
            </div>
            <EndCustomerComponent :endCustomer="activeEndCustomer!" :readOnly="true" />
            <LocationList :locations="locations" :readOnly="true" class="mt-5" />
        </div>
        <NotFoundPage v-else />
    </div>
</template>

<script setup lang="ts">
import { useEndCustomerStore } from './EndCustomerStore'
import EndCustomerComponent from './EndCustomerComponent.vue'
import LocationList from './LocationList.vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import NotFoundPage from '@/views/NotFoundPage.vue'
import { watchEffect, ref } from 'vue'

const route = useRoute()
const endCustomerStore = useEndCustomerStore()
const { locations, activeEndCustomer } = storeToRefs(endCustomerStore)
const loading = ref(false)

watchEffect(async () => {
    loading.value = true
    if (route.params.end_customer_id) {
        await endCustomerStore.setActiveEndCustomer(route.params.end_customer_id as string)
        loading.value = false
    }
})

watchEffect(() => {
    if (activeEndCustomer.value) {
        endCustomerStore.fetchLocationsForEndCustomer(activeEndCustomer.value.id)
    }
})
</script>
