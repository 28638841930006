import { type Ref, ref, computed } from 'vue'
import { defineStore, acceptHMRUpdate } from 'pinia'
import axios from 'axios'
import cupflowClient from '@/shared/open_api_client/OpenApiClient'
import type { Paths } from '@/types/openapi.d.ts'

// Error response structure from fastapi-users
export const enum VerifyErrorCode {
    BAD_TOKEN = 'VERIFY_USER_BAD_TOKEN',
    ALREADY_VERIFIED = 'VERIFY_USER_ALREADY_VERIFIED',
}

type VerifyErrorResponse = Paths.VerifyAuthAccountVerifyPost.Responses.$400

export const useAuthStore = defineStore(
    'auth',
    () => {
        // State
        const user: Ref<{ username: string; name: string } | null> = ref(null)
        const accessToken: Ref<string | null> = ref(null)

        // Getters
        const isAuthenticated = computed(() => !!user.value)

        // Actions
        const login = async (username: string, password: string) => {
            try {
                const response = await cupflowClient.auth_jwt_login_auth_jwt_login_post(
                    null,
                    {
                        username,
                        password,
                    },
                    // generated client doesn't do this...
                    { headers: { 'content-type': 'application/x-www-form-urlencoded' } },
                )

                accessToken.value = response.data.access_token
                user.value = { username: username, name: username }
                return true
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    console.log(e.response?.status)

                    if (e.response?.status === axios.HttpStatusCode.BadRequest) {
                        return false
                    } else if (e.response?.status === axios.HttpStatusCode.UnprocessableEntity) {
                        return false
                    }
                }
                throw e
            }
        }
        const logout = async () => {
            user.value = null
            accessToken.value = null
        }
        const verify = async (token: string, password: string) => {
            try {
                const response = await cupflowClient.verify_auth_account_verify_post(null, {
                    token,
                    password,
                })
                const user = response.data
                return { user }
            } catch (e) {
                if (axios.isAxiosError<VerifyErrorResponse, { token: string }>(e)) {
                    if (e.response && e.response.status == 400) {
                        return { error: e.response.data.detail }
                    }
                }
                throw e
            }
        }

        return {
            user,
            accessToken,
            isAuthenticated,
            login,
            logout,
            verify,
        }
    },
    { persist: true },
)

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
