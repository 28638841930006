<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import Button from 'primevue/button'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'

import { useOrderStore } from '../orderStore'
import { useProductStore, type ProductType } from '../productStore'

const props = defineProps<{
    editable?: boolean
}>()

const { t } = useI18n()
const productStore = useProductStore()
const orderStore = useOrderStore()

const orderItems = computed(() => {
    return Object.entries(orderStore.cart).map(([key, val]) => {
        return {
            ...productStore.getProduct(key as ProductType),
            amount: val,
        }
    })
})

const cupCount = (prod: { cups?: number; amount: number }) => {
    return prod.cups ? prod.cups * prod.amount : null
}

const remove = (type: ProductType, amount: number) => {
    orderStore.removeFromCart(type, amount)
}
</script>
<template>
    <DataTable :value="orderItems" class="w-full" id="order-cart">
        <Column field="img" header="">
            <template #body="slotProps">
                <img
                    :src="slotProps.data.img"
                    :alt="slotProps.data.name"
                    class="w-2rem border-round"
                />
            </template>
        </Column>
        <Column field="name" :header="t('orderProducts.product')" />
        <Column field="amount" :header="t('orderProducts.amount')" />
        <Column :header="t('orderProducts.numberOfCups')">
            <template #body="slotProps">
                {{ cupCount(slotProps.data) }}
            </template>
        </Column>
        <Column
            v-if="props.editable"
            :exportable="false"
            style="min-width: 8rem"
            bodyStyle="text-align:right"
        >
            <template #body="slotProps">
                <Button
                    icon="pi pi-trash"
                    outlined
                    rounded
                    severity="danger"
                    @click="remove(slotProps.data.type, slotProps.data.amount)"
                    :aria-label="t('general.remove')"
                />
            </template>
        </Column>
    </DataTable>
</template>
<style scoped lang="scss">
:deep(.p-column-title:first-letter) {
    text-transform: uppercase;
}
</style>
