<!-- 
    This template uses the S42 UPU standard for the address.
    More information: https://www.upu.int/UPU/media/upu/documents/PostCode/S42_International-Addressing-Standards.pdf


    For future: 
    - Datastructure adheres to S42 standard
    - Component atm only supports Dutch addresses
    - Future extensions by setting a differect type (ie international) to support international addresses.
-->
<template>
    <div>
        <form>
            <div class="formgrid grid gap-3 justify-content-between px-4">
                <div class="field w-3 flex-grow-1">
                    <label class="font-semibold" for="street_postcode"
                        >{{ $t('address.street_postcode') }}:</label
                    >
                    <span class="p-input-icon-right">
                        <i class="pi pi-check top-45" v-show="postcode_complete" />
                        <InputMask
                            v-model="address.postcode as string"
                            type="text"
                            id="street_postcode"
                            mask="9999aa"
                            placeholder="1000AA"
                            @blur="check_address()"
                            @complete="postcode_complete = true"
                            class="w-full"
                            :disabled="readOnly"
                            :invalid="addressValidation?.postcode.$error"
                        />
                        <div
                            class="input-errors"
                            v-for="error of addressValidation?.postcode.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </span>
                </div>
                <div class="field w-3 flex-grow-1">
                    <label class="font-semibold" for="street_no"
                        >{{ $t('address.street_no') }}:</label
                    >
                    <span class="p-input-icon-right">
                        <i class="pi pi-check top-45" v-show="housenumber_complete" />
                        <InputText
                            v-model="address.street_no as Nullable<string>"
                            type="number"
                            id="street_no"
                            @blur="leaveHouseNumber()"
                            class="w-full"
                            :disabled="readOnly"
                            :invalid="addressValidation?.street_no.$error"
                        />
                        <div
                            class="input-errors"
                            v-for="error of addressValidation?.street_no.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </span>
                </div>
                <div class="field w-3 flex-grow-1">
                    <label class="font-semibold" for="street_no_addition"
                        >{{ $t('address.street_no_addition') }}:</label
                    >
                    <span class="p-input-icon-right">
                        <i class="pi pi-check top-45" v-show="housenumber_addition_complete" />
                        <InputText
                            v-model="address.street_no_addition"
                            type="text"
                            id="street_no_addition"
                            @blur="leaveHouseNumberAddition()"
                            :disabled="readOnly"
                            class="w-full"
                            :invalid="addressValidation?.street_no_addition.$error"
                        />
                        <div
                            class="input-errors"
                            v-for="error of addressValidation?.street_no_addition.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </span>
                </div>
                <div class="field w-3 flex-grow-1">
                    <label class="font-semibold" for="street_name"
                        >{{ $t('address.street_name') }}:</label
                    >
                    <InputText
                        v-model="address.street_name"
                        type="text"
                        id="street_name"
                        :disabled="!incorrectCombinationButFilled()"
                        placeholder="-"
                        class="w-full"
                        :invalid="addressValidation?.street_name.$error"
                        @blur="addressValidation?.street_name.$touch()"
                    />
                    <div
                        class="input-errors"
                        v-for="error of addressValidation?.street_name.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
                <div class="field w-3 flex-grow-1">
                    <label class="font-semibold" for="town">{{ $t('address.town') }}:</label>
                    <InputText
                        v-model="address.town"
                        type="text"
                        id="town"
                        :disabled="!incorrectCombinationButFilled()"
                        placeholder="-"
                        class="w-full"
                        :invalid="addressValidation?.town.$error"
                        @blur="addressValidation?.town.$touch()"
                    />
                    <div
                        class="input-errors"
                        v-for="error of addressValidation?.town.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
                <div v-show="incorrectCombinationButFilled()">
                    <span class="font-normal">{{
                        $t('address.postalcode_housenumber_not_found')
                    }}</span>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import type { Address } from './Address'
import { ref } from 'vue'
import { useAddressStore } from './AddressStore'
import type { Nullable } from 'primevue/ts-helpers'

const props = defineProps<{
    address: Address
    validation?: any
    errors?: String[]
    readOnly?: boolean
}>()
const emit = defineEmits(['get-address', 'is-street-town-required'])

const address = ref(props.address)
const addressStore = useAddressStore()
const postcode_complete = ref(false)
const housenumber_complete = ref(false)
const housenumber_addition_complete = ref(false)

let addressValidation = props.validation

async function leaveHouseNumber() {
    addressValidation?.street_no.$touch()
    await check_address()
    housenumber_complete.value = true
    emit('get-address', address.value)
}
async function leaveHouseNumberAddition() {
    addressValidation?.street_no_addition.$touch()
    await check_address()
    housenumber_addition_complete.value = true
    emit('get-address', address.value)
}
async function check_address() {
    addressValidation?.postcode.$touch()
    emit('get-address', address.value)

    await addressStore.getAddressForPostCodeHouseNumber(
        address.value.postcode!,
        address.value.street_no!,
        address.value.street_no_addition!,
    )
    if (addressStore.isValid) {
        address.value.street_name = addressStore.currentAddress.street_name
        address.value.town = addressStore.currentAddress.town
    }
}
function incorrectCombinationButFilled(): boolean {
    const streetTownRequired = (((address.value.postcode &&
        address.value.street_no &&
        !addressStore.isValid) as boolean) && !props.readOnly) as boolean
    emit('is-street-town-required', streetTownRequired)
    addressValidation = props.validation
    return streetTownRequired
}
</script>
<style scoped lang="scss">
.top-45 {
    top: 45px;
}
</style>
