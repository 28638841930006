<template>
    <Dialog
        v-model:visible="isDialogVisible"
        modal
        header="Partial Crates Found"
        :style="{ width: '25rem' }"
        @show="() => (scannedPartialCrateId = null)"
    >
        <span class="p-text-secondary block mb-2"
            >A partial crate with cupsize <Tag :value="partialCrates[0].cup_size"></Tag> was found
            for this location.</span
        >
        <span class="p-text-secondary block mb-2"
            >Please scan the partial crate with the id
            <Tag :value="partialCrates[0].crate_id"></Tag> here:</span
        >
        <div class="card">
            <InputText
                ref="partialCrateIdElement"
                class="h-3rem text-lg"
                v-model="scannedPartialCrateId"
                type="text"
                placeholder="Crate ID"
            />
        </div>
        <div class="flex justify-content-end gap-2 my-3">
            <Button
                type="button"
                label="Cancel"
                severity="secondary"
                @click="isDialogVisible = false"
            ></Button>
            <Button type="button" label="Save" @click="savePartialCrate()"></Button>
        </div>
    </Dialog>
    <div
        class="h-full border-1 border-white border-round flex flex-column align-items-center overflow-auto"
        style="background-color: #8e0444"
    >
        <div class="pt-2 px-3 w-full">
            <Button
                rounded
                class="h-2rem w-2rem absolute top-0 left-0 mt-3 ml-5"
                icon="pi pi-arrow-left"
                :pt="{
                    icon: {
                        class: 'text-sm',
                    },
                }"
                @click="() => $router.push({ name: 'washer_menu' })"
            >
            </Button>
        </div>
        <h4 class="text-center font-bold mt-1 mb-3">Clean Crate</h4>
        <div
            class="p-card py-2 px-2 flex flex-column gap-2"
            style="width: 95%; margin-bottom: 15px"
        >
            <DropDown
                ref="locationSelectionElement"
                :options="locationList"
                optionLabel="name"
                optionValue="value"
                placeholder="Select Location"
                v-model="wash_cycle_id"
                :highlightOnSelect="false"
                @change="locationOnchange"
            >
                <template #option="slotProps">
                    <div class="flex align-items-center">
                        <div class="date-tag">{{ slotProps.option.date }}</div>
                        <div>{{ slotProps.option.name }}</div>
                    </div>
                </template>
            </DropDown>

            <div class="text-center" v-if="cupSizes.length > 0">
                <Carousel
                    :value="cratesArr"
                    :numVisible="1"
                    :numScroll="1"
                    :page="currentPage"
                    verticalViewPortHeight="180px"
                    contentClass="flex align-items-center"
                    :showNavigators="cratesArr.length > 1 ? true : false"
                    :showIndicators="cratesArr.length > 1 ? true : false"
                    :pt="{
                        previousButton: {
                            class: 'carousel-prev-next',
                        },
                        nextButton: {
                            class: 'carousel-prev-next',
                        },
                    }"
                >
                    <template #item="slotProps">
                        <div
                            class="border-1 surface-border border-round p-2 m-2 flex flex-column gap-2"
                        >
                            <div
                                class="flex justify-content-end"
                                v-if="cratesArr.length > 1"
                                style="position: relative"
                            >
                                <Button
                                    class="remove-crate-btn"
                                    icon="pi pi-minus"
                                    rounded
                                    @click="removeCrate(slotProps.index)"
                                />
                            </div>

                            <div>
                                <SelectButton
                                    v-if="cupSizes.length > 0"
                                    class="w-full"
                                    v-model="slotProps.data.cup_size"
                                    :options="cupSizes"
                                    aria-labelledby="basic"
                                    optionLabel="name"
                                    optionValue="value"
                                    :allowEmpty="false"
                                    :pt="{
                                        button: {
                                            class: 'w-6 text-lg',
                                        },
                                    }"
                                />
                                <p class="m-0 font-italic" v-else>
                                    Select a location to see the cup sizes
                                </p>
                            </div>

                            <InputText
                                ref="crateIdElement"
                                class="h-3rem text-lg"
                                id="input"
                                v-model="slotProps.data.crate_id"
                                @keyup="getCrateIdFromURL(slotProps.data.crate_id, slotProps.index)"
                                type="text"
                                placeholder="Crate ID"
                            />

                            <div class="flex justify-content-between gap-2">
                                <InputGroup>
                                    <Button
                                        class="w-5rem"
                                        icon="pi pi-minus"
                                        aria-label="substract one from cup count"
                                        @click="
                                            slotProps.data.cup_count = Math.max(
                                                0,
                                                (slotProps.data.cup_count ?? 0) - 1,
                                            )
                                        "
                                    />
                                    <InputText
                                        ref="noOfCupsElement"
                                        type="number"
                                        v-model="slotProps.data.cup_count"
                                        class="h-3rem w-full text-lg border-noround"
                                    />
                                    <Button
                                        class="w-5rem"
                                        icon="pi pi-plus"
                                        aria-label="add one to cup count"
                                        @click="
                                            slotProps.data.cup_count =
                                                (slotProps.data.cup_count ?? 0) + 1
                                        "
                                    />
                                </InputGroup>
                                <Button
                                    rounded
                                    class="h-3rem text-lg text-primary"
                                    label="Full"
                                    raised
                                    @click="
                                        () => {
                                            slotProps.data.cup_count = crateStore.crateCapacity(
                                                slotProps.data.cup_size,
                                            )
                                            addCrate()
                                        }
                                    "
                                    outlined
                                />
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <Button
                            class="add-crate-btn"
                            :style="cratesArr.length > 1 ? 'margin-top: -45px;' : ''"
                            icon="pi pi-plus"
                            label="Add crate"
                            @click="addCrate"
                        />
                    </template>
                </Carousel>

                <Button
                    v-if="!isCratesReady"
                    class="w-5"
                    type="button"
                    label="Proceed"
                    icon="pi pi-check"
                    @click="isCratesReady = true"
                />
            </div>

            <div v-if="isCratesReady" class="broken-cups-wrapper">
                <label class="text-sm">End of life Cups:</label>
                <div
                    v-for="(brokenCups, index) in endOfLifeCups"
                    :key="brokenCups.cup_size"
                    class="flex align-items-center gap-2 mb-1"
                >
                    <label class="text-lg font-bold">{{ brokenCups.cup_size }}CC:</label>
                    <InputGroup>
                        <Button
                            class="w-5rem bg-red-400"
                            icon="pi pi-minus"
                            aria-label="substract one from end-of-life cups"
                            @click="updateBrokenCups(index, 'remove')"
                        />
                        <InputText
                            ref="noOfBrokenCupsElement"
                            type="number"
                            :value="brokenCups.cup_count"
                            :aria-label="`${brokenCups.cup_size}cc cups end-of-life count`"
                            @update:modelValue="
                                (e) => {
                                    brokenCups.cup_count = Number(e)
                                }
                            "
                            class="h-3rem w-full text-lg border-noround"
                        />
                        <Button
                            class="w-5rem bg-red-400"
                            icon="pi pi-plus"
                            aria-label="add one to end-of-life cups"
                            @click="updateBrokenCups(index, 'add')"
                        />
                    </InputGroup>
                </div>
            </div>

            <div v-if="isCratesReady" class="text-right">
                <Button
                    class="text-lg my-0"
                    rounded
                    icon="pi pi-save"
                    label="Submit"
                    :pt="{
                        icon: {
                            class: 'text-lg ml-2',
                        },
                    }"
                    iconPos="right"
                    @click="submitOutgoingCrate"
                >
                </Button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, onScopeDispose, ref, type Ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import type { Components } from '@/types/openapi.d.ts'
import { useCrateStore } from '@/washing-management/CrateStore'
import { useWashCycleStore } from '@/washing-management/WashCycleStore'
import DropDown, { type DropdownChangeEvent } from 'primevue/dropdown'
import Carousel from 'primevue/carousel'
import { onBeforeRouteLeave } from 'vue-router'
import { useConfirm } from 'primevue/useconfirm'
import Dialog from 'primevue/dialog'
import Tag from 'primevue/tag'

type CupCount = Components.Schemas.CupCount

const confirm = useConfirm()
const toast = useToast()
const crateStore = useCrateStore()
const washCycleStore = useWashCycleStore()

const cupSizes: Ref<{ name: string; value: Components.Schemas.CupSize }[]> = ref([])
const endOfLifeCups: Ref<Components.Schemas.EolCount[]> = ref([])
const crateIdElement = ref()
const locationSelectionElement = ref()
const noOfCupsElement = ref()
const numberOfBrokenCups: any = ref(0)
const wash_cycle_id = ref('')
const locationList: Ref<{ name: string; value: string; date: string }[]> = ref([])
const locationSelectionValues = {
    oldValue: '',
    newValue: '',
}
const selectedLocationId = ref('')
const partialCrates: Ref<Components.Schemas.CrateWithCupCount[]> = ref([])
const isDialogVisible = ref(false)
const partialCrateIdElement = ref()
const scannedPartialCrateId = ref(null)
let partialCrateTimeout: ReturnType<typeof setTimeout>

function updateBrokenCups(index: number, action: string) {
    if (action === 'remove') {
        if (endOfLifeCups.value[index].cup_count > 0) {
            endOfLifeCups.value[index].cup_count--
        }
    } else {
        endOfLifeCups.value[index].cup_count++
    }
}

function locationOnchange(event: DropdownChangeEvent) {
    if (event.value) {
        locationSelectionValues.oldValue = locationSelectionValues.newValue

        if (locationSelectionValues.oldValue === '') {
            locationSelectionValues.newValue = event.value
            setCupSizes(locationSelectionValues.newValue)
            addCrate()
            checkForPartialCrates()
            return
        }

        locationSelectionValues.newValue = event.value

        if (locationSelectionValues.oldValue !== locationSelectionValues.newValue) {
            confirm.require({
                header: 'Unsaved changes',
                message:
                    'Are you sure that you want to switch location? All unsaved changes will be lost.',
                icon: 'pi pi-info-circle',
                position: 'top',
                rejectClass: 'p-button-secondary p-button-outlined',
                rejectLabel: 'No',
                acceptLabel: 'Yes',
                group: 'washer',
                onHide() {
                    wash_cycle_id.value = locationSelectionValues.oldValue
                    locationSelectionValues.newValue = locationSelectionValues.oldValue
                },
                accept: () => {
                    wash_cycle_id.value = locationSelectionValues.newValue
                    cratesArr.value = []
                    isCratesReady.value = false
                    numberOfBrokenCups.value = 0
                    setCupSizes(locationSelectionValues.newValue)
                    addCrate()
                    checkForPartialCrates()
                },
                reject: () => {
                    wash_cycle_id.value = locationSelectionValues.oldValue
                    locationSelectionValues.newValue = locationSelectionValues.oldValue
                },
            })
        }
    }
}

const cratesArr: Ref<Partial<CupCount>[]> = ref([])
const currentPage = ref(0)
const isCratesReady = ref(false)

onMounted(async () => {
    fetchUpdatedLocations()
})

async function fetchUpdatedLocations() {
    await washCycleStore.fetchWashCyclesAdmin(true)

    locationList.value = washCycleStore.washCycles.map((location) => {
        return {
            name:
                location.location?.address.street_no +
                ', ' +
                location.location?.address.street_name +
                ', ' +
                location.location?.address.town,
            value: location.wash_cycle_id,
            date: location.wash_date,
        }
    })
}

async function checkForPartialCrates() {
    const partialCrateRes = await crateStore.fetchPartialCratesBasedOnLocation(
        selectedLocationId.value,
    )
    if (partialCrateRes && partialCrateRes.count > 0) {
        partialCrates.value = partialCrateRes.crates
        showPartialCrateDialog()
    } else {
        partialCrates.value = []
    }
}

function showPartialCrateDialog() {
    isDialogVisible.value = true
    partialCrateTimeout = setTimeout(() => {
        if (partialCrateIdElement.value?.$el) {
            partialCrateIdElement.value.$el.focus()
        }
    }, 100)
}

function savePartialCrate() {
    if (scannedPartialCrateId.value) {
        cratesArr.value[currentPage.value].crate_id = scannedPartialCrateId.value
        cratesArr.value[currentPage.value].cup_size = partialCrates.value[0].cup_size
        if (
            !partialCrates.value
                .map((crate) => crate.crate_id)
                .includes(scannedPartialCrateId.value)
        ) {
            toast.add({
                severity: 'warn',
                summary: 'Note',
                detail: 'This is not a partial crate',
                life: 3000,
            })
        } else {
            toast.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Partial crate detected',
                life: 3000,
            })
            partialCrates.value = partialCrates.value.filter(
                (crate) => crate.crate_id !== scannedPartialCrateId.value,
            )
        }
    }
    isDialogVisible.value = false
}

function getCrateIdFromURL(crate_id: string | undefined, index: number) {
    if (crate_id) {
        try {
            // https://my.takeables.nl/crate/qr_scan/23jq4pnpt7
            const url = new URL(crate_id)
            const code = url.pathname.replace(/\/$/, '').split('/').pop()
            if (code) {
                cratesArr.value[index].crate_id = code
            }
        } catch (error) {
            // do nothing since the crateId is not a URL
        }
    }
}

function setCupSizes(value: string) {
    cupSizes.value = []
    endOfLifeCups.value = []
    washCycleStore.washCycles.forEach((location) => {
        if (location.wash_cycle_id === value) {
            selectedLocationId.value = location.location_id
            if (location.location?.cupsizes) {
                location.location?.cupsizes.forEach((cup_size) => {
                    cupSizes.value.push({ name: `${cup_size}CC`, value: cup_size })
                    endOfLifeCups.value.push({ cup_count: 0, cup_size: cup_size })
                })
            }
        }
    })
}

function addCrate() {
    cratesArr.value.push({
        cup_size: cupSizes.value.length > 0 ? cupSizes.value[0].value : '180',
    })
    currentPage.value = cratesArr.value.length - 1
    if (partialCrates.value.length > 0) {
        showPartialCrateDialog()
    }
}

function removeCrate(index: number) {
    confirm.require({
        header: 'Remove Crate',
        message: 'Are you sure you want to remove this crate?',
        icon: 'pi pi-info-circle',
        position: 'top',
        rejectClass: 'p-button-secondary p-button-outlined',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        group: 'washer',
        accept: () => {
            cratesArr.value.splice(index, 1)
            currentPage.value = cratesArr.value.length - 1
        },
        reject: () => {},
    })
}

async function submitOutgoingCrate() {
    const validCrates = cratesArr.value.filter(validateCupCount)
    const isOutgoingCrateRegistered = await crateStore.registerWashCycleOutgoing({
        crates: validCrates,
        wash_cycle_id: wash_cycle_id.value,
        eol_cups: endOfLifeCups.value,
    })
    if (isOutgoingCrateRegistered) {
        toast.add({
            severity: 'success',
            detail: 'Success',
            life: 3000,
        })

        // reset the form
        cratesArr.value = []
        isCratesReady.value = false
        numberOfBrokenCups.value = 0
        locationSelectionValues.oldValue = ''
        locationSelectionValues.newValue = ''
        wash_cycle_id.value = ''
        cupSizes.value = []
        selectedLocationId.value = ''
        partialCrates.value = []
        scannedPartialCrateId.value = null
        fetchUpdatedLocations()
    } else {
        toast.add({
            severity: 'error',
            detail: 'Failed',
            life: 3000,
        })
    }
}

onBeforeRouteLeave((to, from, next) => {
    if (wash_cycle_id.value) {
        confirm.require({
            message: 'Unsaved changes',
            header: 'Do you want to leave without saving the changes?',
            icon: 'pi pi-info-circle',
            position: 'top',
            rejectClass: 'p-button-secondary p-button-outlined',
            rejectLabel: 'Back',
            acceptLabel: 'Leave',
            group: 'washer',
            accept: () => {
                next()
            },
            reject: () => {
                next(false)
            },
        })
    } else {
        next()
    }
})

onScopeDispose(() => {
    clearTimeout(partialCrateTimeout)
})

function validateCupCount(cupCount: Partial<CupCount>): cupCount is CupCount {
    // TODO: implement actual validation on the form/inputs. For now we just ignore 'em.
    return cupCount.crate_id !== undefined && (cupCount.cup_count ?? -1) >= 0
}
</script>

<style lang="scss">
.p-stepper {
    flex-basis: 40rem;
}
.date-tag {
    background-color: #8e0444;
    color: white;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
    font-size: 12px;
}

.p-carousel-content {
    position: relative;
}

.p-carousel-container {
    width: 100%;
}

.carousel-prev-next {
    width: 10px;
}

.add-crate-btn {
    float: right;
    margin-bottom: 5px;
}

.remove-crate-btn {
    position: absolute;
    bottom: -13px;
    right: -16px;
    color: white;
    background-color: red;
    height: 30px;
    width: 30px;
    border: none;
}

.broken-cups-wrapper {
    margin-top: -20px;
}
</style>
