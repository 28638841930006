import { type Ref, ref, watchEffect } from 'vue'
import { defineStore } from 'pinia'
import type { Components } from '@/types/openapi.d.ts'

import cupflowClient from '@/shared/open_api_client/OpenApiClient'
import { useAuthStore } from '@/authentication/authStore'


export const useUserStore = defineStore(
    'userStore', 
    () => {
        const user: Ref<Components.Schemas.UserRead | null> = ref(null)

        const fetchUser = async () => {
            const result = await cupflowClient.users_current_user_users_me_get({})
            user.value = result.data
            return true
        }

        const authStore = useAuthStore()
        //computedAsync
        watchEffect(async () => {
            authStore.isAuthenticated ? await fetchUser() : null
        })

        return {
            user,
            fetchUser
        }
    },
    // {
    //     persist: true
    // }
)
