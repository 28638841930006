<script setup lang="ts">
import { computed, toRef } from 'vue'
import type { EndCustomerLocation } from '@/stores/LocationStore'
import { formatStreet } from '@/shared/locations'

const props = defineProps<{
    location: EndCustomerLocation
}>()
const address = toRef(() => props.location.address)

const streetDisplay = computed(() => formatStreet(address.value))
</script>
<template>
    <div>
        <span>{{ streetDisplay }}</span>,
        <span>{{ address.postcode }}</span>,
        <span>{{ address.town }}</span>
    </div>
</template>
