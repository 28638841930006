import dayjs from 'dayjs'
import OpenAPIClientAxios from 'openapi-client-axios'
import { type AxiosRequestConfig } from 'axios'
import { useRouter } from 'vue-router'

import type { Components, Client as CupflowClient } from '@/types/openapi.d.ts'
import { useAuthStore } from '@/authentication/authStore'
import { backendURI } from '@/shared/constant/Config'

import { router } from '../../router'

Date.prototype.toJSON = function () {
    // you can use moment or anything else you prefer to format
    // the date here
    return dayjs(this).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
}

function add_interceptor(client: CupflowClient) {
    client.interceptors.request.use(
        //@ts-ignore
        (config: AxiosRequestConfig) => {
            const authStore = useAuthStore()
            if (authStore.isAuthenticated) {
                if (config.headers === undefined) {
                    config.headers = {}
                }

                config.headers['Authorization'] = `Bearer ${authStore.accessToken}`
            }

            return config
        },
        (error) => {
            return Promise.reject(error)
        },
    )

    client.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            const originalRequest = error.config
            if (
                error.response.status === 401 &&
                !originalRequest._retry &&
                error.config.url !== '/auth/jwt/login/'
            ) {
                const authStore = useAuthStore()
                if (authStore.isAuthenticated) {
                    useAuthStore().logout()
                    router.push({
                        path: '/login',
                        query: { redir: encodeURIComponent(location.pathname + location.search) },
                    })
                }
            }
            return Promise.reject(error)
        },
    )
}

const api = new OpenAPIClientAxios({
    definition: '/openapi.json',
    axiosConfigDefaults: { baseURL: backendURI },
})

const cupflowClient = await api.init<CupflowClient>()

add_interceptor(cupflowClient)

export default cupflowClient
