<template>
    <div v-if="activeEndCustomer == undefined">
        {{ t('location.noEndCustomerSelected') }}
    </div>
    <div v-else>
        <h1>
            {{ t('location.locationFor') }} {{ endCustomerStore.activeEndCustomer?.company_name }}
            {{
                endCustomerStore.locations.length > 0
                    ? ' (' + endCustomerStore.locations.length + ')'
                    : ''
            }}
        </h1>
        <LocationList :locations="locations" :readOnly="true" />
    </div>
</template>

<script setup lang="ts">
import { watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

import { useAuthCtxStore } from '@/authorization/authCtxStore'
import { useEndCustomerStore } from './EndCustomerStore'
import LocationList from './LocationList.vue'

const { t } = useI18n()
const authCtxStore = useAuthCtxStore()

const endCustomerStore = useEndCustomerStore()

const { activeEndCustomer, locations } = storeToRefs(endCustomerStore)

watchEffect(async () => {
    if (authCtxStore.end_customer_id) {
        await endCustomerStore.setActiveEndCustomer(authCtxStore.end_customer_id)
    }
})

watchEffect(() => {
    if (activeEndCustomer.value) {
        endCustomerStore.fetchLocationsForEndCustomer(activeEndCustomer.value.id)
    }
})

</script>
