<script setup lang="ts">
import { computed, ref } from 'vue'
import Panel from 'primevue/panel'
import Divider from 'primevue/divider'
import { useI18n } from 'vue-i18n'
import { useToast } from 'primevue/usetoast'

import { i18n } from '@/i18n'
import { useUserStore } from '@/authentication/userStore'
import { useOrderStore } from './orderStore'
import LocationDisplay from './components/LocationDisplay.vue'
import OrderCart from './components/OrderCart.vue'

const { t } = useI18n()
const toast = useToast()
const userStore = useUserStore()
const orderStore = useOrderStore()

const dateFmt = computed(() => {
    // TODO: fix languageStore, make languae/selectedLanguage reactive
    // So we don't haveo to use i18n directly here.
    return new Intl.DateTimeFormat(i18n.global.locale.value, {
        timeZone: 'CET',
        dateStyle: 'full',
    })
})
const dateDisplay = computed(() => {
    return dateFmt.value.format(orderStore.selectedDate)
})
const notifyError = () => {
    // TODO: register a global handler for uncaught exceptions.
    toast.add({
        severity: 'error',
        summary: t('errors.unknown'),
        detail: t('errors.unknownDetail'),
        life: 3000,
    })
}

const pending = ref(false)
const done = ref(false)
const submit = async () => {
    try {
        pending.value = true
        await orderStore.submit()
        orderStore.clearCart()
        pending.value = false
        done.value = true
    } catch (e) {
        console.error(e)
        notifyError()
    }
}
</script>

<template>
    <Panel v-if="!done" class="order-confirm" :header="t('orderProducts.overview.title')">
        <div class="grid">
            <!-- TODO: allowed location_manager to get its end_customer info...
            <div class="col-3">
                {{ t('customer.companyName') }}
            </div>
            <div class="col-9">
                {{ companyName }}
            </div>
            -->
            <div class="col-3 cf-label">
                {{ t('orderProducts.overview.orderEmail') }}
            </div>
            <div class="col-9">
                {{ userStore.user?.email }}
            </div>
            <div class="col-3 cf-label">
                {{ t('orderProducts.location') }}
            </div>
            <div class="col-9">
                <LocationDisplay :location="orderStore.selectedLocation!" />
            </div>
            <div class="col-3 cf-label">
                {{ t('orderProducts.preferredDeliveryDate') }}
            </div>
            <div class="col-9">
                {{ dateDisplay }}
            </div>
            <div class="col-12">
                <OrderCart />
            </div>
        </div>
        <Divider />
        <div class="col-12 flex justify-content-between flex-wrap">
            <router-link :to="{ name: 'order_select' }">
                <Button rounded severity="secondary" :label="t('general.back')" />
            </router-link>
            <Button rounded icon="pi pi-send" :label="t('orderProducts.order')" @click="submit" :loading="pending" />
        </div>
    </Panel>
    <Panel v-else :header="t('orderProducts.ordered')">
        <p>{{ t('orderProducts.overview.finished') }}</p>
    </Panel>
</template>
<style scoped lang="scss">
.order-confirm {
   max-width: 60em;
 }
.cf-label:first-letter {
  text-transform: uppercase;
}
</style>
