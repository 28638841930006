import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import cupflowClient from '@/shared/open_api_client/OpenApiClient'
import type { Components } from '@/types/openapi'

type DeliveryReadyRequest = Components.Schemas.DeliveryReadyRequest
type ProductType = Components.Schemas.Product
type ShipmentResponse = Components.Schemas.ShipmentResponse
type ProductResponse = Components.Schemas.ProductResponse & {
    scanned?: boolean
    shipment_unit_id?: string
}
type CrateResponse = Components.Schemas.CrateResponse & {
    scanned?: boolean
    shipment_unit_id?: string
}

export interface IPickupTask {
    type: string
    company: string
    location: string
    crates?: CrateResponse[]
    products?: ProductResponse[]
    shipment_id: string | null
}
export const usePickupStore = defineStore(
    'PickupStore',
    () => {
        const pendingShipments: Ref<ShipmentResponse[]> = ref([])

        async function fetchPendingDeliveryShipments() {
            try {
                const response = await cupflowClient.deliveries_shipments_deliveries_pending_get()
                pendingShipments.value = response.data
            } catch (error) {
                console.error(error)
            }
        }

        async function submitScannedCrates(scannedCrates: DeliveryReadyRequest): Promise<boolean> {
            try {
                const response =
                    await cupflowClient.delivery_shipment_ready_shipments_deliveries_crate_ready_post(
                        null,
                        scannedCrates,
                    )
                if (response.status === 200 || response.status === 201) {
                    return true
                }
                return false
            } catch (error) {
                console.error(error)
                return false
            }
        }

        return {
            pendingShipments,
            fetchPendingDeliveryShipments,
            submitScannedCrates,
        }
    },
    {},
)
