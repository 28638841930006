<template>
    <div class="bg-white border-round h-full flex flex-column">
        <Button
            rounded
            class="back-btn"
            icon="pi pi-arrow-left"
            :pt="{
                icon: {
                    class: 'text-1xl',
                },
            }"
            @click="() => $router.push({ name: 'washer_menu' })"
        >
        </Button>
        <div class="pt-2 px-3 w-full">
            <div class="text-center">
                <p class="text-black-alpha-90 font-bold text-3xl m-0">Pick Tasks</p>
            </div>
            <Button
                class="logout-btn"
                icon="pi pi-sign-out"
                @click="router.push({ name: 'login' })"
            >
            </Button>
        </div>

        <div class="overflow-y-scroll">
            <div v-if="isLoading" class="card flex justify-content-center pt-8">
                <ProgressSpinner
                    style="width: 50px; height: 50px"
                    strokeWidth="8"
                    fill="#f0e9e9"
                    animationDuration=".05s"
                    aria-label="Custom ProgressSpinner"
                />
            </div>
            <div v-else-if="pickupList.length === 0">
                <div class="text-center pt-8">
                    <p class="text-1xl text-black-alpha-90 font-italic">No tasks available</p>
                </div>
            </div>
            <div v-else v-for="task in pickupList">
                <div
                    class="task-wrapper"
                    @click="navigate(task)"
                    :class="{
                        'bg-blue-700': task.type === 'clean-cups',
                        'bg-green-700': task.type === 'new-cups',
                        'bg-red-700': task.type === 'clean-crates',
                    }"
                >
                    <div class="flex align-items-center">
                        <svg
                            v-if="task.type === 'clean-cups'"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            height="1rem"
                            fill="currentColor"
                            class="w-2rem"
                        >
                            <path
                                d="M55.2 17.7C60.6 6.8 71.7 0 83.8 0H364.2c12.1 0 23.2 6.8 28.6 17.7L416 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96S14.3 64 32 64L55.2 17.7zM74.7 453.8L48 160H400L373.3 453.8c-3 33-30.6 58.2-63.7 58.2H138.4c-33.1 0-60.7-25.2-63.7-58.2zM323.8 290.9c1.7-18.7-13.1-34.9-31.9-34.9H156c-18.8 0-33.6 16.2-31.9 34.9l8.7 96c1.5 16.5 15.3 29.1 31.9 29.1H283.2c16.6 0 30.4-12.6 31.9-29.1l8.7-96z"
                            />
                        </svg>
                        <svg
                            v-else-if="task.type === 'new-cups'"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            height="1rem"
                            fill="currentColor"
                            class="w-2rem"
                        >
                            <path
                                d="M108.2 48h232c3 0 5.8 1.7 7.2 4.4L361.2 80H88l13-27.4c1.3-2.8 4.1-4.6 7.2-4.6zM414.8 80L390.3 31c-9.5-19-28.9-31-50.1-31h-232C86.6 0 66.9 12.4 57.6 32L34.8 80H24C10.7 80 0 90.7 0 104s10.7 24 24 24H50 400h24c13.3 0 24-10.7 24-24s-10.7-24-24-24h-9.2zM47.4 160L74.6 453.9c3 32.9 30.7 58.1 63.7 58.1H309.7c33.1 0 60.7-25.2 63.7-58.1L400.6 160H352.4l-5.9 64H101.5l-5.9-64H47.4zm68.9 224H331.6l-6.1 65.5c-.8 8.2-7.7 14.5-15.9 14.5H138.3c-8.3 0-15.2-6.3-15.9-14.5L116.4 384z"
                            />
                        </svg>
                        <svg
                            v-else-if="task.type === 'clean-crates'"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            height="1rem"
                            fill="currentColor"
                            class="mb-2 w-2rem"
                        >
                            <path
                                d="M48 224c-26.5 0-48 21.5-48 48v80H512V272c0-26.5-21.5-48-48-48H48zM512 384H0v80c0 26.5 21.5 48 48 48H464c26.5 0 48-21.5 48-48V384zM64 272a16 16 0 1 1 0 32 16 16 0 1 1 0-32zM48 448a16 16 0 1 1 32 0 16 16 0 1 1 -32 0zM448 272a16 16 0 1 1 0 32 16 16 0 1 1 0-32zM432 448a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"
                            />
                        </svg>
                        <span class="font-bold">{{ task.title }}</span>
                    </div>
                    <Tag class="ellipsis" :value="task.company"></Tag>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Button from 'primevue/button'
import { useRouter } from 'vue-router'
import { ref, onMounted } from 'vue'
import type { Ref } from 'vue'
import Tag from 'primevue/tag'
import { usePickupStore } from '@/pick-tasks/PickupStore'
import ProgressSpinner from 'primevue/progressspinner'
import type { Components } from '@/types/openapi.d.ts'

type Product = Components.Schemas.ProductResponse
type ShipmentResponse = Components.Schemas.ShipmentResponse
type ShipmentUnit = Components.Schemas.ShipmentUnit

export interface IPickupTaskList {
    type: string
    title: string
    company: string
    path: string
    shipment_id: string | null
}
const pickupStore = usePickupStore()
const router = useRouter()
const pickupList: Ref<IPickupTaskList[]> = ref([])
const isLoading = ref(false)

function navigate(task: IPickupTaskList) {
    router.push({ path: task.path })
}

onMounted(async () => {
    isLoading.value = true
    await pickupStore.fetchPendingDeliveryShipments()

    if (pickupStore.pendingShipments) {
        pickupStore.pendingShipments.forEach((shipmentPerLocation: ShipmentResponse) => {
            const { units, shipment_id } = shipmentPerLocation
            const companyName = shipmentPerLocation?.end_customer?.company_name ?? 'COMPANY_NAME'

            units.forEach((unit: ShipmentUnit) => {
                const { products, crates } = unit
                if (crates && crates.length > 0) {
                    if (
                        pickupList.value.some(
                            (item) =>
                                item.type === 'clean-cups' && item.shipment_id === shipment_id,
                        )
                    ) {
                        return
                    }
                    pickupList.value.push({
                        title: 'Clean Cups',
                        company: companyName,
                        path: `${shipment_id}/clean-cups`,
                        type: 'clean-cups',
                        shipment_id: shipment_id,
                    })
                }
                if (products && products.length > 0) {
                    const cleanCrates = products.filter((item: Product) => item.product === 'CRATE')
                    const newCups = products.filter((item: Product) => item.product !== 'CRATE')

                    if (cleanCrates && cleanCrates.length > 0) {
                        if (
                            pickupList.value.some(
                                (item) =>
                                    item.type === 'clean-crates' &&
                                    item.shipment_id === shipment_id,
                            )
                        ) {
                            return
                        }
                        pickupList.value.push({
                            title: 'Crates',
                            company: companyName,
                            path: `${shipment_id}/clean-crates`,
                            type: 'clean-crates',
                            shipment_id: shipment_id,
                        })
                    }

                    if (newCups && newCups.length > 0) {
                        if (
                            pickupList.value.some(
                                (item) =>
                                    item.type === 'new-cups' && item.shipment_id === shipment_id,
                            )
                        ) {
                            return
                        }
                        pickupList.value.push({
                            title: 'New Cups',
                            company: companyName,
                            path: `${shipment_id}/new-cups`,
                            type: 'new-cups',
                            shipment_id: shipment_id,
                        })
                    }
                }
            })
        })
    }
    isLoading.value = false
})
</script>

<style scoped lang="scss">
.logout-btn {
    position: absolute;
    right: 0px;
    margin: 20px;
    top: 0px;
}

.back-btn {
    position: absolute;
    left: 0px;
    margin: 20px;
    top: 0px;
}

.mr-9 {
    margin-right: 25px;
    margin-left: 5px;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    background-color: #8e0444;
    color: white;
    width: 150px;
}

.task-wrapper {
    border: 1px solid;
    padding: 8px;
    margin: 16px;
    border-radius: 10px;
    font-size: 15px;
    display: flex;
    font-weight: bold;
    gap: 5px;
    align-items: center;
    color: white;
    justify-content: space-between;
}
</style>
