import { ref, watchEffect, readonly, toValue, type MaybeRefOrGetter } from 'vue'

import cupflowClient from '@/shared/open_api_client/OpenApiClient'
import type { Components } from '@/types/openapi.d.ts'

export type Account = Components.Schemas.Account

export function useLocationManagers(locationID: MaybeRefOrGetter<string>) {
    const loading = ref(true)
    const managers = ref<Account[]>([])
    const refreshTrigger = ref(1)

    watchEffect(async () => {
        if (refreshTrigger.value) {
            loading.value = true
            managers.value = await fetchLocationManagers(toValue(locationID))
            loading.value = false
        }
    })

    const refresh = () => refreshTrigger.value++

    const addManager = async (name: string, email: string): Promise<Account> => {
        const newAccount = await addLocationManager(toValue(locationID), name, email)
        refresh()
        return newAccount.account
    }

    const removeManager = async (account: Account) => {
        await revokeManager(toValue(locationID), account.id)
        refresh()
    }

    return {
        managers: readonly(managers),
        loadingManagers: readonly(loading),

        addManager,
        removeManager,
    }
}

async function fetchLocationManagers(location_id: string): Promise<Account[]> {
    const response =
        await cupflowClient.managers_end_customer_management_location__location_id__managers_get({
            location_id,
        })
    return response.data.accounts
}

async function addLocationManager(location_id: string, name: string, email: string) {
    const response =
        await cupflowClient.add_manager_end_customer_management_location__location_id__managers_post(
            { location_id },
            { name: name, email: email },
        )
    return response.data
}

async function revokeManager(location_id: string, user_id: string) {
    const response =
        await cupflowClient.revoke_manager_end_customer_management_location__location_id__managers__user_id__delete(
            { location_id, user_id },
        )
}
