<template>
    <div>
        <div class="action-buttons">
            <h1>{{ t('ListEndCustomer.title') }}</h1>
            <Button
                icon="pi pi-plus"
                :label="$t('ListEndCustomer.addCustomer')"
                @click="router.push('/endcustomer/add')"
                v-show="permStore.hasPermission(PERMISSION.end_customer_add)"
                rounded
            ></Button>
        </div>
        <DataTable
            :value="endCustomerStore.endCustomers"
            stripedRows
            style="min-width: 50rem"
            datekey="id"
            v-model:expandedRows="expandedRows"
            v-model:filters="filters"
            class="p-datatable-sm"
            @row-click="onRowClick"
            selectionMode="single"
            removableSort
        >
            <template #header>
                <div class="flex justify-content-end">
                    <IconField iconPosition="left">
                        <InputIcon>
                            <i class="pi pi-search" />
                        </InputIcon>
                        <InputText
                            v-model="filters['global'].value"
                            :placeholder="t('general.search')"
                        />
                    </IconField>
                </div>
            </template>
            <template #empty> {{ t('general.noResultsFound') }} </template>
            <template #loading> {{ t('general.loading') }} </template>
            <Column
                field="company_name"
                sortable
                :header="t('ListEndCustomer.companyName')"
            ></Column>
            <Column
                field="contact_person.name"
                :header="t('ListEndCustomer.contactperson')"
            ></Column>
            <Column field="contact_person.email" :header="t('ListEndCustomer.email')"></Column>
            <Column field="start_date" :header="t('ListEndCustomer.startDate')">
                <template #body="{ data }">{{
                    new Date(data.start_date).toLocaleDateString('nl-NL')
                }}</template>
            </Column>
            <!--<Column field="location_count" :header="t('ListEndCustomer.location_count')">
                <template #body="{ data }">{{ data.locations?.length ?? 0 }}</template>
            </Column>-->
            <Column field="cup_count" :header="t('ListEndCustomer.cup_count')">
                <template #body="{ data }">{{ 0 }}</template>
            </Column>
            <Column>
                <template #body="{ data, frozenRow, index }">
                    <Button
                        outlined
                        icon="pi pi-pencil"
                        text
                        rounded
                        size="large"
                        @click="showDetailsEndCustomer(data)"
                    />
                </template>
            </Column>

            <template #expansion="slotProps">
                <LocationList :locations="endCustomerStore.locations" />
            </template>
        </DataTable>
    </div>
</template>

<script setup lang="ts">
import { useEndCustomerStore } from './EndCustomerStore'
import { usePermStore, PERMISSION } from '@/authorization/permStore'

import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

import { type EndCustomer } from './customer'
import { useToast } from 'primevue/usetoast'
import LocationList from './LocationList.vue'

import { FilterMatchMode } from 'primevue/api'

const router = useRouter()
const { t } = useI18n()
const endCustomerStore = useEndCustomerStore()
endCustomerStore.fetchEndCustomers()

const permStore = usePermStore()

let expandedRows = ref<EndCustomer[]>([])

const toast = useToast()

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
})

function onRowClick(event: any) {
    let rowData = event.data
    showDetailsEndCustomer(rowData)
}

function updateExpandedRows(rowData: any) {
    let rows = [...expandedRows.value]
    console.log(rows)
    if (
        rows.find((customer) => {
            return customer.id === rowData.id
        })
    ) {
        rows = rows.filter((customer) => {
            return customer.id !== rowData.id
        })
    } else {
        rows.push(rowData)
    }
    expandedRows.value = rows
}

function editEndcustomer(id: string) {
    console.log('edit customer: ' + id)
    toast.add({
        severity: 'error',
        summary: 'Werkt nog niet',
        detail: 'To be implemented',
        life: 3000,
    })
}

function showDetailsEndCustomer(data: EndCustomer) {
    endCustomerStore.activeEndCustomer = data
    router.push({ name: 'endcustomer_details', params: { end_customer_id: data.id } })
}
</script>
