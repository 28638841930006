import axiosInstance from './AxiosInstance'
import router from '@/router'
import type AxiosRequestConfig from 'axios'
import { useAuthStore } from '@/authentication/authStore'
import { useToast } from 'primevue/usetoast'

axiosInstance.interceptors.request.use(
    //@ts-ignore
    (config: AxiosRequestConfig) => {
        const authStore = useAuthStore()
        if (authStore.isAuthenticated) {
            config.headers['Authorization'] = `Bearer ${authStore.accessToken}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    },
)

axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        useToast().add({
            severity: 'error',
            summary: 'Error',
            detail: error.response.data.detail,
            life: 3000,
        })
        const originalRequest = error.config
        if (
            error.response.status === 401 &&
            !originalRequest._retry &&
            error.config.url !== '/auth/jwt/login/'
        ) {
            useAuthStore().logout()
            router.push('/login')
        }
        return Promise.reject(error)
    },
)

export default axiosInstance
