import ShipmentPage from './ShipmentsPage.vue'
//import NewOutgoingShipmentPage from './NewOutgoingShipmentPage.vue'

export const shipmentsRoutes = [
    {
        path: 'shipments',
        children: [
            {
                path: 'end_customer/:end_customer_id',
                name: 'shipments_end_customer',
                component: ShipmentPage,
                props: true,
            },
            {
                path: 'location/:location_id',
                name: 'shipments_location',
                component: ShipmentPage,
                props: true,
            },
            /* TODO:
            {
                path: 'shipment/add',
                name: 'shipment_add',
                component: NewOutgoingShipmentPage,
            },
            */
        ],
    },
]
