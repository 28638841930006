import { defineStore } from 'pinia'
import { type Ref, ref } from 'vue'

export interface breadcrumb {
    icon?: string
    label?: string
    route?: string
}

export const useBreadcrumbStore = defineStore('BreadcrumbStore', () => {
    // State
    const breadcrumbItems: Ref<breadcrumb[]> = ref([])

    // Actions
    const setItems = (item: breadcrumb[]) => {
        breadcrumbItems.value = item
    }

    return {
        breadcrumbItems,
        setItems,
    }
})
