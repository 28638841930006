import PickupLayout from './PickupLayout.vue'
import PickupMenuPage from './PickupMenuPage.vue'
import CleanCupsPage from './CleanCupsPage.vue'
import NewCupsPage from './NewCupsPage.vue'
import CleanCratePage from './CleanCratePage.vue'

// Routes for the special UI on scanner devices:
export const PickupRoutes = [
    {
        path: '/pick-tasks',
        component: PickupLayout,
        children: [
            {
                path: 'menu',
                name: 'pickup_menu',
                component: PickupMenuPage,
            },
            {
                path: ':shipment_id/clean-cups',
                name: 'clean_cups',
                component: CleanCupsPage,
                props: true,
            },
            {
                path: ':shipment_id/new-cups',
                name: 'new_cups',
                component: NewCupsPage,
                props: true,
            },
            {
                path: ':shipment_id/clean-crates',
                name: 'clean_crates',
                component: CleanCratePage,
                props: true,
            },
        ],
    },
]
