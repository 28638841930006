<template>
    <div>
        <DataTable
            :value="props.locations"
            style="min-width: 50rem"
            datakey="id"
            v-model:expandedRows="expandedRows"
            v-model:filters="filters"
            :key="tableKey"
            class="p-datatable-sm"
            @row-click="onRowClick"
            selectionMode="single"
            removableSort
        >
            <template #header>
                <div class="flex justify-content-end gap-2">
                    <IconField iconPosition="left">
                        <InputIcon>
                            <i class="pi pi-search" />
                        </InputIcon>
                        <InputText
                            v-model="filters['global'].value"
                            :placeholder="t('general.search')"
                        />
                    </IconField>
                    <Button
                        icon="pi pi-plus"
                        :label="$t('location.addLocation')"
                        arial-label="add"
                        @click="add_location()"
                        v-show="permStore.hasPermission(PERMISSION.location_add)"
                        rounded
                    />
                </div>
            </template>
            <template #empty> {{ t('general.noResultsFound') }} </template>
            <template #loading> {{ t('general.loading') }} </template>
            <Column field="address.street_name" :header="t('address.street_name')"> </Column>
            <Column field="address.town" :header="t('address.town')"> </Column>
            <Column field="description" :header="t('location.description')" sortable></Column>
            <Column
                field="building_number"
                :header="t('location.building_number')"
                sortable
            ></Column>
            <Column field="nr_of_employees" :header="t('location.nrOfEmployees')" sortable></Column>
            <Column field="pick_up_days" :header="t('location.pickUpDaysTitle')">
                <template #body="slotProps">
                    <div v-for="day in slotProps.data.pick_up_days">
                        {{ $t('general.' + day) }} ,
                    </div>
                </template>
            </Column>
            <Column>
                <template #body="{ data }">
                    <Button
                        icon="pi pi-stop-circle"
                        rounded
                        text
                        v-tooltip.left="$t('location.stopLocation')"
                        @click="deleteLocationConfirm($event, data)"
                        v-if="data.end_date == undefined"
                    />
                    <Tag value="Beeindigd" v-if="data.end_date != undefined" rounded></Tag>
                    <Button
                        v-tooltip="$t('ListEndCustomer.editInformationTooltip')"
                        outlined
                        icon="pi pi-pencil"
                        text
                        rounded
                        v-if="data.end_date == undefined || new Date(data.end_date) > new Date()"
                        @click="showLocationDetails(data)"
                    />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script setup lang="ts">
import Tag from 'primevue/tag'
import type { Location as LocationModel } from './customer'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { useEndCustomerStore } from './EndCustomerStore'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import { usePermStore, PERMISSION, ROLE } from '@/authorization/permStore'

import { FilterMatchMode } from 'primevue/api'
import router from '@/router'


const props = defineProps<{
    locations: LocationModel[],
}>()


const { t } = useI18n()
const endCustomerStore = useEndCustomerStore()
const permStore = usePermStore()
const toast = useToast()
const confirm = useConfirm()

let expandedRows = ref<LocationModel[]>([])
const tableKey = ref(0)

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
})

function add_location() {
    const end_customer_id = endCustomerStore.activeEndCustomer!.id
    router.push({ name: 'customer_location_create', params: {end_customer_id} })
}

function onRowClick(event: any) {
    showLocationDetails(event.data)
}

function showLocationDetails(location: LocationModel) {
    endCustomerStore.setSelectedLocation(location)
    const isRoleEndCustomer = permStore.hasRole(ROLE.end_customer_manager)

    if (isRoleEndCustomer) {
        router.push({
            name: 'endcustomer_location_update',
            params: { location_id: location.location_id },
        })
    } else {
        router.push({
            name: 'customer_location_update',
            params: { customer_id: location.end_customer_id, location_id: location.location_id },
        })
    }
}

const deleteLocationConfirm = (event: any, data: LocationModel) => {
    confirm.require({
        target: event.currentTarget,
        message: t('locationList.confirmDelete'),
        icon: 'pi pi-exclamation-triangle',
        group: 'headless',
        accept: () => {
            deleteLocation(data)
        },
        reject: () => {},
    })
}

function deleteLocation(location: LocationModel) {
    endCustomerStore.deactivateLocationFromCustomer(location.location_id, null)
    // locations = ref(endCustomerStore.getActiveEndCustomer.locations) // updates the data
    toast.add({
        severity: 'success',
        summary: t('locationList.locationDeleted'),
        detail: t('locationList.locationDeletedDetail'),
        life: 3000,
    })
    tableKey.value += 1 // forces rerender of the table.
}
</script>
