<script setup lang="ts">
import Button from 'primevue/button'

import { usePermStore, ROLE } from '@/authorization/permStore'
import { computed } from 'vue'

const permStore = usePermStore()

const allowed = computed(() => {
    return permStore.hasRole(ROLE.washer)
})
</script>
<template>
    <ConfirmDialog group="washer" :style="{ width: '100%' }"></ConfirmDialog>
    <div v-if="allowed" class="bg-primary p-2 h-full">
        <router-view />
    </div>
    <div v-else>
        <div class="bg-white border-round h-full flex flex-column">
            <div class="pt-5 px-3 w-full">
                <div class="text-center">
                    <h1>Geen toegang</h1>
                </div>
                <div class="p-5">
                    <div class="flex flex-column align-items-center">
                        <p class="flex">
                            Propeer in te loggen met een andere gebruiker of vraag uw
                            contactpersoon.
                        </p>
                        <router-link
                            class="flex align-items-stretch w-full"
                            :to="{ name: 'login' }"
                            v-slot="{ navigate }"
                        >
                            <Button class="login w-full h-4rem" rounded @click="navigate">
                                <div class="w-full justify-content-center font-bold text-xl">
                                    Login
                                </div>
                            </Button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss"></style>
