import { createRouter, createWebHistory } from 'vue-router'
import MyPage from '@/views/MyPage.vue'
import HomePage from '@/views/HomePage.vue'
import LoginPage from '@/authentication/Login.vue'
import AccountVerifyPage from '@/views/AccountVerifyPage.vue'
import TermsPage from '@/views/TermsPage.vue'
import NewEndCustomerPage from '@/customer-management/NewEndCustomerPage.vue'
import EndCustomerLocationPage from '@/customer-management/EndCustomerLocationPage.vue'
import Location from '@/customer-management/Location.vue'
import EndCustomerDetailsPage from '@/customer-management/EndCustomerDetailsPage.vue'
import ListEndCustomerPage from '@/customer-management/ListEndCustomerPage.vue'
import LocationManagers from '@/customer-management/LocationManagers.vue'
import DashboardPage from '@/views/DashboardPage.vue'
import NotFoundPage from '@/views/NotFoundPage.vue'
import { OrderRoutes } from '@/order/routes'
import { washerRoutes } from '@/washer/routes'
import { shipmentsRoutes } from '@/shipments/routes'
import { WashingRoutes } from '@/washing-management/routes'
import { PickupRoutes } from '@/pick-tasks/routes'
import { useAuthStore } from '@/authentication/authStore'
import { useBreadcrumbStore, type breadcrumb } from '@/stores/BreadcrumbStore'
import { i18n } from '@/i18n/index'

const t = i18n.global.t

export const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/login',
            name: 'login',
            component: LoginPage,
            beforeEnter: (to, from, next) => {
                useAuthStore().logout()
                next()
            },
        },
        {
            path: '/account/verify',
            name: 'account_verify',
            component: AccountVerifyPage,
            // token = JWT. '.' in URL don't work (atleast dev server sees these as file extentions
            props: (route) => ({ token: route.query.token }),
        },
        {
            path: '',
            name: 'home',
            component: HomePage,
            redirect: (to) => 'dashboard',
            beforeEnter: (to, from, next) => {
                const authStore = useAuthStore()
                if (!authStore.isAuthenticated) {
                    // Redirect to the login page if not authenticated
                    next({ name: 'login' })
                } else {
                    next()
                }
            },
            children: [
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: DashboardPage,
                },
                {
                    path: '/:pathMatch(.*)*',
                    name: 'page_not_found',
                    component: NotFoundPage,
                },
                {
                    path: 'my',
                    name: 'my',
                    component: MyPage,
                },
                {
                    path: 'terms',
                    name: 'terms',
                    component: TermsPage,
                },
                {
                    path: 'endcustomer/add',
                    name: 'endcustomer_add',
                    component: NewEndCustomerPage,
                    meta: {
                        breadcrumbs: [
                            {
                                icon: 'pi pi-users',
                                route: '/endcustomer/list',
                            },
                            {
                                label: 'New Customer',
                            },
                        ],
                    },
                },
                {
                    path: 'endcustomer/details/:end_customer_id',
                    name: 'endcustomer_details',
                    component: EndCustomerDetailsPage,
                    meta: {
                        breadcrumbs: [
                            {
                                icon: 'pi pi-users',
                                route: '/endcustomer/list',
                            },
                            {
                                label: 'Customer Details',
                            },
                        ],
                    },
                },
                {
                    path: 'endcustomer/list',
                    name: 'endcustomer_list',
                    component: ListEndCustomerPage,
                },
                {
                    path: 'endcustomer/locations',
                    name: 'endcustomer_locations',
                    component: EndCustomerLocationPage,
                },
                {
                    path: 'endcustomer/details/:end_customer_id/location/create',
                    name: 'customer_location_create',
                    component: Location,
                    meta: {
                        breadcrumbs: [
                            {
                                icon: 'pi pi-users',
                                route: '/endcustomer/list',
                            },
                            {
                                label: 'Customer Details',
                                route: `/endcustomer/details/:customer_id`,
                            },
                            {
                                label: 'Location Details',
                            },
                        ],
                    },
                },
                {
                    path: 'endcustomer/details/:customer_id/location/:location_id',
                    name: 'customer_location_update',
                    component: Location,
                    meta: {
                        breadcrumbs: [
                            {
                                icon: 'pi pi-users',
                                route: '/endcustomer/list',
                            },
                            {
                                label: 'Customer Details',
                                route: `/endcustomer/details/:customer_id`,
                            },
                            {
                                label: 'Location Details',
                            },
                        ],
                    },
                },
                {
                    path: 'endcustomer/location/:location_id',
                    name: 'endcustomer_location_update',
                    component: Location,
                    meta: {
                        breadcrumbs: [
                            {
                                icon: 'pi pi-building',
                                route: '/endcustomer/locations',
                            },
                            {
                                label: 'Location Details',
                            },
                        ],
                    },
                },
                {
                    path: 'endcustomer/location/:location_id/managers',
                    name: 'endcustomer_location_managers',
                    component: LocationManagers,
                    props: true,
                    meta: {
                        breadcrumbs: [
                            {
                                icon: 'pi pi-building',
                                route: '/endcustomer/locations',
                            },
                            {
                                label: 'Location Details',
                                route: '/endcustomer/location/:location_id',
                            },
                            {
                                label: t('location.managers'),
                            },
                        ],
                    },
                },
                ...shipmentsRoutes,
                ...OrderRoutes,
                ...WashingRoutes,
            ],
        },
        // Special UI for washer scanner devices:
        ...washerRoutes,
        ...PickupRoutes,
    ],
})

router.beforeEach((to, from, next) => {
    let breadcrumbArr = to.meta.breadcrumbs as breadcrumb[]
    const customerId = (to?.params?.customer_id as string) || ''
    const locationId = (to?.params?.location_id as string) || ''
    const breadcrumbStore = useBreadcrumbStore()

    if (breadcrumbArr) {
        breadcrumbArr = breadcrumbArr.map((breadcrumb: breadcrumb) => {
            if (breadcrumb.route?.includes(':customer_id') && customerId) {
                breadcrumb.route = breadcrumb.route?.replace(':customer_id', customerId)
            }
            if (breadcrumb.route?.includes(':location_id') && locationId) {
                breadcrumb.route = breadcrumb.route?.replace(':location_id', locationId)
            }
            return breadcrumb
        })
        breadcrumbStore.setItems(breadcrumbArr as breadcrumb[])
    } else {
        breadcrumbStore.setItems([])
    }
    next()
})

export default router
