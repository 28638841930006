import { computed, ref } from 'vue'
import { defineStore, acceptHMRUpdate } from 'pinia'
import axios from 'axios'

import type { Components } from '@/types/openapi.d.ts'
import cupflowClient from '@/shared/open_api_client/OpenApiClient'
import { type EndCustomerLocation } from '@/stores/LocationStore'

type OrderRequest = Components.Schemas.OrderRequest
type OrderResponse = Components.Schemas.OrderResponse
export type AvailableProducts = Components.Schemas.ProductListResponse
export type ProductType = Components.Schemas.Product

export type OrderItem = Components.Schemas.OrderItem
// Our own type, since we deal with Date
export type Order = {
    locationId: string
    preferredDate: Date
    items: OrderItem[]
}
// Map product to amount
type Cart = Partial<Record<ProductType, number>>
const MINIMAL_DELIVERY_DAYS = 1

export const useOrderStore = defineStore(
    'OrderStore',
    () => {
        const selectedLocation = ref<EndCustomerLocation | null>(null)
        const selectedDate = ref<Date>()
        const cart = ref<Cart>({})
        //
        // Days of the week not allowed for delivery date.
        const disabledDays = ref([0, 6])
        const disabledDates = computed(() => {
            // TODO: from API? atleast the mininum Date, compute the rest.
            const days: Date[] = []
            const minDate = new Date() // in theory, this should also be reactive, if you leave compoennt open for 24hrs
            let nr = 0
            while (nr < MINIMAL_DELIVERY_DAYS) {
                minDate.setDate(minDate.getDate() + 1)
                if (!disabledDays.value.includes(minDate.getUTCDay())) {
                    days.push(new Date(minDate))
                    nr += 1
                }
            }
            return days
        })
        const minimumDate = computed(() => {
            const last = disabledDates.value[disabledDates.value.length - 1] //.at(-1), if es2022
            const minDate = new Date(last!)
            minDate.setDate(minDate.getDate() + 1)
            const wkDay = minDate.getUTCDay()
            if (disabledDays.value.includes(wkDay)) {
                minDate.setDate(minDate.getDate() + (wkDay === 0 ? 1 : 2))
            }
            return minDate
        })

        const cartCount = computed(() => {
            return Object.values(cart.value).reduce((amount, curr) => amount + curr, 0)
        })

        // Actions
        const addToCart = (product: ProductType, amount: number) => {
            if (amount <= 0) {
                throw new Error('Only positive amounts allowed')
            }
            cart.value[product] = (cart.value[product] ?? 0) + amount
        }

        const removeFromCart = (product: ProductType, amount: number) => {
            if (amount <= 0) {
                throw new Error('Only positive amounts allowed')
            }
            cart.value[product] = Math.max(0, (cart.value[product] ?? 0) - amount)
            if (cart.value[product] == 0) {
                delete cart.value[product]
            }
        }

        const orderProduct = async (order: Order) => {
            return await callAPI(orderToRequest(order))
        }

        const isValid = () => {
            // TODO: integrate vuelidate into store?
            return [selectedLocation.value, selectedDate.value, cartCount.value].every((x) => !!x)
        }

        const submit = async () => {
            if (isValid()) {
                await orderProduct({
                    locationId: selectedLocation.value!.location_id,
                    preferredDate: selectedDate.value!,
                    items: Object.entries(cart.value).map(([k, v]) => ({
                        product: k as ProductType,
                        amount: v,
                    })),
                })
            } else {
                throw Error('Order not valid')
            }
        }

        const clearCart = () => {
            cart.value = {}
        }
        const reset = () => {
            clearCart()
            selectedDate.value = minimumDate.value
            selectedLocation.value = null
        }
        return {
            cart,
            selectedLocation,
            selectedDate,

            disabledDays,
            disabledDates,
            minimumDate,
            cartCount,

            addToCart,
            removeFromCart,
            isValid,
            orderProduct,
            submit,
            clearCart,
            reset,
        }
    },
    {},
)

function orderToRequest(order: Order): OrderRequest {
    return {
        location_id: order.locationId,
        preferred_date_of_delivery: order.preferredDate.toJSON().split('T', 1)[0],
        items: order.items,
    }
}

// wrap the API calls for error handling
// TODO: have shared/util to handle the rest of this.
const callAPI = async (order: OrderRequest): Promise<OrderResponse> => {
    try {
        const response = await cupflowClient.order_orders_order__post(null, order)
        return response.data
    } catch (e) {
        if (
            axios.isAxiosError(e) &&
            e.response?.status === axios.HttpStatusCode.UnprocessableEntity
        ) {
            // TODO: API sends invalid fields... but in f'ing string!
            console.error(e.response.data)
            throw e
        } else {
            throw e
        }
    }
}

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useOrderStore, import.meta.hot))
}
