import { computed, ref, readonly, watchEffect } from 'vue'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { useRoute } from 'vue-router'

import cupflowClient from '@/shared/open_api_client/OpenApiClient'
import type { Components } from '@/types/openapi.d.ts'
import { usePermStore, PERMISSION } from '@/authorization/permStore'

export type Shipment = Components.Schemas.ShipmentResponse

export const useShipmentStore = defineStore(
    'ShipmentStore',
    () => {
        const shipments = ref<Shipment[]>([])
        const dateRange = ref<[string, string] | [null, null]>([null, null])
        const refreshTrigger = ref(1)
        const busy = ref(false)
        const multipleLocations = ref<boolean>()

        const shipmentsToday = computed(() => {
            // Could make an API call, but for as long as we don't have pagination on the main list...
            const today = dateStr(new Date())
            return shipments.value.filter((s) => {
                return s.planned_date === today
            })
        })

        const refresh = () => refreshTrigger.value++

        const permStore = usePermStore()

        const route = useRoute()
        // TODO: refactor, remove fallback from usages.
        watchEffect(async () => {
            if (refreshTrigger.value) {
                busy.value = true
                const [start, end] = dateRange.value ?? [null, null]
                let response: Components.Schemas.ShipmentResponse[] = []
                // route.params are seen as string|string[] ? :/
                let end_customer_id: string | null =
                    (route.params.end_customer_id as string) || null
                let location_id: string | null = (route.params.location_id as string) || null
                if (!end_customer_id && !location_id) {
                    // fallback, try permission based
                    [end_customer_id, location_id] = idsFromPermissions(permStore)
                }
                if (permStore.hasPermission(PERMISSION.washing_cycle_view)) {
                    if (end_customer_id) {
                        response = await fetchEndCustomerShipments(
                            end_customer_id as string,
                            start,
                            end,
                        )
                        multipleLocations.value = true
                    } else if (location_id) {
                        response = await fetchLocationShipments(location_id as string, start, end)
                        multipleLocations.value = false
                    }
                }
                shipments.value = response.map((s: Shipment) => {
                    return {
                        ...s,
                        //plannedDate: new Date(s.planned_date),
                    }
                })
                busy.value = false
            }
        })

        // actions
        async function setDateRange(start: Date | null, end: Date | null) {
            if (start && end) {
                dateRange.value = [dateStr(start), dateStr(end)]
            } else {
                dateRange.value = [null, null]
            }
        }
        async function cancel(shipment: Shipment, cancel: boolean) {
            const response = await cupflowClient.cancel_pickup_shipments_cancel_pickup_post(null, {
                pickup_date: shipment.planned_date,
                location_id: shipment.location.location_id,
                cancel: cancel,
            })
            refresh()
            return response.data.shipment_id
        }

        return {
            shipments: shipments, //readonly(shipments),
            shipmentsToday: shipmentsToday,
            busy: readonly(busy),
            multipleLocations: readonly(multipleLocations),
            setDateRange,
            cancel,
        }
    },
    {},
)

interface RangeParams {
    start?: string
    end?: string
}

/**
 * Try to find a location or end customer ID from the permissions.
 * @deprecated
 */
function idsFromPermissions(permStore: ReturnType<typeof usePermStore>) {
    let end_customer_id = null
    let location_id = null
    const allowed = permStore.idsForPermission(PERMISSION.shipment_view)
    if (allowed.length > 1) {
        const end_customer_ids = permStore.idsForPermission(PERMISSION.end_customer_manage)
        // if you manage multiple, we don't know what to show you... ('customer' user)
        // TODO: add a backend endpoint for customer user to call? or just don't show in dashboard?
        end_customer_id = end_customer_ids.length === 1 ? end_customer_ids[0] : null
    } else if (allowed.length == 1) {
        location_id = allowed[0]
    }
    return [
        end_customer_id,
        location_id,
    ]
}

async function fetchEndCustomerShipments(
    end_customer_id: string,
    start: string | null,
    end: string | null,
): Promise<Shipment[]> {
    const params: RangeParams = {}
    if (start) {
        params['start'] = start
    }
    if (end) {
        params['end'] = end
    }
    const response =
        await cupflowClient.end_customer_shipment_list_shipments_end_customer__end_customer_id___get(
            { end_customer_id, ...params },
        )
    return response.data.shipments
}

async function fetchLocationShipments(
    location_id: string,
    start: string | null,
    end: string | null,
): Promise<Shipment[]> {
    const params: RangeParams = {}
    if (start) {
        params['start'] = start
    }
    if (end) {
        params['end'] = end
    }
    const response =
        await cupflowClient.location_shipment_list_shipments_location__location_id___get({
            location_id,
            ...params,
        })
    return response.data.shipments
}

function dateStr(d: Date) {
    return d.toJSON().split('T')[0]
}

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useShipmentStore, import.meta.hot))
}
